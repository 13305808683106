import * as React from 'react'
import { TabList } from '@pulseops/backoffice'
import {
  AuthService,
  MyTaskPermissions,
  PulseOpsApi,
  PulseOpsService,
  SubTaskCount,
  SubTaskType,
  TaskCount,
  TaskListPermissions,
  TaskOp,
  TaskService,
  TaskType,
  useMobile,
  AppContext,
  TASK_DETAIL_ROLES,
  GeneralService,
  assets,
  UserGroupInfo,
  TransactionType,
  SubTaskLabel,
  SubTaskCodeFromString,
  InquiryComplaintService,
  ErrorHandling,
  CommonRequestService,
  SelectOption
} from '@pulseops/common'
import {
  View,
  StyleSheet,
  useWindowDimensions,
  ActivityIndicator,
  Text,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import {
  TaskList,
  TaskActionGroup,
  SearchFilterInput,
  TaskGroupTabs,
  TaskFilterForm,
  TaskFilterFormModal,
  TaskTransferFormModal,
  TaskTransferForm,
  TaskCommentFormModal,
  TaskUnsuspendFormModal,
  TaskUnsuspendForm,
  BIG_SCREEN_SIZE,
  useBigScreenThreshold,
  Type,
  TaskTable,
  TaskAddCommentModal
} from './common'
import {
  CanView,
  isRefreshAllInDashboard,
  isRefreshAllBasketInDashboard,
  refreshDashboardHandled,
  requestRefreshTeamTask,
  isRefreshDashboardAfterTransfer
} from '@pulseops/task/common'
import { ZIO } from '@mxt/zio'
// import { DATask } from './distribution-admin'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { useFocusEffect } from '@react-navigation/core'
import { EndPopup } from '../task-detail/actions'
import { Subject } from 'rxjs'

// Remove tạm vì chưa support tab này
const removeErrorHandlingTab = (taskCount: TaskCount[]) => {
  return taskCount.filter((item) => item.type !== TaskType.ErrorHandler)
}

const checkBasketPermission = (taskType: TaskType, subTask: SubTaskType, permissions: string[]) => {
  if (taskType === TaskType.PolicyService) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.PS.ViewVerificationPSMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.PS.ViewQCPSMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.PS.ViewSuspendPSMyTask)
      case SubTaskType.Underwriting:
        return true
      case SubTaskType.QCUnderwriting:
        return permissions.includes(MyTaskPermissions.UW.ViewQCUWPSMyTask)
      default:
        return false
    }
  }
  if (taskType === TaskType.DistributionService) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.DS.ViewVerificationDSMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.DS.ViewQCDSMyTask)
      // case SubTaskType.Suspend:
      //   return permissions.includes(MyTaskPermissions.DS.ViewSuspendDSMyTask)
      default:
        return false
    }
  }

  // if (taskType === TaskType.DistributionService) {
  //   switch (subTask) {
  //     case SubTaskType.Verification:
  //       return permissions.includes(MyTaskPermissions.DS.ViewVerificationDSMyTask)
  //     case SubTaskType.QC:
  //       return permissions.includes(MyTaskPermissions.DS.ViewQCDSMyTask)
  //     case SubTaskType.Suspend:
  //       return permissions.includes(MyTaskPermissions.DS.ViewSuspendDSMyTask)
  //     default:
  //       return false
  //   }
  // }

  if (taskType === TaskType.CustomerService) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.CS.ViewVerificationCSMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.CS.ViewQCCSMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.CS.ViewSuspendCSMyTask)
      default:
        return false
    }
  }

  if (taskType === TaskType.Complaint) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.CH.ViewVerificationCHMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.CH.ViewQCCHMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.CH.ViewSuspendCHMyTask)
      default:
        return false
    }
  }

  if (taskType === TaskType.Underwriting) {
    switch (subTask) {
      case SubTaskType.Underwriting:
        return permissions.includes(MyTaskPermissions.UW.ViewUnderwritingUWMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.UW.ViewQCUWMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.UW.ViewSuspendUWMyTask)
      default:
        return false
    }
  }

  // Missing permisson case
  if (taskType === TaskType.Claim) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.CL.ViewAdminCLMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.CL.ViewQCCLMyTask)
      case SubTaskType.Adjudication:
        return permissions.includes(MyTaskPermissions.CL.ViewAdjudicationCLMyTask)
      case SubTaskType.Payout:
        return permissions.includes(MyTaskPermissions.CL.ViewPayoutCLMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.CL.ViewAdjudicationSuspendCLMyTask)
      default:
        return false
    }
  }

  // Not available
  if (taskType === TaskType.ErrorHandler) {
    switch (subTask) {
      default:
        return false
    }
  }

  if (taskType === TaskType.PremiumCollection) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.PC.ViewVerificationPCMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.PC.ViewQCPCMyTask)
      case SubTaskType.Suspend:
        return permissions.includes(MyTaskPermissions.PC.ViewSuspendPCMyTask)
      default:
        return false
    }
  }

  if (taskType === TaskType.IDC) {
    switch (subTask) {
      case SubTaskType.Verification:
        return permissions.includes(MyTaskPermissions.IDC.ViewVerificationIDCMyTask)
      case SubTaskType.QC:
        return permissions.includes(MyTaskPermissions.IDC.ViewQCIDCMyTask)
      default:
        return false
    }
  }

  return false
}

const countTotalTaskWithPermission = (data: TaskCount[], permissions: string[]) => {
  return data.map((item) => {
    const { subTasks } = item
    const totalItem = subTasks.reduce((total, basket) => {
      const hasPermission = checkBasketPermission(basket.code.task, basket.code.subTask, permissions)
      return total + (hasPermission ? basket.value : 0)
    }, 0)
    return {
      ...item,
      value: totalItem
    }
  })
}

const hasTransferAction = (taskGroupSelected: TaskCount, permissions: string[]) => {
  switch (taskGroupSelected.type) {
    case TaskType.PolicyService:
      return permissions.includes(TaskListPermissions.PS.TransferPSTaskList)
    // case TaskType.DistributionService:
    //   return permissions.includes(TaskListPermissions.DS.TransferDSTaskList)
    case TaskType.DistributionService:
      return permissions.includes(TaskListPermissions.DS.TransferDSTaskList)
    case TaskType.Complaint:
      return permissions.includes(TaskListPermissions.CH.TransferCHTaskList)
    case TaskType.CustomerService:
      return permissions.includes(TaskListPermissions.CS.TransferCSTaskList)
    case TaskType.Underwriting:
      return permissions.includes(TaskListPermissions.UW.TransferUWTaskList)
    case TaskType.Claim:
      return permissions.includes(TaskListPermissions.CL.TransferCLTaskList)
    case TaskType.PremiumCollection:
      return permissions.includes(TaskListPermissions.PC.TransferPCTaskList)
    case TaskType.IDC:
      return permissions.includes(TaskListPermissions.IDC.TranferIDCTaskList)
    default:
      return false
  }
}

// const hasUnsuspendAction = (taskGroupSelected: TaskCount, permissions: string[]) => {
//   switch (taskGroupSelected.type) {
//     case TaskType.PolicyService:
//       return permissions.includes(TaskListPermissions.PS.UnsuspendPSTaskList)
//     case TaskType.Complaint:
//       return permissions.includes(TaskListPermissions.CH.UnsuspendCHTaskList)
//     case TaskType.CustomerService:
//       return permissions.includes(TaskListPermissions.CS.UnsuspendCSTaskList)
//     case TaskType.Underwriting:
//       return permissions.includes(TaskListPermissions.UW.UnsuspendUWTaskList)
//     case TaskType.Claim:
//       return permissions.includes(TaskListPermissions.CL.UnsuspendCLTaskList)
//     default:
//       return false
//   }
// }

const hasRetryAction = (taskGroupSelected: TaskCount, permissions: string[]) => {
  switch (taskGroupSelected.type) {
    case TaskType.PolicyService:
      return permissions.includes(TaskListPermissions.PS.RetryPSTaskList)
    // case TaskType.DistributionService:
    //   return permissions.includes(TaskListPermissions.DS.RetryDSTaskList)
    case TaskType.DistributionService:
      return permissions.includes(TaskListPermissions.DS.RetryDSTaskList)
    case TaskType.Complaint:
      return permissions.includes(TaskListPermissions.CH.RetryCHTaskList)
    case TaskType.CustomerService:
      return permissions.includes(TaskListPermissions.CS.RetryCSTaskList)
    case TaskType.Underwriting:
      return permissions.includes(TaskListPermissions.UW.RetryUWTaskList)
    case TaskType.Claim:
      return permissions.includes(TaskListPermissions.CL.RetryCLTaskList)
    case TaskType.PremiumCollection:
      // return permissions.includes(TaskListPermissions.PC.RetryPCTaskList)
      return false
    default:
      return false
  }
}

const hasEndAction = (taskGroupSelected: TaskCount, permissions: string[]) => {
  switch (taskGroupSelected.type) {
    case TaskType.PolicyService:
      return permissions.length > 0
    case TaskType.DistributionService:
      return permissions.length > 0
    case TaskType.Complaint:
      return permissions.length > 0
    case TaskType.CustomerService:
      return permissions.length > 0
    case TaskType.Underwriting:
      return false
    case TaskType.Claim:
      return false
    case TaskType.PremiumCollection:
      //return permissions.includes(TaskListPermissions.PC.EndPCTaskList)
      return false
    default:
      return false
  }
}

// const hasExportAction = (taskGroupSelected: TaskCount, permissions: string[]) => {
//   switch (taskGroupSelected.type) {
//     case TaskType.PolicyService:
//       return permissions.includes(TaskListPermissions.PS.ExportPSTaskList)
//     case TaskType.Complaint:
//       return permissions.includes(TaskListPermissions.CH.ExportCHTaskList)
//     case TaskType.CustomerService:
//       return permissions.includes(TaskListPermissions.CS.ExportCSTaskList)
//     case TaskType.Underwriting:
//       return permissions.includes(TaskListPermissions.UW.ExportUWTaskList)
//     case TaskType.Claim:
//       return permissions.includes(TaskListPermissions.CL.ExportCLTaskList)
//     default:
//       return false
//   }
// }

interface Props {
  active?: boolean
  permissions: string[]
  subServiceTypeList: SelectOption[]
  subTransactionTypeList: Array<SelectOption & { slaStandard: string }>
}

const refreshSubject = new Subject<SubTaskType>()

export const MyTaskTab = ({ active, permissions, subServiceTypeList, subTransactionTypeList }: Props) => {
  const { isWide } = useMobile()
  const { height } = useWindowDimensions()
  const { t, i18n } = useTranslation('TaskManagement')
  const { isBigScreen } = useBigScreenThreshold(BIG_SCREEN_SIZE)
  const { showToast, showGlobalLoading, isGlobalLoadingVisible } = React.useContext(AppContext.AppContextInstance)

  const [isLoading, setLoading] = React.useState(false)
  const [isGridView, setIsGirdView] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)

  const [isFirstTime, setFirstTime] = React.useState(true)
  const [taskGroupSelected, setTaskGroupSelected] = React.useState<TaskCount>()
  const [policyNum, setPolicyNum] = React.useState<string>('')
  const [searchPolicyNum, setSearchPolicyNum] = React.useState<string>('')
  const [filter, setFilter] = React.useState<TaskFilterForm.Validated>()
  const [taskSelectedMap, setTaskSelectedMap] = React.useState<Map<string, TaskOp>>(new Map())
  const [myTaskCount, setMyTaskCount] = React.useState<TaskCount[]>([])
  const [isEmptyTab, setEmptyTab] = React.useState(false)
  const [basketSelected, setBasketSelected] = React.useState<SubTaskCount>()
  const [taskAddComment, setTaskAddComment] = React.useState<TaskOp>()
  const [userGroups, setUserGroups] = React.useState<{ userGroup: string; groupName: string }[]>([])
  const [transferUserGroups, setTransferUserGroups] = React.useState<{ userGroup: string; groupName: string }[]>([])
  const [listMustRefesh, _setListMustRefesh] = React.useState<SubTaskType[]>([])

  const [isFilterModalVisible, setFilterModalVisible] = React.useState<boolean>(false)
  const [isTransferModalVisible, setTransferModalVisible] = React.useState<boolean>(false)
  const [isSuspendModalVisible, setSuspendModalVisible] = React.useState<boolean>(false)
  const [isCommentModalVisible, setCommentModalVisible] = React.useState<boolean>(false)
  const [isEndModalVisible, setEndModalVisible] = React.useState<boolean>(false)

  // const { subServiceTypeList, subTransactionTypeList } = pipe(
  //   ZIO.zipPar(InquiryComplaintService.getTypesFromCachedApi(), InquiryComplaintService.getSubTypeFromCachedApi()),
  //   ZIO.map(([typeList, subTypeArr]) => {
  //     const subServiceTypeList = typeList.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code
  //     }))
  //     const subTransactionTypeList = subTypeArr.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code,
  //       slaStandard: x.slaStandard ?? ''
  //     }))
  //     return { subServiceTypeList, subTransactionTypeList }
  //   }),
  //   ErrorHandling.runDidUpdate([i18n.language])
  // ) || {
  //   subServiceTypeList: [],
  //   subTransactionTypeList: []
  // }

  const commonRequests =
    pipe(
      CommonRequestService.getCommonRequestListByCachedZIO(),
      ZIO.map((commonrequests) => commonrequests),
      ErrorHandling.runDidMount()
    ) || []

  const onTaskSelected = (basket: SubTaskCount, task: TaskOp) => {
    !basketSelected && setBasketSelected(basket)
    taskSelectedMap.set(task.id, task)
    setTaskSelectedMap(new Map(taskSelectedMap))
  }

  const onTaskUnSelected = (task: TaskOp) => {
    taskSelectedMap.delete(task.id)
    taskSelectedMap.size === 0 && setBasketSelected(undefined)
    setTaskSelectedMap(new Map(taskSelectedMap))
  }

  const reset = () => {
    setSearchPolicyNum('')
    setPolicyNum('')
    setFilter(undefined)
    setBasketSelected(undefined)
    setTaskSelectedMap(new Map())
    setTabIndex(0)
  }

  const onPressSearchTask = () => {
    if (policyNum) {
      setSearchPolicyNum(policyNum)
    }
  }

  // Handling task actions

  const onPressTaskTransfer = () => {
    getUserGroupList()
    // setTransferModalVisible(true)
  }

  // const onPressTaskSuspend = () => {
  //   setSuspendModalVisible(true)
  // }

  const onPressTaskEnd = () => {
    setEndModalVisible(true)
  }

  // Handling form submit
  const onSubmitTaskFilterForm = (data: TaskFilterForm.Validated) => {
    setFilter(data)
    setFilterModalVisible(false)
  }

  const onSubmitTaskTransferForm = (data: TaskTransferForm) => {
    const { comment, user, userGroup } = data
    if (!user || !userGroup) {
      showToast(t('TransferFailed'), 'error')
    } else {
      showGlobalLoading(true)
      const transferTask =
        taskSelectedMap.size === 1
          ? pipe(
              AuthService.userInfo,
              ZIO.flatMap(({ email }) => {
                return PulseOpsService.transferTask(
                  [...taskSelectedMap.keys()][0],
                  user.value,
                  userGroup.value,
                  { author: email, message: comment },
                  [...taskSelectedMap.values()][0].updateToCore,
                  [TaskType.IDC].includes(taskGroupSelected?.type as TaskType) ? TaskType.IDC : undefined
                )
              })
            )
          : PulseOpsApi.transferList({
              assignee: user.value,
              assigneeGroup: userGroup.value,
              taskList: [...taskSelectedMap.keys()],
              category: [TaskType.IDC].includes(taskGroupSelected?.type as TaskType) ? TaskType.IDC : undefined
            })

      pipe(
        transferTask,
        ZIO.catchAll((_error) => {
          // Error Handling
          showGlobalLoading(false)
          showToast(t('TransferFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap((_value) => {
          getTaskCount()
          setTransferModalVisible(false)
          setTaskSelectedMap(new Map())
          setBasketSelected(undefined)
          showToast(t('TransferSuccess'), 'success')
          requestRefreshTeamTask()
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const isCheckedOpenTransfer = () => {
    if (taskGroupSelected && taskGroupSelected.type === TaskType.PolicyService) {
      let transactionTypeList: TransactionType[] = []
      for (const selectedRow of taskSelectedMap.values()) {
        if (!transactionTypeList.some((item) => item === selectedRow.transactionType)) {
          transactionTypeList.push(selectedRow.transactionType as TransactionType)
        }
        // transactionTypeList.push(selectedRow.transactionType as TransactionType)
      }
      return transactionTypeList.length <= 1 ? true : false
    } else {
      return true
    }
  }

  const getUserGroupList = () => {
    showGlobalLoading(true)
    pipe(
      taskGroupSelected &&
        ![TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC].includes(
          taskGroupSelected.type
        )
        ? ZIO.effect(() => {
            setTransferModalVisible(true)
            return userGroups
          })
        : pipe(
            ZIO.effect(() => {
              let transactionTypeList: TransactionType[] = []
              const subTaskCode = !!basketSelected ? SubTaskCodeFromString.encode(basketSelected.code) : ''
              for (const selectedRow of taskSelectedMap.values()) {
                if (!transactionTypeList.some((item) => item === selectedRow.transactionType)) {
                  transactionTypeList.push(selectedRow.transactionType as TransactionType)
                }
              }
              return { transactionTypeList, subTaskCode }
            }),
            ZIO.flatMap(({ transactionTypeList, subTaskCode }) => {
              return (basketSelected?.code.subTask === SubTaskType.QC ||
                basketSelected?.code.subTask === SubTaskType.QCUnderwriting) &&
                ['PS', 'CH', 'CS', 'IDC'].includes(taskGroupSelected?.type ?? '')
                ? pipe(
                    GeneralService.getUserQCGroupLevelByCategory(
                      taskGroupSelected?.type as TaskType,
                      transactionTypeList[0],
                      subTaskCode === 'PS-UW-QC' ? 'UW-QC' : subTaskCode
                    ),
                    ZIO.map((groupArr) => {
                      let userGroupList: UserGroupInfo[] = []
                      groupArr.forEach((groupItem) => {
                        //filter duplication groups
                        if (
                          !!groupItem &&
                          !userGroupList.some((group) => group.userGroup === groupItem.userQCGroupLevelToTransfer)
                        ) {
                          let data = {
                            category: taskGroupSelected?.type ?? '',
                            userGroup: groupItem.userQCGroupLevelToTransfer,
                            groupName: groupItem.userQCGroupLevelToTransfer
                          }
                          userGroupList.push(data)
                        }
                      })
                      return userGroupList
                    })
                  )
                : pipe(
                    GeneralService.getManualTransferGroups(
                      taskGroupSelected?.type as TaskType,
                      subTaskCode,
                      transactionTypeList[0]
                    ),
                    ZIO.map((manualGroups) => {
                      let userGroupList: UserGroupInfo[] = []
                      // manual list
                      const filteredGroups =
                        taskGroupSelected?.type === TaskType.IDC
                          ? manualGroups.map((groupItem) => {
                              const groupTransferList = groupItem.userGroupCodeToTransfer.split('-')
                              const category = groupTransferList[0]
                              const groupInfo = {
                                category: category,
                                userGroup: groupItem.userGroupCodeToTransfer,
                                groupName: groupItem.userGroupCodeToTransfer
                              }
                              return groupInfo
                            })
                          : manualGroups
                              .filter(
                                (p) =>
                                  taskGroupSelected?.type !== TaskType.PolicyService ||
                                  (taskGroupSelected?.type === TaskType.PolicyService &&
                                    p.userGroupCodeToTransfer !== 'PS-PayoutMajor')
                              )
                              .map((groupItem) => {
                                const groupTransferList = groupItem.userGroupCodeToTransfer.split('-')
                                const category = groupTransferList[0]
                                const userGroup = groupTransferList[1]
                                const groupInfo = {
                                  category: category,
                                  userGroup: userGroup,
                                  groupName: groupItem.userGroupCodeToTransfer
                                }
                                return groupInfo
                              })
                      filteredGroups.forEach((item) => {
                        //filter duplication groups
                        if (!userGroupList.some((group) => group.userGroup === item.userGroup)) {
                          userGroupList.push(item)
                        }
                      })
                      return userGroupList
                    })
                  )
            }),
            ZIO.map((userGroupArr) => {
              let userGroupList: UserGroupInfo[] = userGroupArr
              // if (userGroupArr.some((x) => x.userGroup.includes('Alteration'))) {
              //   userGroupList.push({
              //     category: 'PS',
              //     userGroup: 'Underwriting4',
              //     groupName: 'Underwriting4'
              //   })
              //   userGroupList.push({
              //     category: 'PS',
              //     userGroup: 'Underwriting3',
              //     groupName: 'Underwriting3'
              //   })
              // }
              setTransferUserGroups(
                userGroupList.map((newGroup) => ({ userGroup: newGroup.userGroup, groupName: newGroup.groupName }))
              )
              setTransferModalVisible(true)
              return userGroupList
            })
          ),
      ZIO.foldM(
        (_) => ZIO.effect(() => showGlobalLoading(false)),
        (_) => ZIO.effect(() => showGlobalLoading(false))
      ),
      ZIO.unsafeRun({})
    )
  }

  const onSubmitTaskUnsuspendForm = (data: TaskUnsuspendForm) => {
    const { comment, user } = data
    if (!user) {
      showToast(t('UnsuspendFailed'), 'error')
    } else {
      showGlobalLoading(true)
      const processInstanceIds = [...taskSelectedMap.values()].map((item) => item.processInstanceId || '')
      const basketCode = !!basketSelected ? SubTaskCodeFromString.encode(basketSelected.code) : ''
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) =>
          PulseOpsService.unSuspendTask(
            processInstanceIds,
            user.value,
            { message: comment, author: email },
            false,
            basketCode,
            ''
          )
        ),
        ZIO.catchAll((_error) => {
          // Error handling
          showGlobalLoading(false)
          showToast(t('UnsuspendFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          getTaskCount()
          setSuspendModalVisible(false)
          setTaskSelectedMap(new Map())
          setBasketSelected(undefined)
          showGlobalLoading(false)
          showToast(t('UnsuspendSuccess'), 'success')
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onEndTask = () => {
    getTaskCount()
    setTaskSelectedMap(new Map())
    setBasketSelected(undefined)
    showGlobalLoading(false)
  }

  useFocusEffect(
    React.useCallback(() => {
      if (active) {
        if (isRefreshAllInDashboard() || isRefreshAllBasketInDashboard() || isRefreshDashboardAfterTransfer()) {
          getTaskCount()
          refreshDashboardHandled()
        }
      }
    }, [active, taskGroupSelected])
  )

  const getTaskCount = () => {
    pipe(
      TaskService.countMyTasks,
      ZIO.catchAll((_error) => {
        setLoading(false)
        // Error Handling
        return ZIO.fail(null)
      }),
      ZIO.tap((taskCounts) => {
        setLoading(false)
        setFirstTime(false)
        showGlobalLoading(false)
        taskCounts = countTotalTaskWithPermission(taskCounts, permissions)
        setMyTaskCount(taskCounts)
        if (!taskCounts || taskCounts.length === 0) {
          setEmptyTab(true)
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    if (active) {
      isFirstTime && setLoading(true)
      getTaskCount()
    }
  }, [active])

  React.useEffect(() => {
    if (taskGroupSelected) {
      reset()
      if (taskGroupSelected.value > 0) {
        pipe(
          GeneralService.getUsersGroupsByTransaction(taskGroupSelected.type),
          ZIO.catchAll((_error) => {
            return ZIO.fail(null)
          }),
          ZIO.tap((userGroups) => {
            const hideGroup = ['Underwriting1', 'Underwriting2']
            setUserGroups(userGroups.filter((x) => !hideGroup.includes(x.groupName)))
            setTransferUserGroups(userGroups.filter((x) => !hideGroup.includes(x.groupName)))
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
    }
  }, [taskGroupSelected])

  React.useEffect(() => {
    if (policyNum.length === 0 && searchPolicyNum.length > 0) {
      setSearchPolicyNum('')
    }
  }, [policyNum])

  let menusTab: { id: number; title: string }[] = []
  const checkDSTab =
    !!taskGroupSelected &&
    taskGroupSelected.type === 'DS' &&
    taskSelectedMap.size > 0 &&
    ['QC'].includes([...taskSelectedMap.values()][0].activity)
      ? true
      : false

  if (taskGroupSelected) {
    taskGroupSelected?.subTasks
      .filter((item) => checkBasketPermission(taskGroupSelected.type, item.code.subTask, permissions))
      .forEach((subTask, index) => {
        menusTab.push({
          id: index + 1,
          title: SubTaskLabel(subTask.code)(t)
        })
      })
  }

  let taskGroupHasPermission: SubTaskCount[] = []
  if (taskGroupSelected) {
    taskGroupHasPermission = taskGroupSelected?.subTasks.filter((item) =>
      checkBasketPermission(taskGroupSelected.type, item.code.subTask, permissions)
    )
  }

  const onChangeBasketTab = (tabIndex: number) => {
    setTabIndex(tabIndex)
    setTaskSelectedMap(new Map())
    setBasketSelected(undefined)
  }

  /** special screen, like distribution admin need individual screen */
  // const renderIndividualScreen: React.ReactNode | null = React.useMemo(() => {
  //   if (taskGroupSelected) {
  //     switch (taskGroupSelected.type) {
  //       case TaskType.DistributionService:
  //         return (
  //           <DATask
  //             taskType={'MyTask'}
  //             taskCount={taskGroupSelected}
  //             groupName={t('MyTasks')}
  //             categroryName={taskGroupSelected?.label(t) || ''}
  //             key={'mytask'}
  //           />
  //         )
  //       default:
  //         return null
  //     }
  //   }

  //   return null
  // }, [taskGroupSelected])

  return (
    <View style={{ flex: 1 }}>
      <CanView condition={isLoading}>
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="red" />
        </View>
      </CanView>

      <CanView condition={isEmptyTab}>
        <View style={styles.noDataContainer}>
          <Text>Empty</Text>
        </View>
      </CanView>

      <CanView condition={!isLoading}>
        <TaskGroupTabs
          type={Type.MyTask}
          permissions={permissions}
          containerStyle={isBigScreen ? wideStyles.tranTabContainer : mobileStyles.tranTabContainer}
          data={removeErrorHandlingTab(myTaskCount)}
          onTabChanged={setTaskGroupSelected}
        />

        {/* {renderIndividualScreen ?? ( */}
        <>
          <View style={{ flexDirection: 'row', marginBottom: isBigScreen ? 22 : 18 }}>
            <View
              style={[
                styles.controlContainer,
                { justifyContent: 'space-between' },
                // { flex: isBigScreen ? 0.75 : 1 },
                isWide ? { marginHorizontal: 24 } : { marginStart: 20, marginEnd: 12 }
              ]}
            >
              <CanView condition={isWide || (!isWide && taskSelectedMap.size > 0)}>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <TouchableOpacity onPress={() => setIsGirdView((prevState) => !prevState)}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      {isGridView ? <assets.TableViewIcon /> : <assets.GridViewIcon />}
                      <Text style={{ marginHorizontal: 10, color: '#ED1B2E', fontWeight: '700', fontSize: 16 }}>
                        {!isGridView ? t('TaskManagement:GridView') : t('TaskManagement:ListView')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <View style={{ width: 2, height: 28, backgroundColor: '#f1f1f1', marginRight: 10 }} />
                  <CanView
                    condition={
                      !!basketSelected &&
                      (basketSelected.code.subTask === SubTaskType.Verification ||
                        basketSelected.code.subTask === SubTaskType.Underwriting)
                    }
                  >
                    <TaskActionGroup>
                      <CanView
                        condition={
                          !!taskGroupSelected &&
                          hasTransferAction(taskGroupSelected, permissions) &&
                          !!basketSelected &&
                          basketSelected.code.subTask !== SubTaskType.QC &&
                          isCheckedOpenTransfer()
                        }
                      >
                        <TaskActionGroup.Item label={t('Transfer')} onPress={onPressTaskTransfer} />
                      </CanView>
                      {/* <CanView condition={isWide && !!taskGroupSelected && hasTransferAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Export')} />
                  </CanView> */}
                    </TaskActionGroup>
                  </CanView>
                  <CanView
                    condition={
                      !!basketSelected &&
                      (basketSelected.code.subTask === SubTaskType.QC ||
                        basketSelected.code.subTask === SubTaskType.QCUnderwriting)
                    }
                  >
                    <TaskActionGroup>
                      <CanView
                        condition={
                          !!taskGroupSelected &&
                          hasTransferAction(taskGroupSelected, permissions) &&
                          !!basketSelected &&
                          isCheckedOpenTransfer() &&
                          [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC].includes(
                            taskGroupSelected.type as TaskType
                          )
                        }
                      >
                        <TaskActionGroup.Item label={t('Transfer')} onPress={onPressTaskTransfer} />
                      </CanView>
                      {/* <CanView condition={isWide && !!taskGroupSelected && hasTransferAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Export')} />
                  </CanView> */}
                    </TaskActionGroup>
                  </CanView>
                  <CanView condition={!!basketSelected && basketSelected.code.subTask === SubTaskType.Adjudication}>
                    <TaskActionGroup>
                      <CanView
                        condition={
                          !!taskGroupSelected &&
                          hasTransferAction(taskGroupSelected, permissions) &&
                          !!basketSelected &&
                          basketSelected.code.subTask !== SubTaskType.QC
                        }
                      >
                        <TaskActionGroup.Item label={t('Transfer')} onPress={onPressTaskTransfer} />
                      </CanView>
                      {/* <CanView condition={isWide && !!taskGroupSelected && hasTransferAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Export')} />
                  </CanView> */}
                    </TaskActionGroup>
                  </CanView>
                  <CanView condition={!!basketSelected && basketSelected.code.subTask === SubTaskType.Suspend}>
                    {/* <TaskActionGroup> */}
                    {/* <CanView condition={!!taskGroupSelected && hasUnsuspendAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Unsuspend')} onPress={onPressTaskSuspend} />
                  </CanView> */}
                    {/* <CanView condition={isWide && !!taskGroupSelected && hasTransferAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Export')} />
                  </CanView> */}
                    {/* </TaskActionGroup> */}
                  </CanView>
                  <CanView condition={!!basketSelected && basketSelected.code.subTask === SubTaskType.ErrorHandling}>
                    <TaskActionGroup>
                      <CanView condition={!!taskGroupSelected && hasRetryAction(taskGroupSelected, permissions)}>
                        <TaskActionGroup.Item label={t('Retry')} />
                      </CanView>
                      {/* <CanView condition={isWide && !!taskGroupSelected && hasTransferAction(taskGroupSelected, permissions)}>
                    <TaskActionGroup.Item label={t('Export')} />
                  </CanView> */}
                    </TaskActionGroup>
                  </CanView>
                  <CanView
                    condition={
                      taskSelectedMap.size === 1 &&
                      !['PULSE4OPS', 'PULSE'].includes([...taskSelectedMap.values()][0].source)
                    }
                  >
                    <TaskActionGroup>
                      <CanView
                        condition={
                          !!taskGroupSelected && hasEndAction(taskGroupSelected, permissions) && checkDSTab === false
                        }
                      >
                        <TaskActionGroup.Item label={t('claim:End')} onPress={onPressTaskEnd} />
                      </CanView>
                    </TaskActionGroup>
                  </CanView>
                </View>
              </CanView>

              <SearchFilterInput
                value={policyNum}
                isFilterApplied={!!filter}
                onPressSearch={onPressSearchTask}
                onPressFilter={() => setFilterModalVisible(true)}
                hideInput={!isWide && taskSelectedMap.size > 0}
                containerStyle={isWide ? { alignSelf: 'flex-end' } : { flex: 1, justifyContent: 'flex-end' }}
                inputContainerStyle={
                  isBigScreen ? { width: 450 } : isWide ? { width: 350 } : { flex: 1, maxWidth: 350 }
                }
                onChangeSearchText={setPolicyNum}
                taskGroupSelected={taskGroupSelected}
              />
            </View>
          </View>

          <ScrollView style={{ height: 0 }}>
            {!isGridView ? (
              <View style={{ paddingHorizontal: 16 }}>
                <TabList menus={menusTab} tabIndex={tabIndex} onChangeTab={(e) => onChangeBasketTab(e)} />
                {taskGroupSelected &&
                  taskGroupHasPermission.length > 0 &&
                  taskGroupHasPermission[tabIndex] &&
                  taskGroupHasPermission[tabIndex].code &&
                  checkBasketPermission(
                    taskGroupSelected.type,
                    taskGroupHasPermission[tabIndex].code.subTask,
                    permissions
                  ) && (
                    <TaskTable
                      tabIndex={tabIndex}
                      searchPolicyNum={searchPolicyNum}
                      filter={filter}
                      cardType={'MyTask'}
                      permissions={permissions}
                      basketSelected={basketSelected}
                      taskSelected={taskSelectedMap}
                      onTaskSelected={onTaskSelected}
                      taskGroupSelected={taskGroupSelected}
                      onTaskUnSelected={onTaskUnSelected}
                      subTask={taskGroupHasPermission}
                      subServiceTypeList={subServiceTypeList}
                      subTransactionTypeList={subTransactionTypeList}
                      commonRequests={commonRequests}
                      // shouldRefresh={
                      //   taskGroupSelected && listMustRefesh && listMustRefesh.includes(taskGroupSelected.subTasks[tabIndex]?.code.subTask)
                      // }
                      // onRefreshed={() => {
                      //   taskGroupSelected && refreshSubject.next(taskGroupSelected.subTasks[tabIndex].code.subTask)
                      // }}
                    />
                  )}
              </View>
            ) : (
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={[
                    {
                      flex: isBigScreen ? 0.75 : 1,
                      marginStart: isBigScreen ? 24 : 20
                    },
                    { flexDirection: isBigScreen ? 'row' : 'column' }
                  ]}
                >
                  <CanView condition={!!taskGroupSelected && taskGroupSelected.subTasks.length === 0}>
                    <View style={[styles.noDataContainer, { minHeight: height * 0.55 }]}>
                      <Text>Empty</Text>
                    </View>
                  </CanView>

                  <CanView condition={!!taskGroupSelected && taskGroupSelected.subTasks.length > 0}>
                    {taskGroupSelected?.subTasks.map((subTask) => {
                      const hasPermission = checkBasketPermission(
                        taskGroupSelected.type,
                        subTask.code.subTask,
                        permissions
                      )
                      if (!hasPermission) return null
                      const subTaskType = subTask.code.subTask
                      const isSuspend = subTaskType === SubTaskType.Suspend
                      const canComment = permissions.includes(TASK_DETAIL_ROLES.ADD_COMMENT) && !isSuspend
                      const shouldRefresh = listMustRefesh.includes(subTaskType)
                      return (
                        <View
                          key={`${subTask.code.task}-${subTask.code.subTask}-${subTask.value}`}
                          style={[{ minHeight: isBigScreen ? height * 0.55 : 0 }, isBigScreen ? { flex: 1 } : {}]}
                        >
                          <TaskList
                            searchPolicyNum={searchPolicyNum}
                            shouldRefresh={shouldRefresh}
                            filter={filter}
                            canComment={canComment}
                            disableSelect={basketSelected && subTask.code.subTask !== basketSelected.code.subTask}
                            key={subTask.code.subTask}
                            basket={subTask}
                            title={subTask.label(t)}
                            cardType={'MyTask'}
                            taskSelected={taskSelectedMap}
                            containerStyle={
                              isBigScreen ? wideStyles.centerLaneContainer : mobileStyles.centerLaneContainer
                            }
                            onTaskSelected={(task) => onTaskSelected(subTask, task)}
                            onTaskUnSelected={onTaskUnSelected}
                            onPressTaskChatIcon={(task) => {
                              setTaskAddComment(task)
                              setCommentModalVisible(true)
                            }}
                            onRefreshed={() => {
                              refreshSubject.next(subTaskType)
                            }}
                            subServiceTypeList={subServiceTypeList}
                            subTransactionTypeList={subTransactionTypeList}
                            commonRequests={commonRequests}
                          />
                        </View>
                      )
                    })}
                  </CanView>
                </View>

                <CanView condition={isBigScreen}>
                  <View style={{ flex: 0.25, paddingTop: 22 }}>
                    {/* <Text style={{ color: '#4F4F4F', fontWeight: '600', fontSize: 14 }}>Remind</Text> */}
                  </View>
                </CanView>
              </View>
            )}
          </ScrollView>

          <TaskFilterFormModal
            groupName={t('MyTasks')}
            categroryName={taskGroupSelected?.label(t) || ''}
            visible={isFilterModalVisible}
            onPressSubmit={onSubmitTaskFilterForm}
            onPressClean={() => filter && setFilter(undefined)}
            onPressClose={() => setFilterModalVisible(false)}
            taskGroupSelected={taskGroupSelected}
          />

          <TaskTransferFormModal
            userGroups={transferUserGroups}
            visible={isTransferModalVisible && !isGlobalLoadingVisible}
            taskGroup={taskGroupSelected}
            basket={basketSelected}
            onPressClose={() => setTransferModalVisible(false)}
            onPressTransfer={onSubmitTaskTransferForm}
          />

          <TaskUnsuspendFormModal
            userGroups={userGroups}
            visible={isSuspendModalVisible && !isGlobalLoadingVisible}
            taskGroup={taskGroupSelected}
            basket={basketSelected}
            onPressClose={() => setSuspendModalVisible(false)}
            onPressTransfer={onSubmitTaskUnsuspendForm}
          />

          {/* <TaskCommentFormModal
            caseId={taskAddComment?.caseId}
            taskId={taskAddComment?.id}
            processId={taskAddComment?.processInstanceId}
            visible={isCommentModalVisible && !isGlobalLoadingVisible}
            onPressClose={() => {
              setTaskAddComment(undefined)
              setCommentModalVisible(false)
            }}
          /> */}

          <TaskAddCommentModal
            visible={isCommentModalVisible}
            caseId={taskAddComment?.caseId}
            taskId={taskAddComment?.id}
            processId={taskAddComment?.processInstanceId ?? ''}
            policyNum={taskAddComment?.policyNumber ?? ''}
            onPressClose={() => {
              setTaskAddComment(undefined)
              setCommentModalVisible(false)
            }}
          />

          <EndPopup
            open={isEndModalVisible}
            onClose={() => setEndModalVisible(false)}
            onSuccess={onEndTask}
            selectedValue={{
              taskId: [...taskSelectedMap.values()].map((item) => item.id)[0],
              updateToCore: false,
              category: taskGroupSelected?.type as string
            }}
          />
        </>
        {/* )} */}
      </CanView>
    </View>
  )
}

const styles = StyleSheet.create({
  controlContainer: {
    flexDirection: 'row',
    flex: 1
  },

  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  noDataContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const wideStyles = StyleSheet.create({
  tranTabContainer: {
    marginHorizontal: 24,
    marginVertical: 16
  },

  centerLaneContainer: {
    marginEnd: 16,
    marginVertical: 0
  }
})

const mobileStyles = StyleSheet.create({
  tranTabContainer: {
    marginHorizontal: 24,
    marginTop: 20,
    marginBottom: 22
  },

  centerLaneContainer: {
    marginHorizontal: 0,
    marginBottom: 16
  }
})
