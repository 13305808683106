import { FileUploadData, SelectOption } from "@pulseops/common";

export namespace CSGADDocumentForm {
  export interface CSGADCreatingDocumentInfo {
    GAOfficeCode: SelectOption | undefined
    docID: SelectOption | undefined
    CSArea: SelectOption | undefined
    uploadFiles: FileUploadData []
    errorMessage: string
    keyID: string
  }

  export interface CSGADCreatingDocumentData {
    newGADlist: Array<CSGADCreatingDocumentInfo>
  }

  export interface CSGADDocumentListData {
    from: Date | null
    to: Date | null
    GAOfficeCode: SelectOption | undefined
    docID: SelectOption | undefined
    CSArea: SelectOption | undefined
    isSelectedAll: boolean
    GADDocumentList: Array<CSGADDocumentDataInfo>
  }

  export type CSGADDocumentDataInfo= {
    historyID: string
    isSelectedItem: boolean
  }
}