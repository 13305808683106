import React from 'react'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { Error, FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import {
  AppContext,
  AuthService,
  DatePicker,
  ErrorHandling,
  FileUploadData,
  ImgUploadMutiple,
  ImgUploadMutiplePC,
  Input,
  OPLService,
  Permission,
  sharedStyle,
  StorageBlob
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { OPLDocumentForm } from './OPLDocumentForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'
import { OPLStackContext } from '../OPLStackContext'

type OPLCreatingDocumentProps = {
  documentType: string
  isActiveTab: boolean
  setIsActiveTabIndex: (val: number) => void
}

export const OPLCreatingDocument = (props: OPLCreatingDocumentProps) => {
  const { t } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { userPermissions } = React.useContext(OPLStackContext)
  const [userInfo, setUserInfo] = React.useState('')
  const { setValue, control, trigger, watch, getValues, reset } = useForm<OPLDocumentForm.OPLCreatingDocumentFormData>({
    defaultValues: {
      uploadFiles: [],
      fileName: '',
      title: '',
      content: '',
      code: '',
      expirationDate: null,
      createDate: null,
      createBy: '',
      modifiedDate: null,
      modifedBy: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (isFocused) {
      setValue(`createDate`, new Date())
      setValue(`modifiedDate`, new Date())
      pipe(
        AuthService.userInfo,
        ZIO.map((userData) => {
          setValue(`createBy`, userData.isGaLogin ? userData.name : userData.email)
          setValue(`modifedBy`, userData.isGaLogin ? userData.name : userData.email)
          setUserInfo(userData.isGaLogin ? userData.name : userData.email)
          return userData
        }),
        ErrorHandling.run()
      )
    }
    return () => {
      resetForm()
    }
  }, [isFocused])

  const resetForm = () => {
    reset({
      uploadFiles: [],
      fileName: '',
      title: '',
      content: '',
      code: '',
      expirationDate: null,
      createDate: new Date(),
      createBy: userInfo,
      modifiedDate: new Date(),
      modifedBy: userInfo
    })
  }

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const onUploadedFileEvent = (value: FileUploadData[]) => {
    if (value && value.length > 0) {
      setValue('fileName', value[0].fileName)
      trigger('fileName')
    } else {
      setValue('fileName', '')
    }
  }

  const getUploadFileWithMetaData = (formData: OPLDocumentForm.OPLCreatingDocumentFormData) => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    const batchno = moment(new Date()).format('DD/MM/YYYY')
    const metaDataRaw: StorageBlob.MetaDataUpload = {
      type: '',
      doctype: '',
      class: 'POLICYINFO',
      docid: '10406081',
      maindoc: 'GUIDELINE DOCUMENTS',
      subdoc: 'GUIDELINE',
      polnum: formData.code,
      batchno: batchno
    }
    // console.log('metaDataRaw:' + JSON.stringify(metaDataRaw))
    metaDataFiles = formData.uploadFiles.map((x) => {
      return {
        file: x.file,
        metaData: metaDataRaw
      }
    })
    return metaDataFiles
  }

  const getSubmitedData = (
    formData: OPLDocumentForm.OPLCreatingDocumentFormData,
    documents: {
      name: string
      url: string
    }[]
  ) => {
    // const expirationDate = formData.expirationDate ? formData.expirationDate.toISOString() : ''
    const expirationDate = formData.expirationDate ? moment(formData.expirationDate).format("YYYY-MM-DD") : ''
    const createdDate = formData.createDate ? formData.createDate.toISOString() : ''
    const modifiedDate = formData.modifiedDate ? formData.modifiedDate.toISOString() : ''
    const savedData: OPLService.NewOPLDocumentInfoRequest = {
      body: {
        id: null,
        documentType: props.documentType,
        fileName: formData.fileName,
        title: formData.title,
        content: formData.content,
        code: formData.code,
        expirationDate: expirationDate,
        createdDate: createdDate,
        createdBy: formData.createBy,
        modifiedDate: modifiedDate,
        modifiedBy: formData.modifedBy,
        status: 'Active',
        transactionType: 'PRUNET_UPLOAD',
        docNo: 'D1228',
        fromDate: '',
        toDate: ''
      },
      documents: documents
    }
    return savedData
  }

  const onSaveEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formData = getValues()
      const duplicatedCodeRequest: OPLService.DuplicateOPLDocumentCodeRequest = {
        body: {
          documentType: props.documentType,
          code: formData.code
        }
      }
      pipe(
        OPLService.checkDuplicateOPLDocumentCode(duplicatedCodeRequest),
        ZIO.flatMap((duplicatedResult) => {
          return duplicatedResult
            ? ZIO.succeed(showToast(t('message:MS020074'), 'error'))
            : pipe(
                ZIO.effect(() => getUploadFileWithMetaData(formData)),
                ZIO.flatMap((uploadFile) => StorageBlob.uploadToSubmit('', 'PRUNET_UPLOAD')(uploadFile)),
                ZIO.flatMap((azuredFiles) => {
                  const submitedData = getSubmitedData(formData, azuredFiles)
                  return OPLService.addNewOPLDocument(submitedData)
                }),
                ZIO.map((response) => {
                  if (response && response.status && response.status.message === 'MS020085') {
                    showToast(t('message:MS020085'), 'success')
                    props.setIsActiveTabIndex(0)
                  } else {
                    showToast(t('message:MS020071'), 'error')
                  }
                }),
                ZIO.mapError((error) => {
                  showToast(t('message:MS020071'), 'error')
                  return error
                })
              )
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <Controller
          control={control}
          name={'uploadFiles'}
          rules={{
            required: {
              value: true,
              message: t('message:MS150004')
            },
            validate: (val) => {
              if (val && val.length > 0 && val[0].fileName.length > 300) {
                return `${t('message:MS020068', { maxLength: 300 })}`
              } else {
                return true
              }
            }
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiplePC
                value={value as any[]}
                onChange={(val) => {
                  onChange(val)
                  onUploadedFileEvent(val)
                }}
                onBlur={onBlur}
                timeFormat={'DD/MM/YYYY HH:mm'}
                maxSizeFile={10}
                maxTotalFile={10}
                messageFormat={'OPL_CS:OPL:UploadFileFormatInfo'}
                validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']}
                messageMaxSize={'OPL_CS:OPL:MaxSizeFile10MB'}
                messageMaxTotalSize={'OPL_CS:OPL:MaxSizeFile10MB'}
                formatFileNameVi={true}
                maxNumFile={1}
                takeAPicture={false}
                showFullFileName={false}
                isFileDownload={true}
                errorMessage={''}
              />
              {error?.message && <Error message={error.message} />}
            </>
          )}
        />
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:FileName') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
                {/* <Input title={t('FileName')} value={''} required /> */}
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:Title') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('CustomerEngagement:Content') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      numberOfLines={4}
                      maxLength={300}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="code"
                  rules={{
                    validate: (val) => {
                      if (!val) {
                        return `${t('message:MS020001', { field: t('CustomerEngagement:Code') })}`
                      } else if (val.length !== 8) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Code')}
                      value={value}
                      maxLength={8}
                      inputType="number"
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  rules={{
                    validate: (val) => {
                      if (!!val && !moment(val).isValid()) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
      <View style={[OPLSharedStyles.buttonContainer]}>
        {userPermissions.includes(Permission.CancelOPLSDUploadDocument) && (
          <TouchableOpacity style={[sharedStyle.button]} onPress={() => resetForm()}>
            <Text style={sharedStyle.textButton}>{t('OPL_CS:OPL:Cancel')}</Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.SaveOPLSDUploadDocument) && (
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
            <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  )
}
const createDocStyles = StyleSheet.create({
  datePickerText: {
    marginBottom: 2
  }
})
