import React from 'react'
import {
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  makeStyles
} from '@material-ui/core'
// import { OBMuiTableStyles, OBSharedStyles } from '../ob-shared'
import { StyleSheet, StyleProp, View, ViewStyle, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { DataSource, TableHeaderSort } from '@pulseops/common'

// type DataSource = {
//   [key: string]: string | number | JSX.Element
// }

interface OBSelectedRowProps {
  index: number,
  selectItem: DataSource
}

export const OPLMuiTableStyles = makeStyles({
  root: {
    "& .MuiTableHead-root": {
      backgroundColor: '#F4F4F4'
    },
    "& .MuiTableCell-head": {
      color: '#58647A',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    "& .MuiTableCell-body": {
      color: '#1C1D1F',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
    }
  }
})

export interface CheckboxTableColum {
  title: string
  fieldName: string
  // colStyles?: React.CSSProperties
  minWidth?: number
  disabled?: boolean
  rowRender?: (props: OBSelectedRowProps) => JSX.Element
  headerColumnRender?: (list: any) => JSX.Element
}

type CheckboxTableProps = {
  displayedColumns: Array<CheckboxTableColum>
  dataSource: DataSource[]
  containerStyle?: StyleProp<ViewStyle>
  isPagination?: boolean
  totalRecord?: number
  pageSize?: number
  pageNum?: number
  setPageNum?: (value: number) => void
  setPageSize?: (value: number) => void
  setTotalRecord?: (totalNum: number) => void
  onPageChange?: (pageNum: number, pageSize: number) => void
  onRowsPerPageChange?: (pageNum: number, pageSize: number) => void
  rowsPerPageOptions?: Array<number>
  stickyHeader?: boolean
}

export const CheckboxTable = (props: CheckboxTableProps) => {
  const { t } = useTranslation()
  const { height, width } = useWindowDimensions()
  const classes = OPLMuiTableStyles()
  const {
    displayedColumns,
    dataSource,
    containerStyle,
    onPageChange,
    onRowsPerPageChange,
    totalRecord = 0,
    isPagination,
    pageNum = 0,
    pageSize = 10,
    setPageNum,
    setPageSize,
    setTotalRecord,
    rowsPerPageOptions
  } = props
  const [documentList, setDocumentList] = React.useState<DataSource[]>([])
  const [softItem, setSoftItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })

  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 300,
      maxWidth: width - 40
    }
  })

  React.useEffect(() => {
    setDocumentList(dataSource)
  }, [dataSource])
  return (
    <View style={[tableStyles.container, containerStyle]}>
      <TableContainer component={Paper} className={useStyles().container}>
        <Table classes={{ root: classes.root }} stickyHeader={props.stickyHeader}>
          <TableHead>
            {/* <TableRow>
              {displayedColumns && displayedColumns.map((colItem, index) => {
                return (
                  <TableCell style={{ minWidth: colItem.minWidth }} key={'colItem_' + index}>{colItem.title}</TableCell>
                )
              })}
            </TableRow> */}
            <TableHeaderSort
              data={documentList}
              setData={setDocumentList}
              setSortItem={setSoftItem}
              sortItem={softItem}
              column={[
                ...displayedColumns.map((columnItem) => {
                  return {
                    label: columnItem.title,
                    field: columnItem.fieldName,
                    disabled: columnItem.disabled,
                    columnRender: columnItem.headerColumnRender
                  }
                })
                // {
                //   label: '',
                //   disabled: true
                // },
                // {
                //   label: t('TaskManagement:Status'),
                //   disabled: true
                // }
              ]}
            />
          </TableHead>
          <TableBody>
            {documentList &&
              documentList.map((dataItem, index) => {
                return (
                  <TableRow key={`dataItem_${index}`}>
                    {displayedColumns.map((col, subIndex) => {
                      return (
                        <TableCell key={'col' + subIndex} style={{ minWidth: col.minWidth }}>
                          {col.rowRender
                            ? col.rowRender({ index: index, selectItem: dataItem })
                            // : col.fieldName === 'STT'
                            // ? index + 1
                            : dataItem[col.fieldName]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions ?? [10, 20]}
          count={totalRecord}
          rowsPerPage={pageSize}
          page={pageNum}
          onPageChange={(e, page) => {
            setPageNum && setPageNum(page)
            onPageChange && onPageChange(page, pageSize)
          }}
          onRowsPerPageChange={(e) => {
            const pageS = Number(e.target.value)
            setPageSize && setPageSize(pageS)
            if (totalRecord <= pageNum * pageS) {
              setPageNum && setPageNum(0)
              onRowsPerPageChange && onRowsPerPageChange(0, pageS)
            } else {
              onRowsPerPageChange && onRowsPerPageChange(pageNum, pageS)
            }
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        ></TablePagination>
      )}
    </View>
  )
}
export const tableStyles = StyleSheet.create({
  container: {
    marginVertical: 24
  },
})