import React from "react";

type OPLStackContext = {
  userPermissions: string []
}

const defaultData: OPLStackContext = {
  userPermissions: []
}

export const OPLStackContext = React.createContext<OPLStackContext>(defaultData)