import React from 'react'
import { View } from 'react-native'
import { OPLSharedStyles } from '../../opl'
import { AppContext, Permission, TabView, TabViewInfo } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { CSStackContext } from '../CSStackContext'
import { CSGADDocumentList } from './CSGADDocumentList'
import { CSGADCreatingDocument } from './CSGADCreatingDocument'
import { useIsFocused, useNavigation } from '@react-navigation/native'
export const CSGADDocument = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState(0)
  const { userPermissions } = React.useContext(CSStackContext)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const getTabList = () => {
    const tabList: TabViewInfo[] = []
    if (userPermissions.includes(Permission.ViewGADDocsList)) {
      tabList.push({
        tabName: t('OPL_CS:CS:List'),
        tabKey: 0
      })
    }
    if (userPermissions.includes(Permission.SaveUploadGADDocs)) {
      tabList.push({
        tabName: t('OPL_CS:CS:UploadDocument'),
        tabKey: 1
      })
    }
    return tabList
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:DocumentManagement'),
          navigate: () => rootNavigation.navigate('CSScreen')
        },
        {
          title: t('OPL_CS:CS:GADDocuments'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getComponentByTabIndex = () => {
    switch (isActiveTabIndex) {
      case 0:
        return userPermissions.includes(Permission.ViewGADDocsList) ? (
          <CSGADDocumentList></CSGADDocumentList>
        ) : (
          <></>
        )
      case 1:
        return userPermissions.includes(Permission.SaveUploadGADDocs) ? (
          <CSGADCreatingDocument setIsActiveTabIndex={setIsActiveTabIndex}></CSGADCreatingDocument>
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }

  return (
    <View style={OPLSharedStyles.wrapper}>
      <TabView
        isActiveTabIndex={isActiveTabIndex}
        setIsActiveTabIndex={setIsActiveTabIndex}
        data={getTabList()}
      ></TabView>
      {getComponentByTabIndex()}
    </View>
  )
}
