import { ModalAction, ModalComponent } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, ScrollView, StyleSheet } from 'react-native'

export type OPLDeleteModalProps = {
  headerTitle: string
  message: string
  isVisiable: boolean
  onCloseModal: () => void
  onCancelEvent?: () => void
  onYesEvent?: () => void
}
const OPLDeleteModal = (props: OPLDeleteModalProps) => {
  const { headerTitle, isVisiable, onCloseModal, message, onCancelEvent, onYesEvent } = props
  const { t } = useTranslation()
  const actions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: () => onCancelEvent && onCancelEvent(),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: modalStyles.btnCancel
    },
    {
      text: t('OPL_CS:OPL:Yes'),
      type: 'filled',
      action: () => onYesEvent && onYesEvent(),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: {}
    }
  ]
  return (
    <ModalComponent
      title={headerTitle}
      visible={isVisiable}
      onClose={onCloseModal}
      centerTitle={false}
      actions={actions}
    >
      <ScrollView style={modalStyles.modalContent}>
        <Text> {message}</Text>
      </ScrollView>
    </ModalComponent>
  )
}

export default OPLDeleteModal
const modalStyles = StyleSheet.create({
  modalContent: {
    marginHorizontal: 20
  },
  btnCancel: {
   marginRight: 15
  },
})
