import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
export namespace OPLService {
  export const OPLDocumentListInput = t.type({
    // id: t.string,
    documentType: t.string,
    fileName: t.string,
    title: t.string,
    content: t.string,
    code: t.string,
    expirationDate: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    status: t.string,
    transactionType: t.string,
    docNo: t.string,
    start: t.number,
    size: t.number,
    fromDate: t.string,
    toDate: t.string,
  })

  export const OPLDocumentInfo = t.type({
    id: t.string,
    documentType: t.string,
    fileName: t.string,
    title: t.string,
    content: t.string,
    code: t.string,
    expirationDate: Maybe(t.string),
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    status: t.string,
    urlFilenet: t.string
  })

  export const OPLDocumentListResponse = t.type({
    body: t.array(OPLDocumentInfo),
    start: t.number,
    size: t.number,
    total: t.number,
    // status: t.type({
    //   message: t.string
    // })
  })

  export const NewOPLDocumentInfo = t.type({
    id: Maybe(t.string),
    documentType: t.string,
    fileName: t.string,
    title: t.string,
    content: t.string,
    code: t.string,
    expirationDate: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    status: t.string,
    transactionType: t.string,
    docNo: t.string,
    fromDate: t.string,
    toDate: t.string
  })

  export const NewOPLDocumentInfoRequest = t.type({
    body: NewOPLDocumentInfo,
    documents: t.array(t.type({
      name: t.string,
      url: t.string
    }))
  })

  export const NewOPLDocumentResponse = t.type({
    // body: t.array(NewOPLDocumentInfo),
    // start: t.number,
    // size: t.number,
    // total: t.number,
    body: Maybe(t.string),
    status: t.type({
      message: Maybe(t.string)
    })
  })

  export const DuplicateOPLDocumentCodeRequest = t.type({
    body: t.type({
      documentType: t.string,
      code: t.string
    })
  })

  export const UpdateOPLDocumentRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      documentType: t.string,
      fileName: t.string,
      title: t.string,
      content: t.string,
      code: t.string,
      expirationDate: t.string,
      createdDate: t.string,
      createdBy: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      status: t.string
    }))
  })

  export const UpdateOPLDocumentResponse = t.type({
    status: t.type({
      message: t.string
    })
  })

  export const DeleteOPLDocumentRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      status: t.string
    }))
  })

  //signature
  export const NewOPLSignatureInfo = t.type({
    id: Maybe(t.string),
    fileName: t.string,
    title: t.string,
    content: t.string,
    adCode: t.string,
    gaCode: t.string,
    expirationDate: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    signatureStatus: t.string,
    transactionType: t.string,
    docNo: t.string
  })

  export const NewOPLSignatureInfoRequest = t.type({
    body: NewOPLSignatureInfo,
    documents: t.array(t.type({
      name: t.string,
      url: t.string
    }))
  })

  export const OPLSignatureListRequest = t.type({
    fileName: t.string,
    title: t.string,
    content: t.string,
    adCode: t.string,
    gaCode: t.string,
    expirationDate: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    signatureStatus: t.string,
    transactionType: t.string,
    start: t.number,
    size: t.number,
    fromDate: t.string,
    toDate: t.string,
  })


  export const OPLSignatureInfo = t.type({
    id: t.string,
    fileName: t.string,
    title: t.string,
    content: t.string,
    adCode: t.string,
    gaCode: Maybe(t.string),
    expirationDate: Maybe(t.string),
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    signatureStatus: t.string,
    urlFilenet: t.string
  })

  export const OPLSignatureListResponse = t.type({
    body: t.array(OPLSignatureInfo),
    start: t.number,
    size: t.number,
    total: t.number
  })

  export const OPLSignatureDetailResponse = t.type({
    body: OPLSignatureInfo
  })

  export const UpdateOPLSignatureRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      fileName: t.string,
      title: t.string,
      content: t.string,
      adCode: t.string,
      gaCode: t.string,
      expirationDate: t.string,
      createdDate: t.string,
      createdBy: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      signatureStatus: t.string
    }))
  })

  export const DeleteOPLSignatureRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      signatureStatus: t.string
    }))
  })


  export type OPLDocumentListInput = t.TypeOf<typeof OPLDocumentListInput>
  export type OPLDocumentInfo = t.OutputOf<typeof OPLDocumentInfo>
  export type NewOPLDocumentInfoRequest = t.OutputOf<typeof NewOPLDocumentInfoRequest>
  export type DuplicateOPLDocumentCodeRequest = t.OutputOf<typeof DuplicateOPLDocumentCodeRequest>
  export type UpdateOPLDocumentRequest = t.OutputOf<typeof UpdateOPLDocumentRequest>
  export type DeleteOPLDocumentRequest = t.OutputOf<typeof DeleteOPLDocumentRequest>
  export type NewOPLSignatureInfo = t.OutputOf<typeof NewOPLSignatureInfo>
  export type NewOPLSignatureInfoRequest = t.OutputOf<typeof NewOPLSignatureInfoRequest>
  export type OPLSignatureListRequest = t.OutputOf<typeof OPLSignatureListRequest>
  export type OPLSignatureListResponse = t.OutputOf<typeof OPLSignatureListResponse>
  export type OPLSignatureInfo = t.OutputOf<typeof OPLSignatureInfo>
  export type OPLSignatureDetailResponse = t.OutputOf<typeof OPLSignatureDetailResponse>
  export type UpdateOPLSignatureRequest = t.OutputOf<typeof UpdateOPLSignatureRequest>
  export type DeleteOPLSignatureRequest = t.OutputOf<typeof DeleteOPLSignatureRequest>

  export const getOPLDocumentList = (bodyData: OPLDocumentListInput) => pipe(
    POApi.post(`prunet/search-opl-document`)(OPLDocumentListResponse)(bodyData),
    ZIO.map((documentList) => {
      return documentList
    })
  )

  export const addNewOPLDocument = (bodyData: NewOPLDocumentInfoRequest) => pipe(
    POApi.post(`prunet/add-opl-document`)(NewOPLDocumentResponse)(bodyData),
    ZIO.map((newDocumentResponse) => newDocumentResponse)
  )

  export const checkDuplicateOPLDocumentCode = (data: DuplicateOPLDocumentCodeRequest) => pipe(
    POApi.post(`prunet/check-duplicate-code-opl-document`)(t.type({
      body: t.type({
        isOPLDuplicatedCode: t.boolean
      })
    }))(data),
    ZIO.map((response) => response.body.isOPLDuplicatedCode)
  )

  export const getOPLDocumentDetailByID = (OPLDocumentID: string) => pipe(
    POApi.get(`prunet/get-opl-document/${OPLDocumentID}`)(OPLDocumentInfo),
    ZIO.map((detailData) => detailData)
  )

  export const updateOPLDocument = (bodyData: UpdateOPLDocumentRequest) => pipe(
    POApi.post(`prunet/update-opl-document`)(UpdateOPLDocumentResponse)(bodyData),
    ZIO.map((response) => response)
  )
  export const deleteOPLDocument = (bodyData: DeleteOPLDocumentRequest) => pipe(
    POApi.post(`prunet/update-opl-document`)(UpdateOPLDocumentResponse)(bodyData),
    ZIO.map((response) => response)
  )

  export const addNewOPLSignature = (newData: NewOPLSignatureInfoRequest) => pipe(
    POApi.post(`prunet/add-signature`)(NewOPLDocumentResponse)(newData),
    ZIO.map((newSignatureResponse) => newSignatureResponse)
  )

  export const getOPLSignatureList = (queryData: OPLSignatureListRequest) => pipe(
    POApi.post(`prunet/search-signature`)(OPLSignatureListResponse)(queryData)
  )

  export const getADCodeFromSystem = (codeType: string = 'SIGNATURE') => pipe(
    POApi.get(`prunet/get-code/${codeType}`)(t.type({
      body: t.type({
        code: t.number
      })
    })),
    ZIO.map((response) => response.body.code)
  )

  export const getOPLSignatureDetailByID = (OPLSignatureID: string) => pipe(
    POApi.get(`prunet/get-signature/${OPLSignatureID}`)(OPLSignatureDetailResponse),
    ZIO.map((detailData) => detailData.body)
  )

  export const updateOPLSignature = (data: UpdateOPLSignatureRequest) => pipe(
    POApi.post(`prunet/update-signature`)(UpdateOPLDocumentResponse)(data),
    ZIO.map((response) => response)
  )

  export const deleteOPLSignature = (data: DeleteOPLSignatureRequest) => pipe(
    POApi.post(`prunet/update-signature`)(UpdateOPLDocumentResponse)(data),
    ZIO.map((response) => response)
  )
}