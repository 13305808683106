import { FileUploadData, SelectOption } from "@pulseops/common"

export namespace CSProcessGuidelineDocForm {
  export interface CSProcessGuidelineCreatingDocInfo {
    docID: SelectOption | undefined
    uploadFiles: FileUploadData[]
    errorMessage: string
    keyID: string
  }

  export interface CSProcessGuidelineCreatingDocData {
    newGuidelinelist: Array<CSProcessGuidelineCreatingDocInfo>
  }

  export interface CSProcessGuidelineDocListData {
    from: Date | null
    to: Date | null
    docID: SelectOption | undefined
    isSelectedAll: boolean
    guidelineDoclist: Array<CSProcessGuidelineDocDataInfo>
  }

  export type CSProcessGuidelineDocDataInfo = {
    historyID: string
    isSelectedItem: boolean
  }
}