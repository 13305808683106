import React from 'react'
import { StyleSheet, Text, ScrollView, TouchableOpacity, View } from 'react-native'
import { OPLDocumentConst, OPLSharedStyles } from '../../opl'
import {
  AppContext,
  assets,
  AuthService,
  CSService,
  ErrorHandling,
  GeneralService,
  ImgUploadMutiplePC,
  OfficeCode,
  OPLService,
  Panel,
  Permission,
  PulseOpsFormat,
  SelectSearch,
  sharedStyle,
  StorageBlob
} from '@pulseops/common'
import { Error, FieldText, SectionCol, SectionRow } from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
import { CSStackContext } from '../CSStackContext'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { CSGADDocumentForm } from './CSGADDocumentForm'
import { useIsFocused } from '@react-navigation/native'
import { CSGADDocumentConst } from './CSGADDocumentConst'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
type CSGADCreatingDocumentProps = {
  setIsActiveTabIndex: (index: number) => void
}
export const CSGADCreatingDocument = (props: CSGADCreatingDocumentProps) => {
  const { t } = useTranslation()
  const { userPermissions } = React.useContext(CSStackContext)
  const isFocused = useIsFocused()
  const [userInfo, setUserInfo] = React.useState('')
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { control, getValues, watch, setValue, trigger } = useForm<CSGADDocumentForm.CSGADCreatingDocumentData>({
    defaultValues: {
      newGADlist: []
    },
    mode: 'onChange'
  })
  const GADFieldArray = useFieldArray<CSGADDocumentForm.CSGADCreatingDocumentData>({
    control: control,
    name: 'newGADlist'
  })

  const GAOfficeCodeList: OfficeCode[] = pipe(
    GeneralService.getALLOfficeList(),
    ZIO.map((officeList) => {
      return officeList && officeList.length > 0 ? officeList : []
    }),
    ErrorHandling.runDidMount([])
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      initNewGADFields()
      pipe(
        AuthService.userInfo,
        ZIO.map((userData) => {
          setUserInfo(userData.isGaLogin ? userData.name : userData.email)
          return userData
        }),
        ZIO.unsafeRun({})
      )
    }
    return () => {
      GADFieldArray.remove()
      setValue('newGADlist', [])
    }
  }, [isFocused])

  const initNewGADFields = () => {
    GADFieldArray.append({
      GAOfficeCode: undefined,
      docID: undefined,
      CSArea: undefined,
      uploadFiles: [],
      errorMessage: '',
      keyID: PulseOpsFormat.generateUUID()
    })
  }

  const onAddNewGADDocBlock = () => {
    const newGADDoclist = watch('newGADlist')
    if (newGADDoclist.length < 5) {
      initNewGADFields()
    }
  }
  const onRemoveGADDocBlock = (index: number) => {
    GADFieldArray.remove(index)
    const newGADlist = watch('newGADlist')
    pipe(
      newGADlist,
      A.map((item) => {
        const newIndex = newGADlist.indexOf(item)
        GADFieldArray.update(newIndex, {
          GAOfficeCode: item.GAOfficeCode,
          docID: item.docID,
          CSArea: item.CSArea,
          uploadFiles: item.uploadFiles,
          errorMessage: item.errorMessage,
          keyID: item.keyID
        })
        setValue(`newGADlist.${newIndex}.GAOfficeCode`, item.GAOfficeCode)
        setValue(`newGADlist.${newIndex}.docID`, item.docID)
        setValue(`newGADlist.${newIndex}.CSArea`, item.CSArea)
        setValue(`newGADlist.${newIndex}.uploadFiles`, item.uploadFiles)
        setValue(`newGADlist.${newIndex}.errorMessage`, item.errorMessage)
        setValue(`newGADlist.${newIndex}.keyID`, item.keyID)
        return item
      })
    )
  }

  const onResetEvent = () => {
    const newGADlist = watch('newGADlist')
    pipe(
      newGADlist,
      A.map((item) => {
        const newIndex = newGADlist.indexOf(item)
        GADFieldArray.update(newIndex, {
          GAOfficeCode: undefined,
          docID: undefined,
          CSArea: undefined,
          uploadFiles: [],
          errorMessage: '',
          keyID: item.keyID
        })
        setValue(`newGADlist.${newIndex}.GAOfficeCode`, undefined)
        setValue(`newGADlist.${newIndex}.docID`, undefined)
        setValue(`newGADlist.${newIndex}.CSArea`, undefined)
        setValue(`newGADlist.${newIndex}.uploadFiles`, [])
        setValue(`newGADlist.${newIndex}.errorMessage`, '')
        setValue(`newGADlist.${newIndex}.keyID`, item.keyID)
        return item
      })
    )
  }

  const onUploadedFileEvent = (selectedIndex: number) => {
    setValue(`newGADlist.${selectedIndex}.errorMessage`, '')
  }

  const deleteSuccessfulSavedBlocks = (
    resultList: Array<{
      keyID: string
      message: string
    }>
  ) => {
    for (let i = 0; i < resultList.length; i++) {
      if (resultList[i] && resultList[i].message === 'MS020085') {
        const originGADlist = watch('newGADlist')
        const deletedIndex = originGADlist.indexOf(
          originGADlist.find((x) => x.keyID === resultList[i].keyID) as CSGADDocumentForm.CSGADCreatingDocumentInfo
        )
        onRemoveGADDocBlock(deletedIndex)
      }
    }
    //set error message to show in UI for each unsaved block
    const newGADlist = watch('newGADlist')
    pipe(
      watch('newGADlist'),
      A.map((item) => {
        const newIndex = newGADlist.indexOf(item)
        setValue(`newGADlist.${newIndex}.errorMessage`, 'MS020080')
        return item
      })
    )
  }

  const getUploadFileWithMetaData = (formData: CSGADDocumentForm.CSGADCreatingDocumentInfo, systemCode: number) => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    const batchno = moment(new Date()).format('DD/MM/YYYY')
    const docIDInfo = CSGADDocumentConst.DocIDConstList.find((p) => p.docID === formData.docID?.value)
    const metaDataRaw: StorageBlob.MetaDataUpload = {
      type: '',
      doctype: '',
      class: 'POLICYINFO',
      docid: docIDInfo?.docID || '',
      maindoc: docIDInfo?.mainDoc || '',
      subdoc: docIDInfo?.subDoc || '',
      polnum: systemCode.toString(),
      propnum: formData.GAOfficeCode?.value || '',
      batchno: batchno,
      functionType: 'uploadDocument'
    }
    metaDataFiles = formData.uploadFiles.map((x) => {
      return {
        file: x.file,
        metaData: metaDataRaw
      }
    })
    return metaDataFiles
  }

  const getSubmitedData = (
    formData: CSGADDocumentForm.CSGADCreatingDocumentInfo,
    documents: {
      name: string
      url: string
    }[],
    code: number
  ) => {
    const createdDate = new Date().toISOString()
    const modifiedDate = new Date().toISOString()
    const fileName = formData.uploadFiles && formData.uploadFiles.length > 0 ? formData.uploadFiles[0].fileName : ''
    const docIDInfo = CSGADDocumentConst.DocIDConstList.find((p) => p.docID === formData.docID?.value)
    const document =
      documents && documents.length > 0
        ? documents[0]
        : {
            name: '',
            url: ''
          }
    const savedData: CSService.NewGADDocumentRequest = {
      id: null,
      code: code.toString(),
      documentType: 'CS_GAD',
      fileName: fileName,
      gaCode: formData.GAOfficeCode?.value || '',
      csArea: formData.CSArea?.value || '',
      docId: formData.docID?.value || '',
      createdDate: createdDate,
      createdBy: userInfo,
      modifiedDate: modifiedDate,
      modifiedBy: userInfo,
      activityDate: createdDate,
      status: OPLDocumentConst.OPLDocumentStatusConst.Active,
      transactionType: 'PRUNET_UPLOAD',
      docNo: docIDInfo?.docNo || '',
      document: document,
      keyID: formData.keyID
    }
    return savedData
  }

  const onSaveEvent = async () => {
    const isValidData = await trigger()
    if (isValidData) {
      const formData = getValues()
      pipe(
        OPLService.getADCodeFromSystem('CS_GAD'),
        ZIO.flatMap((codeNo) =>
          pipe(
            formData.newGADlist,
            A.map((item) =>
              pipe(
                ZIO.effect(() => getUploadFileWithMetaData(item, codeNo)),
                ZIO.flatMap((uploadFiles) => StorageBlob.uploadToSubmit('', 'PRUNET_UPLOAD')(uploadFiles)),
                ZIO.map((azuredFiles) => {
                  const submitedItemData = getSubmitedData(item, azuredFiles, codeNo)
                  return submitedItemData
                })
              )
            ),
            ZIO.sequence
          )
        ),
        ZIO.flatMap((submitedData) => {
          return CSService.addNewGADDocument(submitedData)
        }),
        ZIO.map((response) => {
          if (response && response.status && response.status.message === 'MS020085') {
            showToast(t('message:MS020085'), 'success')
            props.setIsActiveTabIndex(0)
          } else {
            deleteSuccessfulSavedBlocks(response.body)
            // showToast(t('message:MS020080'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:MS020080'), 'error')
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }
  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      {watch('newGADlist').map((fieldItem, index) => {
        return (
          <Panel containerStyle={creatingDocument.panelContent} key={'GADFieldArray_' + index}>
            <SectionRow>
              <SectionCol>
                <Controller
                  control={control}
                  name={`newGADlist.${index}.GAOfficeCode`}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('OPL_CS:CS:GAOfficeCode') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      label={t('OPL_CS:CS:GAOfficeCode')}
                      options={GAOfficeCodeList.map((item) => ({ label: item.code, value: item.code }))}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                      }}
                      onBlur={onBlur}
                      //  disabled={isDisabledEdit}
                      errorMessage={!value || !value.value ? error?.message : ''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`newGADlist.${index}.docID`}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('OPL_CS:CS:DocIDDocumentType') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      label={t('OPL_CS:CS:DocIDDocumentType')}
                      options={CSGADDocumentConst.DocIDConstList.map((item) => ({
                        label: item.docName,
                        value: item.docID
                      }))}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                      }}
                      //  disabled={isDisabledEdit}
                      errorMessage={!value || !value.value ? error?.message : ''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`newGADlist.${index}.CSArea`}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('OPL_CS:CS:CSArea') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      label={t('OPL_CS:CS:CSArea')}
                      options={CSGADDocumentConst.CSAreaConstList}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                      }}
                      //  disabled={isDisabledEdit}
                      errorMessage={!value || !value.value ? error?.message : ''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
                <Controller
                  control={control}
                  name={`newGADlist.${index}.uploadFiles`}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS150004')
                    },
                    validate: (val) => {
                      if (val && val.length > 0 && val[0].fileName.length > 300) {
                        return `${t('message:MS020068', { maxLength: 300 })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <>
                      <ImgUploadMutiplePC
                        value={value as any[]}
                        onChange={(val) => {
                          onChange(val)
                          onUploadedFileEvent(index)
                        }}
                        onBlur={onBlur}
                        timeFormat={'DD/MM/YYYY HH:mm'}
                        maxSizeFile={10}
                        maxTotalFile={10}
                        // messageFormat={'OPL_CS:OPL:UploadFileFormatInfo'}
                        validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']}
                        messageMaxSize={'message:MS020083'}
                        messageMaxTotalSize={'message:MS020083'}
                        formatFileNameVi={true}
                        maxNumFile={1}
                        takeAPicture={false}
                        showFullFileName={false}
                        isFileDownload={true}
                        errorMessage={''}
                      />
                      {error?.message && <Error message={error.message} />}
                    </>
                  )}
                />
              </SectionCol>
            </SectionRow>
            {!!watch(`newGADlist.${index}.errorMessage`) && (
              <View style={creatingDocument.messageContent}>
                <Text style={creatingDocument.messageText}>
                  {t(`message:${watch(`newGADlist.${index}.errorMessage`)}`)}
                </Text>
              </View>
            )}
            <View style={OPLSharedStyles.btnDeleteContainer}>
              {userPermissions.includes(Permission.DeleteUploadGADDocs) && (
                <TouchableOpacity
                  style={[
                    sharedStyle.button,
                    OPLSharedStyles.btnDeleteContent,
                    GADFieldArray.fields.length <= 1 && sharedStyle.btnDisabled
                  ]}
                  onPress={() => onRemoveGADDocBlock(index)}
                  disabled={GADFieldArray.fields.length <= 1}
                >
                  <Text
                    style={[sharedStyle.textButton, GADFieldArray.fields.length <= 1 && sharedStyle.btnTextDisabled]}
                  >
                    {t('claim:Delete')}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </Panel>
        )
      })}

      <View style={OPLSharedStyles.btnAddContainer}>
        {userPermissions.includes(Permission.AddUploadGADDocs) && (
          <TouchableOpacity style={[sharedStyle.button, OPLSharedStyles.btnAddContent]} onPress={onAddNewGADDocBlock}>
            <Text style={sharedStyle.textButton}>{t('common:Add')}</Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={[OPLSharedStyles.buttonContainer]}>
        {userPermissions.includes(Permission.ResetUploadGADDocs) && (
          <TouchableOpacity style={[sharedStyle.button]} onPress={() => onResetEvent()}>
            <Text style={sharedStyle.textButton}>{t('OPL_CS:CS:Reset')}</Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.SaveUploadGADDocs) && (
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
            <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  )
}
const creatingDocument = StyleSheet.create({
  panelContent: {
    marginTop: 15
  },
  messageContent: {
    marginTop: 10
  },
  messageText: {
    fontSize: 13,
    color: '#ED1B2C'
  }
})
