import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { CSStackParamList } from './CSStackParamList'
import { CSScreen } from './CSScreen'
import { pipe } from 'fp-ts/lib/function'
import { ErrorHandling, RBAC } from '@pulseops/common'
import { CSStackContext } from './CSStackContext'
import { CSGADDocument } from './cs-gad-document'
import { CSProcessGuidelineDoc } from './cs-process-guideline-document'
const stack = createStackNavigator<CSStackParamList>()
export const CSStack = () => {
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidMount([]))
  return (
    <CSStackContext.Provider value={{ userPermissions: permissions }}>
      <stack.Navigator initialRouteName="CSScreen" screenOptions={{ headerShown: false }}>
        <stack.Screen name="CSScreen" component={CSScreen} />
        <stack.Screen name="CSGADDocument" component={CSGADDocument}/>
        <stack.Screen name="CSProcessGuidelineDoc" component={CSProcessGuidelineDoc}/>
      </stack.Navigator>
    </CSStackContext.Provider>
  )
}
