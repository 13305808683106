import React from 'react'
import { Text, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { AppContext, Permission, TabView, TabViewInfo } from '@pulseops/common'
import { OPLListSignature } from './OPLListSignature'
import { OPLCreatingSignature } from './OPLCreatingSignature'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { OPLStackContext } from '../OPLStackContext'
export const OPLSignature = () => {
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState(0)
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const { t } = useTranslation()
  const { userPermissions } = React.useContext(OPLStackContext)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const getTabList = () => {
    const tabList: TabViewInfo[] = []
    if (userPermissions.includes(Permission.ViewOPLSDSignatureList)) {
      tabList.push({
        tabName: t('OPL_CS:OPL:ListSignature'),
        tabKey: 0
      })
    }
    if (userPermissions.includes(Permission.ViewOPLSDUploadSignature)) {
      tabList.push({
        tabName: t('OPL_CS:OPL:CreateSignature'),
        tabKey: 1
      })
    }
    return tabList
  }
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:OPL'),
          navigate: () => rootNavigation.navigate('OPLScreen')
        },
        {
          title: t('OPL_CS:OPL:Signature'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const getComponentByTabIndex = () => {
    switch (isActiveTabIndex) {
      case 0:
        return userPermissions.includes(Permission.ViewOPLSDSignatureList) ? (
          <OPLListSignature></OPLListSignature>
        ) : (
          <></>
        )
      case 1:
        return userPermissions.includes(Permission.ViewOPLSDUploadSignature) ? (
          <OPLCreatingSignature
            isActiveTab={isActiveTabIndex === 1}
            setIsActiveTabIndex={setIsActiveTabIndex}
          ></OPLCreatingSignature>
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }
  return (
    <View style={OPLSharedStyles.wrapper}>
      <TabView
        isActiveTabIndex={isActiveTabIndex}
        setIsActiveTabIndex={setIsActiveTabIndex}
        data={getTabList()}
      ></TabView>
      {getComponentByTabIndex()}
    </View>
  )
}
