import * as D from 'date-fns'
import moment from 'moment'
export namespace PulseOpsFormat {
  export const toCurrentTimeZone = (str: string, fi: string, fo: string) => {
    const inp = moment(str, fi).utc(false).toDate()
    const out = moment(inp).utc(true).format(fo)
    console.log(inp, out)
    return out
  }
  export const thousandRegex = /(\d)(?=(?:[0-9]{3})+\b)/g
  export const datetoFormat = (date: Date | null | undefined, format: string) =>
    date ? moment(date).format(format) : '-'
  export const dateStringtoFormat = (date: string | null | undefined, format: string) =>
    date ? moment(date).format(format) : '-'
  export const dateWF = (date: string | undefined, fi: string, fo: string) => (date ? moment(date, fi).format(fo) : '-')
  export const date = (date: Date | null | undefined) => (date ? datetoFormat(date, 'DD/MM/yyyy') : '')
  export const stringToDate = (date: string | null | undefined) => (date ? moment(date, 'DD/MM/yyyy').toDate() : null)
  export const dateStr = (date: string) => `${date.substr(6, 2)}/${date.substr(4, 2)}/${date.substr(0, 4)}`
  export const timeStrToDate = (time: string) =>
    new Date(`${time.substr(3, 2)}/${time.substr(0, 2)}/${time.substr(6, 4)} ${time.substr(-8)}`)
  export const timeStrUTCToLocal = (time: string) =>
    new Date(`${time.substr(6, 4)}-${time.substr(3, 2)}-${time.substr(0, 2)}T${time.substr(-5)}Z`)
  export const timeStrUTCToLocalFormat = (time: string, format: string) => datetoFormat(timeStrUTCToLocal(time), format)
  export const dateStrWF = (date: string | null | undefined, format: string): string =>
    !!date && date !== '0' && date !== '99999999' && date != '9999-99-99' && date !== '-'
      ? moment(date).format(format)
      : '-'
  export const getAge = (date: Date) => Number(moment().diff(moment(date), 'years'))
  export const getAgeFromDate = (date: string | null | undefined, format: string) =>
    date ? Number(moment().diff(moment(date, format), 'years')) : 0
  export const getStringToDate = (str: string, format: string): Date => moment(str, format).toDate()
  export const getDifferenceInDays = (dateLeft: Date | number, dateRight: Date | number): number =>
    D.differenceInDays(dateLeft, dateRight)

  export const currency = (value: number) => new Intl.NumberFormat().format(value)

  export const currencyGetFull = (value: number, minimum: number) => new Intl.NumberFormat("en", {
    minimumFractionDigits: minimum,
  }).format((value))

  export const thousandSepartor = (value: number | null | undefined): string =>
    value
      ? Math.floor(value)
          .toString()
          .replace(/(\d)(?=(?:[0-9]{3})+\b)/g, '$1,')
      : '0'

  export const thousandSepartorReverse = (value: string): number => Number(value.replace(/,/g, ''))

  export const thousandSepartorStr = (value: string | undefined, defaultValue = '', extendValue = ''): string =>
    value ? thousandSepartor(thousandSepartorReverse(value)) + extendValue : defaultValue

  export const thousandSepartorCleanBefore = (value: string | undefined) =>
    value ? thousandSepartor(thousandSepartorReverse(value)) : ''

  export const gender = (value: string) =>
    value.toUpperCase() === 'F' ? 'Female' : value.toUpperCase() === 'M' ? 'Male' : value

  export const genderT = (t: (val: string) => string) => (value: string) =>
    value.toUpperCase() === 'F' ? t('common:Female') : value.toUpperCase() === 'M' ? t('common:Female') : value

  export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)
  export const phoneNumber = (number: string | null | undefined, code: string | null | undefined): string =>
    number ? `${code ? `+${code} ` : ''}${number}` : '-'
  export const onlyUniqueArrayFilter = <T>(value: T, index: number, self: Array<T>) => self.indexOf(value) === index
  export const noneEmptyAndUnique = <T>(value: T, index: number, self: Array<T>) =>
    !!value && self.indexOf(value) === index

  export const currentDateWF = (f: string) => moment(new Date()).format(f)

  export const InputNUM = (onChange: (val: string) => void) => (val: string) => {
    const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    onChange(newVal)
  }

  export const startOfDate = (date: Date, timezoneUTC?: boolean): Date => {
    return timezoneUTC
      ? moment(date)
          .startOf('day')
          .add(new Date().getTimezoneOffset() * -1, 'minutes')
          .toDate()
      : moment(date).startOf('day').toDate()
  }

  export const serviceInquiry_StartOfDate = (date: Date, timezoneUTC?: boolean): Date => {
    return moment(date).startOf('day').toDate()
  }

  export const endOfDate = (date: Date, timezoneUTC?: boolean): Date => {
    return timezoneUTC
      ? moment(date)
          .endOf('day')
          .add(new Date().getTimezoneOffset() * -1, 'minutes')
          .toDate()
      : moment(date).endOf('day').toDate()
  }

  export const serviceInquiry_endOfDate = (date: Date, timezoneUTC?: boolean): Date => {
    return moment(date).endOf('day').toDate()
  }

  export const getYesterday = (date?: Date | null): Date => {
    return moment(date).subtract(1, 'days').toDate()
  }

  export const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  export const onlyNumber =
    (value: string | undefined | null) =>
    (defaultValue: string = '') => {
      let canNegative
      if (!!value) {
        canNegative = value[0] === '-'
      } else {
        canNegative = false
      }
      const _change = value ?? defaultValue
      const _numberReplace = _change.replace(/[^-0-9]/g, '')
      return canNegative ? `-${_numberReplace}` : _numberReplace
    }

  export const addThousandSeparator = (value: string) => {
    //const _change = onlyNumber(value)('')
    const _change = value.replace(/[^\d.-]/g, '')
    if (/^(-?\d+\.)?-?\d+$/.test(_change)) {
      return thousandSepartor(Number(_change))
    }
    // else if (_change === '-') {
    //   return '-'
    // } else {
    //   return
    // }
    else {
      return _change
    }
  }

  export const positiveNumber = (value: string) => {
    return value.replace(/[^0-9]/g, '')
  }

  export const generateUUID = () => {
    var uuidValue = '',
      k,
      randomValue
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0
  
      if (k === 8 || k === 12 || k === 16 || k === 20) {
        uuidValue += '-'
      }
      uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
    }
    return uuidValue
  }
}
