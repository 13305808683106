import { AppContext, Permission, TabView, TabViewInfo } from '@pulseops/common'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { OPLListDocument } from './OPLListDocument'
import { OPLCreatingDocument } from './OPLCreatingDocument'
import { OPLDocumentConst } from './OPLDocumentConst'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { OPLStackContext } from '../OPLStackContext'
export const OPLFormDocument = () => {
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState(0)
  const isFocused = useIsFocused()
  const { t } = useTranslation()
  const rootNavigation = useNavigation()
  const { userPermissions } = React.useContext(OPLStackContext)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const getTabList = () => {
    const tabList: TabViewInfo[] = []
    if (userPermissions.includes(Permission.ViewOPLSDDocumentList)) {
      tabList.push({
        tabName: t('OPL_CS:OPL:ListDocument'),
        tabKey: 0
      })
    }
    if (userPermissions.includes(Permission.ViewOPLSDUploadDocument)) {
      tabList.push({
        tabName: t('OPL_CS:OPL:CreateDocument'),
        tabKey: 1
      })
    }
    return tabList
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('OPL_CS:OPL:Document'),
          navigate: () => rootNavigation.navigate('OPLSubDocument')
        },
        {
          title: t('OPL_CS:OPL:AgentSupportOperations'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getComponentByTabIndex = () => {
    switch (isActiveTabIndex) {
      case 0:
        return userPermissions.includes(Permission.ViewOPLSDDocumentList) ? (
          <OPLListDocument documentType={OPLDocumentConst.OPLDocumentTypeConst.OPL_FORMS}></OPLListDocument>
        ) : (
          <></>
        )
      case 1:
        return userPermissions.includes(Permission.ViewOPLSDUploadDocument) ? (
          <OPLCreatingDocument
            documentType={OPLDocumentConst.OPLDocumentTypeConst.OPL_FORMS}
            isActiveTab={isActiveTabIndex === 1}
            setIsActiveTabIndex={setIsActiveTabIndex}
          ></OPLCreatingDocument>
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }
  return (
    <View style={OPLStyles.container}>
      <TabView
        isActiveTabIndex={isActiveTabIndex}
        setIsActiveTabIndex={setIsActiveTabIndex}
        data={getTabList()}
      ></TabView>
      {getComponentByTabIndex()}
    </View>
  )
}
const OPLStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 16
  }
})
