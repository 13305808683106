import React from 'react'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { Error, FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { AppContext, AuthService, DatePicker, ErrorHandling, Input, OPLService, sharedStyle } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
// import { OPLDocumentForm } from './OPLDocumentForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OPLSignatureForm } from './OPLSignatureForm'
import moment from 'moment'
import { StackScreenProps } from '@react-navigation/stack'
import { OPLStackParamList } from '../OPLStackParamList'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useLoading } from '@mxt/zio-react'

export const OPLEditingSignature = (props: StackScreenProps<OPLStackParamList, 'OPLEditingSignature'>) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const actionType = props.route.params.actionType
  const historyID = props.route.params.historyID
  const isOnlyView = actionType === 'view'
  const rootNavigation = useNavigation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [detailData, setDetailData] = React.useState<OPLService.OPLSignatureInfo>()
  const { setValue, control, trigger, getValues, reset } = useForm<OPLSignatureForm.OPLEditingSignatureFormData>({
    defaultValues: {
      fileLink: '',
      fileName: '',
      title: '',
      content: '',
      ADCode: '',
      GACode: '',
      expirationDate: null,
      createDate: null,
      createBy: '',
      modifiedDate: null,
      modifiedBy: '',
      signatureStatus: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('OPL_CS:OPL:Signature'),
          navigate: () => props.navigation.navigate('OPLSignature')
        },
        {
          title: t('OPL_CS:OPL:Edit'),
          navigate: null
        }
      ])
      getDetailDataByID()
    }
    return () => {
      reset({
        historyID: '',
        fileLink: '',
        fileName: '',
        title: '',
        content: '',
        ADCode: '',
        GACode: '',
        expirationDate: null,
        createDate: null,
        createBy: '',
        modifiedDate: null,
        modifiedBy: '',
        signatureStatus: ''
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getDetailDataByID = () => {
    pipe(
      ZIO.zipPar(OPLService.getOPLSignatureDetailByID(historyID), AuthService.userInfo),
      ZIO.map(([detailResponse, userInfo]) => {
        setValue('historyID', detailResponse.id)
        setValue('fileName', detailResponse.fileName)
        setValue('title', detailResponse.title)
        setValue('ADCode', detailResponse.adCode)
        setValue('GACode', detailResponse.gaCode || '')
        setValue('content', detailResponse.content)
        setValue('expirationDate', detailResponse.expirationDate ? new Date(detailResponse.expirationDate) : null)
        setValue('createDate', detailResponse.createdDate ? new Date(detailResponse.createdDate) : null)
        setValue('createBy', detailResponse.createdBy)
        setValue('modifiedDate', new Date())
        setValue(`modifiedBy`, userInfo.isGaLogin ? userInfo.name : userInfo.email)
        setValue('fileLink', detailResponse.urlFilenet || '')
        setValue('signatureStatus', detailResponse.signatureStatus)
        setDetailData(detailResponse)
        return detailResponse
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onClickFileNameEvent = (linkUrl: string) => {
    if (!!linkUrl) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(linkUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getUpdatedData = (formData: OPLSignatureForm.OPLEditingSignatureFormData) => {
    // const expirationDate = formData.expirationDate ? formData.expirationDate.toISOString() : ''
    const expirationDate = formData.expirationDate ? moment(formData.expirationDate).format("YYYY-MM-DD") : ''
    const createdDate = detailData?.createdDate || ''
    const modifiedDate = formData.modifiedDate ? formData.modifiedDate.toISOString() : ''
    const updatedData: OPLService.UpdateOPLSignatureRequest = {
      body: [
        {
          id: formData.historyID,
          fileName: formData.fileName,
          title: formData.title,
          content: formData.content,
          gaCode: formData.GACode,
          adCode: formData.ADCode,
          expirationDate: expirationDate,
          createdDate: createdDate,
          createdBy: formData.createBy,
          modifiedDate: modifiedDate,
          modifiedBy: formData.modifiedBy,
          signatureStatus: formData.signatureStatus
        }
      ]
    }
    return updatedData
  }

  const onSaveEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formData = getValues()
      const updatedData = getUpdatedData(formData)
      pipe(
        OPLService.updateOPLSignature(updatedData),
        ZIO.map((response) => {
          if (['MS020075'].includes(response.status.message)) {
            showToast(t('message:MS020075'), 'success')
            props.navigation.navigate('OPLSignature')
          } else {
            showToast(t('message:MS020071'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:MS020071'), 'error')
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onResetEvent = () => {
    if (detailData) {
      setValue('fileName', detailData.fileName)
      setValue('title', detailData.title)
      setValue('ADCode', detailData.adCode)
      setValue('GACode', detailData.gaCode || '')
      setValue('content', detailData.content)
      setValue('expirationDate', detailData.expirationDate ? new Date(detailData.expirationDate) : null)
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <Controller
          control={control}
          name={'fileLink'}
          render={({ field: { value, onChange, onBlur } }) => (
            <>
              <TouchableOpacity onPress={() => onClickFileNameEvent(value)}>
                <View>
                  <Text style={OPLSharedStyles.underlineText}>{detailData?.fileName}</Text>
                </View>
              </TouchableOpacity>
            </>
          )}
        />
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:FileName') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      disabled={isOnlyView}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:Title') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('CustomerEngagement:Content') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      numberOfLines={4}
                      maxLength={300}
                      disabled={isOnlyView}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="ADCode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ADCode')}
                      value={value}
                      maxLength={8}
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      alwayShowUnderline
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="GACode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:GACode')}
                      value={value}
                      maxLength={300}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  rules={{
                    validate: (val) => {
                      if (!!val && !moment(val).isValid()) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      disabled={isOnlyView}
                      errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifiedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
      {!isOnlyView && (
        <View style={[OPLSharedStyles.buttonContainer]}>
          <TouchableOpacity style={[sharedStyle.button]} onPress={() => onResetEvent()}>
            <Text style={sharedStyle.textButton}>{t('Cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
            <Text style={sharedStyle.textButtonRed}>{t('Save')}</Text>
          </TouchableOpacity>
        </View>
      )}
    </ScrollView>
  )
}
const createDocStyles = StyleSheet.create({
  datePickerText: {
    marginBottom: 2
  }
})
