import { AppContext, Permission, sharedStyle } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { OPLStackParamList } from './OPLStackParamList'
import { OPLStackContext } from './OPLStackContext'
import { useIsFocused, useNavigation } from '@react-navigation/native'
export const OPLScreen = (props: StackScreenProps<OPLStackParamList>) => {
  const { t } = useTranslation()
  const { userPermissions } = React.useContext(OPLStackContext)
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:OPL'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const onHandleNavigate = (type: string) => {
    switch (type) {
      case '1': {
        props.navigation.navigate('OPLSubDocument')
        break
      }
      case '2': {
        props.navigation.navigate('OPLSignature')
        break
      }
      default:
        break
    }
  }
  return (
    <View style={OPLStyle.container}>
      {userPermissions.includes(Permission.ViewDocumentOPLSD) && (
        <TouchableOpacity style={[sharedStyle.button]} onPress={() => onHandleNavigate('1')}>
          <Text style={sharedStyle.textButton}>{t('OPL_CS:OPL:Document')}</Text>
        </TouchableOpacity>
      )}
      {userPermissions.includes(Permission.ViewSignatureOPLSD) && (
        <TouchableOpacity style={[sharedStyle.button]} onPress={() => onHandleNavigate('2')}>
          <Text style={sharedStyle.textButton}>{t('OPL_CS:OPL:Signature')}</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
const OPLStyle = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 100
  }
})
