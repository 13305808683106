import { FileUploadData } from "@pulseops/common"

export namespace OPLDocumentForm {
  export type OLPDocumentInfo= {
    historyID: string
    isSelectedItem: boolean
  }
  export type OPLListDocumentFormData = {
    from: Date | null
    to: Date | null
    fileName: string
    title: string
    expirationDate: Date | null
    isSelectedAll: boolean
    documentList: Array<OLPDocumentInfo>
  }

  export type OPLCreatingDocumentFormData = {
    uploadFiles: FileUploadData []
    fileName: string
    title: string
    content: string
    code: string
    expirationDate: Date | null
    createDate: Date | null
    createBy: string
    modifiedDate: Date | null
    modifedBy: string
  }

  export type OPLEditingDocumentFormData = {
    historyID: string
    fileLink: string
    fileName: string
    title: string
    content: string
    documentType: string
    code: string
    expirationDate: Date | null
    createDate: Date | null
    createBy: string
    modifiedDate: Date | null
    modifiedBy: string
    status: string
  }
}