import { CashOut } from './CashOutOption'

export namespace CancelFromInception {
  export interface ClientPolicy {
    policyNum: string
    address: string
  }
  export interface Detail {
    installmentPremium: number
    advancePremium: number
    suspenseAmount: number
    medicalFee: number
    pwAmount: number
    topUpAmount: number
    netAmountAvailable?: number
    hasFileNet: boolean
  }

  export interface AttributesExt {
    // reason: string
    // installmentPremium: number
    // advancePremium: number
    // suspenseAmount: number
    // medicalFee: number
    // pwAmount: number
    // currency: string
    // netAmountAvailable: number
    // totalPayoutAmount: number
    REASON: string
    INSTALLMENT_PREMIUM: number
    ADVANCE_PREMIUM: number
    SUSPENSE_AMOUNT: number
    MEDICAL_FEE: number
    PWAMOUNT: number
    CURRENCY: string
    NET_AMOUNT_AVAILABLE: number
    TOTAL_PAYOUT_AMOUNT: number
  }

  export interface DataSubmit {
    // reason: string
    // installmentPremium: number
    // advancePremium: number
    // suspenseAmount: number
    // medicalFee: number
    // pwAmount: number
    // currency: string
    // netAmountAvailable: number
    // totalPayoutAmount: number
    // policiesReceived: Array<{
    //   payoutOptionType: string
    //   amount: number
    //   bankAccountNumber?: string
    //   bankAccountName?: string
    //   bankName?: string
    //   bankBranch?: string
    //   policyReceivedNum?: string
    //   bankKey?: string
    // }>
    policy: {
      policyNo: string
    }
    attributesExt: AttributesExt
    refundCardFlag?: string
    cashOutOptions: Array<CashOut.Option>
  }

  export interface AccessDataCheck {
    contractStatus: string
    proposalReceivedDate: string
    coverageCode: string
    riskCommenceDate: string
  }
}
