/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { BusinessStack } from '@pulseops/business'
import { DIStack } from '@pulseops/digital-inquiry'
import {
  TaskDashboardScreen,
  TaskDetailStack,
  ServiceInquiryScreen,
  ErrorHandlerReportScreen,
  NotificationScreen,
  IDCDetailStack
  // DADetailStackScreen
} from '@pulseops/task'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { StaffSubmissionStack } from '../../../../submission/src/StaffSubmissionStack'
import { UserStackParamList } from '../UserStackParamList'
import { DrawerContent } from './DrawerContent'
import { Header } from './Header'
import { UserDrawerParamList } from './UserDrawerParamList'
import { UtilitiesStack } from '../../../../utilites/src/utilities'
import { ReportsStack } from '../../../../reports/src/lib/ReportsStack'
import { CashoutStack } from '../../../../submission/src/cash-out-request/cashout-stack'
import { AccountingStack } from '../../../../submission/src/accounting/AccountingStack'
import { InquiryStack } from '../../../../inquiry/src/lib/InquiryStack'
import { PCDataImportScreen } from '../../../../submission/src/pc-data-import'
import { PCRequestStack } from '../../../../submission/src/pc-request/PCRequestStack'
import { PCRequestListStack } from '../../../../submission/src/pc-request-list'
import { OBServiceInquiryScreen, OBTaskManagementScreen, OutboundStack, OBImportFileScreen } from '@pulseops/outbound'
import { PolicyGroupListStack } from '../../../../submission/src/sea-billing-chance/SEAPolicyGroupListStack'
import { InboundStack } from '@pulseops/inbound'
import { ReleasePolicyScreen } from '../../../../submission/src/release-policy'
import { SettingStack } from '../setting/SettingStack'
import { CSStack, OPLStack } from '@pulseops/submission'

const Drawer = createDrawerNavigator<UserDrawerParamList>()

export const UserDrawer = ({ navigation }: { navigation: StackScreenProps<UserStackParamList, 'UserDrawer'> }) => {
  return (
    <Drawer.Navigator
      initialRouteName="TaskDashboardScreen"
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} navigation={navigation} />
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen name="BusinessStack" component={BusinessStack} />
      <Drawer.Screen name="DIStack" component={DIStack} />
      <Drawer.Screen name="TaskDashboardScreen" component={TaskDashboardScreen} />
      <Drawer.Screen name="TaskDetailStack" component={TaskDetailStack} />
      <Drawer.Screen name="ServiceInquiryScreen" component={ServiceInquiryScreen} />
      <Drawer.Screen name="ErrorHandlerReportScreen" component={ErrorHandlerReportScreen} />
      <Drawer.Screen name="StaffSubmissionStack" component={StaffSubmissionStack} />
      <Drawer.Screen name="ReportsStack" component={ReportsStack} />
      <Drawer.Screen name="SettingStack" component={SettingStack} />
      <Drawer.Screen name="UtilitiesStack" component={UtilitiesStack} />
      <Drawer.Screen name="CashoutStack" component={CashoutStack} />
      <Drawer.Screen name="ReleasePolicyScreen" component={ReleasePolicyScreen} />
      <Drawer.Screen name="InquiryStack" component={InquiryStack} />
      <Drawer.Screen name="NotificationScreen" component={NotificationScreen} />
      <Drawer.Screen name="PCDataImportScreen" component={PCDataImportScreen} />
      <Drawer.Screen name="AccountingStack" component={AccountingStack} />
      <Drawer.Screen name="PCRequestStack" component={PCRequestStack} />
      <Drawer.Screen name="PCRequestListStackScreen" component={PCRequestListStack} />
      <Drawer.Screen name="OutboundStack" component={OutboundStack} />
      <Drawer.Screen name="InboundStack" component={InboundStack} />
      <Drawer.Screen name="PolicyGroupListStack" component={PolicyGroupListStack} />
      <Drawer.Screen name="OBTaskManagementScreen" component={OBTaskManagementScreen}/>
      <Drawer.Screen name="OBServiceInquiryScreen" component={OBServiceInquiryScreen}></Drawer.Screen>
      <Drawer.Screen name="OBImportFileScreen" component={OBImportFileScreen}></Drawer.Screen>
      <Drawer.Screen name="IDCDetailStack" component={IDCDetailStack}/>
      <Drawer.Screen name="OPLStack" component={OPLStack}/>
      <Drawer.Screen name="CSStack" component={CSStack}/>
    </Drawer.Navigator>
  )
}
