import {
  AppContext,
  assets,
  CancelFromInception,
  CancelFromInceptionConst,
  CancelFromInceptionService,
  ErrorHandling,
  formatNumberWithComma,
  GeneralService,
  ImgUploadMutiple,
  PayoutPopup,
  Select,
  // StorageBlob,
  TaskType,
  Title,
  TransactionType
} from '@pulseops/common'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SafeAreaView, Text, StyleSheet, View } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { Controller, useForm } from 'react-hook-form'
import { CancelFromInceptionFormData } from './cancel-from-inception-form'
import { RequestAuthenticateData } from '../../request-authen'
import { Column, Error } from '@pulseops/submission/common'
import { Link } from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'

export const CancelFromInceptionScreen: React.FC<PolicyServiceProps<CancelFromInception.DataSubmit>> = ({
  isConfirmed,
  initSubmission,
  policyNumber,
  officeCode
}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const cancelFromInceptionForm = useForm<CancelFromInceptionFormData.CancelFromInception>({
    defaultValues: {
      installmentPremium: '0',
      advancePremium: '0',
      suspenseAmount: '0',
      medicalFee: '0',
      pwAmount: '0',
      netAmountAvailable: '0',
      topUpAmount: '0'
    }
  })
  const { control, watch, setValue } = cancelFromInceptionForm
  const reasons = CancelFromInceptionConst.reasons
  const [detailData, setDetailData] = React.useState<CancelFromInception.Detail>({
    installmentPremium: 0,
    advancePremium: 0,
    suspenseAmount: 0,
    medicalFee: 0,
    pwAmount: 0,
    netAmountAvailable: 0,
    topUpAmount: 0,
    hasFileNet: false
  })
  const [errorMsg, setErrorMsg] = React.useState<string>('')
  const [payoutData, setPayoutData] = React.useState<PayoutPopup.Summary[]>([])
  const [currentPayoutData, setCurrentPayoutDataPayoutData] = React.useState<PayoutPopup.Summary[]>([])
  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const [allowedPayoutMethods, setAllowedPayoutMethods] = React.useState<PayoutPopup.PayoutMethods[]>([
    PayoutPopup.PayoutMethods.PAYPREMIUM,
    PayoutPopup.PayoutMethods.OTHER,
    PayoutPopup.PayoutMethods.PAYLOAN
  ])
  const [isRefundCardFlag, setIsRefundCardFlag] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (policyNumber) {
      // showGlobalLoading(true)
      changePayoutData([])
      pipe(
        CancelFromInceptionService.getDetail(policyNumber ?? ''),
        ZIO.map((responseData) => {
          if (!!responseData) {
            !responseData.hasFileNet &&
              setAllowedPayoutMethods([
                ...allowedPayoutMethods,
                PayoutPopup.PayoutMethods.CASHLESS,
                PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
                PayoutPopup.PayoutMethods.PAID_AT_BANK,
                PayoutPopup.PayoutMethods.BANKTRANSFER,
                // PayoutPopup.PayoutMethods.MOMO
              ])
              if(responseData.hasFileNet){
                setIsRefundCardFlag(true)
              }
            const netAmountAvailableVal =
              responseData.installmentPremium +
              responseData.suspenseAmount +
              responseData.advancePremium +
              responseData.topUpAmount -
              responseData.medicalFee -
              responseData.pwAmount
            const caculatedData = {
              ...responseData,
              netAmountAvailable: netAmountAvailableVal
            }
            setValue('installmentPremium', formatCurrency(responseData.installmentPremium))
            setValue('advancePremium', formatCurrency(responseData.advancePremium))
            setValue('suspenseAmount', formatCurrency(responseData.suspenseAmount))
            setValue('medicalFee', formatCurrency(responseData.medicalFee))
            setValue('pwAmount', formatCurrency(responseData.pwAmount))
            setValue('netAmountAvailable', formatCurrency(netAmountAvailableVal))
            setValue('topUpAmount', formatCurrency(responseData.topUpAmount))
            setDetailData(caculatedData)
          }
          return responseData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [policyNumber])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    changePayoutData(currentPayoutData)
    console.log('language : ' + i18n.language)
  }, [i18n.language])

  const totalAmount = React.useMemo(() => payoutData.reduce((sum, item) => sum + item.amount, 0), [payoutData])

  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }

  const isInvalidAttachmentFiles = () => {
    return detailData.hasFileNet && watch('attachmentFiles') && watch('attachmentFiles').length <= 0
  }

  const checkTotalPayout = (summaryData: PayoutPopup.Summary[]) => {
    const totalPayout = summaryData.reduce((total, item) => total + item.amount, 0)
    return totalPayout !== detailData?.netAmountAvailable ? true : false
  }

  const changePayoutData = (data: PayoutPopup.Summary[]) => {
    let errorMessage = data.length === 0 ? t('message:MS050241') : ''
    // console.log(`payoutData: ${JSON.stringify(data)}`)
    setCurrentPayoutDataPayoutData(data)
    if (!errorMessage) {
      if (!isConfirmed && checkTotalPayout(data)) {
        errorMessage = t('message:MS050170')
      }
    }
    setPayoutData(data)
    setErrorMsg(errorMessage)
  }

  const mapDataSubmit = (): CancelFromInception.DataSubmit => {
    // reason: watch('reason').value,
    // installmentPremium: detailData.installmentPremium,
    // advancePremium: detailData.advancePremium,
    // suspenseAmount: detailData.suspenseAmount,
    // medicalFee: detailData.medicalFee,
    // pwAmount: detailData.pwAmount,
    // currency: watch('currency'),
    // netAmountAvailable: detailData.netAmountAvailable ?? 0,
    // totalPayoutAmount: watch('totalPayoutAmount'),
    // policiesReceived: payoutData.map((x) => {
    //   const policyReceivedNum = x.policyNum ?? ''
    //   const bankAccountNumber = x.bankAccountNum ?? ''
    //   const bankAccountName = x.bankAccountName ?? ''
    //   const bankName = x.bankName ?? ''
    //   const payoutItem = {
    //     payoutOptionType: x.method,
    //     amount: x.amount,
    //     bankName,
    //     bankAccountName,
    //     bankAccountNumber,
    //     bankKey: x.bankBranchCode ?? '',
    //     policyReceivedNum: policyReceivedNum
    //   }
    //   return payoutItem
    // })
    const CFISubmitedData: CancelFromInception.DataSubmit = {
      policy: {
        policyNo: policyNumber ?? ''
      },
      attributesExt: {
        REASON: watch('reason').value,
        INSTALLMENT_PREMIUM: detailData.installmentPremium,
        ADVANCE_PREMIUM: detailData.advancePremium,
        SUSPENSE_AMOUNT: detailData.suspenseAmount,
        MEDICAL_FEE: detailData.medicalFee,
        PWAMOUNT: detailData.pwAmount,
        CURRENCY: watch('currency'),
        NET_AMOUNT_AVAILABLE: detailData.netAmountAvailable ?? 0,
        TOTAL_PAYOUT_AMOUNT: totalAmount
      },
      refundCardFlag: isRefundCardFlag ? '1' : '0',
      cashOutOptions: mapCashOutOption(payoutData)
    }
    return CFISubmitedData
  }

  // const getAttachmentFiles = (isContinue: boolean) => {
  //   let uploadedFiles: StorageBlob.FileContentSubmit[] = []
  //   if (!isContinue && detailData.hasFileNet && watch('attachmentFiles').length >= 0) {
  //     return pipe(
  //       GeneralService.getMetaData(TransactionType.CANCEL_FROM_INCEPTION || '', 'DPS18', officeCode),
  //       ZIO.flatMap((metaDataRes) => {
  //         const metaDataRaw: StorageBlob.MetaDataUpload = {
  //           type: metaDataRes.data.transactionType,
  //           doctype: metaDataRes.data.doctypeEn,
  //           class: metaDataRes.data.classFilenet,
  //           docid: metaDataRes.data.docID,
  //           maindoc: metaDataRes.data.mainDoc,
  //           subdoc: metaDataRes.data.subDoc,
  //           polnum: policyNum,
  //           batchno: metaDataRes.data.batchNo
  //         }
  //         const attachFiles = watch('attachmentFiles').map((x) => {
  //           return {
  //             file: x.file,
  //             metaData: metaDataRaw
  //           }
  //         })
  //         return pipe(StorageBlob.uploadToSubmit('PS', policyNum)(attachFiles))
  //       })
  //     )
  //   } else {
  //     return pipe(uploadedFiles, ZIO.succeed)
  //   }
  // }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(payoutData),
      transactionType: TransactionType.CANCEL_FROM_INCEPTION,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    if (detailData.hasFileNet) {
      uploadedFileList.push({
        uploadFiles: watch('attachmentFiles'),
        transactionType: TransactionType.CANCEL_FROM_INCEPTION,
        docTypeCode: 'DPS18',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }

    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValid = await cancelFromInceptionForm.trigger()
      if (isValid && !errorMsg && !isInvalidAttachmentFiles()) {
        // return await pipe(
        //   getAttachmentFiles(!!isContinue),
        //   ZIO.map((azuredFiles) => {
        //     const submitedData = mapDataSubmit()
        //     return {
        //       url: (policyNum: string) => `wf-api/policy/${policyNum}/cfi`,
        //       body: submitedData,
        //       transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CANCEL_FROM_INCEPTION),
        //       collerationId: policyNumber ?? '',
        //       transaction: TransactionType.CANCEL_FROM_INCEPTION,
        //       // documents: azuredFiles,
        //       uploadedFilesInfo: getUploadedFilesInfo()
        //     }
        //   }),
        //   ZIO.tapError((_) => {
        //     showToast('message:MS050001', 'error')
        //     return ZIO.unit
        //   }),
        //   bindLoader,
        //   ZIO.unsafeRun({})
        // )
        const submitedData = mapDataSubmit()
        return {
          url: (policyNum: string) => `wf-api/policy/${policyNum}/cfi`,
          body: submitedData,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CANCEL_FROM_INCEPTION),
          collerationId: policyNumber ?? '',
          transaction: TransactionType.CANCEL_FROM_INCEPTION,
          // documents: azuredFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else {
        return false
      }
    },
    clear: () => {
      cancelFromInceptionForm.reset({
        installmentPremium: formatCurrency(detailData.installmentPremium),
        advancePremium: formatCurrency(detailData.advancePremium),
        suspenseAmount: formatCurrency(detailData.suspenseAmount),
        medicalFee: formatCurrency(detailData.medicalFee),
        pwAmount: formatCurrency(detailData.pwAmount),
        netAmountAvailable: formatCurrency(detailData.netAmountAvailable),
        topUpAmount: formatCurrency(detailData.topUpAmount)
      })
      payoutRef.current?.clearData()
    }
  })

  const fileDownload = isRefundCardFlag ?  assets.CFIIsRefundCarAttachmentTemplate :  assets.CFIAttachmentTemplate

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Title title={t('CFI:Info')}></Title>
      <View style={CFIStyles.sectionContent}>
        <View style={CFIStyles.sectionRow}>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('CFI:InstallmentPremium')}</Text>
            <Text style={CFIStyles.field_description}>{watch('installmentPremium')} VND</Text>
          </View>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('Payout:AdvancePremium')}</Text>
            <Text style={CFIStyles.field_description}>{watch('advancePremium')} VND</Text>
          </View>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('Payout:SuspenseAmount')}</Text>
            <Text style={CFIStyles.field_description}>{watch('suspenseAmount')} VND</Text>
          </View>
        </View>
        <View style={[CFIStyles.sectionRow, CFIStyles.secondLine]}>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('Payout:MedicalFee')}</Text>
            <Text style={CFIStyles.field_description}>{watch('medicalFee')} VND</Text>
          </View>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('CFI:PartialWithdrawAmount')}</Text>
            <Text style={CFIStyles.field_description}>{watch('pwAmount')} VND</Text>
          </View>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('requestInfo:TopUpAmount')}</Text>
            <Text style={[CFIStyles.field_description]}>{watch('topUpAmount')} VND</Text>
          </View>
        </View>
        <View style={[CFIStyles.sectionRow, CFIStyles.secondLine]}>
          <View style={CFIStyles.col_4}>
            <Text style={CFIStyles.field_title}>{t('Payout:TempRefundFee')}</Text>
            <Text style={[CFIStyles.field_description, CFIStyles.highLightText]}>
              {/* {formatCurrency(detailData.netAmountAvailable)} VND */}
              {watch('netAmountAvailable')} VND
            </Text>
          </View>
          <View style={CFIStyles.col_4}>
            <Controller
              control={control}
              name={'reason'}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('CFI:Reason') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('CFI:Reason')}
                  required={true}
                  disabled={isConfirmed}
                  errorMessage={value ? '' : error?.message}
                  options={reasons}
                ></Select>
              )}
            />
          </View>
        </View>
        {detailData?.hasFileNet && (
          <View style={CFIStyles.secondLine}>
            <Text style={[CFIStyles.field_title, CFIStyles.attachmentHeaderText]}>{t('CFI:CFIFileAttachment')}</Text>
            <Trans i18nKey={'FileAttachDescription'}>
              <Text style={[CFIStyles.field_description]}>
                {t('CFI:FileDescriptionPrefix')}(
                <Link href={`${fileDownload}`} target="_parent" download>
                  {t('CFI:FileDescriptionPostfix')}
                </Link>
                ){t('CFI:FileDescriptionSuffix')}
              </Text>
            </Trans>
            <Controller
              name="attachmentFiles"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS150004')}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Column>
                  <ImgUploadMutiple
                    value={value}
                    onChange={onChange}
                    timeFormat={'DD/MM/YYYY HH:mm'}
                    disabled={isConfirmed}
                  />
                  {error?.message && <Error message={value ? '' : error.message} />}
                </Column>
              )}
            />
          </View>
         )}
      </View>

      <PayoutMethod
        transactionType={TransactionType.CANCEL_FROM_INCEPTION}
        value={payoutData}
        ref={payoutRef}
        policyNum={policyNumber ?? ''}
        methods={allowedPayoutMethods}
        maxAmount={detailData.netAmountAvailable}
        onChange={changePayoutData}
        editable={!isConfirmed}
        officeCode={officeCode}
        isRefundCardFlag={isRefundCardFlag}
      />
      {!!errorMsg && <Text style={CFIStyles.error_message}>{errorMsg}</Text>}
    </SafeAreaView>
  )
}
const CFIStyles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  error_message: {
    color: '#ed1c2e'
  },
  attachmentHeaderText: {
    marginBottom: 10
  },
  highLightText: {
    color: '#ed1b2e',
    fontWeight: 'bold'
  }
})
