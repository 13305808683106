import {
  AppContext,
  AuthService,
  Checkbox,
  DatePicker,
  ErrorHandling,
  Input,
  OPLService,
  Permission,
  PulseOpsFormat,
  sharedStyle
} from '@pulseops/common'
import { SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CheckboxTable, CheckboxTableColum, OPLCommon, OPLSharedStyles } from '../opl-common'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native'
import OPLDeleteModal from '../opl-common/OPLDeleteModal'
import { OPLSignatureForm } from './OPLSignatureForm'
import { OPLStackParamList } from '../OPLStackParamList'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { OPLDocumentConst } from '../opl-document'
import * as A from 'fp-ts/lib/Array'
import { OPLStackContext } from '../OPLStackContext'
import moment from 'moment'

export const OPLListSignature = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const navigationItem = useNavigation<NavigationProp<OPLStackParamList>>()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const [hasEnableEdit, setHasEnableEdit] = React.useState(false)
  const [hasEnableViewDetail, setHasEnableViewDetail] = React.useState(false)
  const [hasEnableDelete, setHasEnableDelete] = React.useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false)
  const [hasEnableExtract, setHasEnableExtract] = React.useState(false)
  const [OPLSignatureList, setOPLSignatureList] = React.useState<OPLService.OPLSignatureInfo[]>([])
  const [userEmail, setUserEmail] = React.useState<string>('')
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [total, setTotal] = React.useState<number>(0)
  const { userPermissions } = React.useContext(OPLStackContext)
  const { setValue, getValues, watch, control, trigger } = useForm<OPLSignatureForm.OPLListSignatureFormData>({
    defaultValues: {
      from: null,
      to: null,
      fileName: '',
      title: '',
      ADCode: '',
      expirationDate: null,
      isSelectedAll: false,
      signatureList: []
    },
    mode: 'onChange'
  })

  const signatureFieldList = useFieldArray<OPLSignatureForm.OPLListSignatureFormData>({
    control: control,
    name: 'signatureList'
  })

  const displayedColumns: CheckboxTableColum[] = [
    {
      title: '',
      fieldName: '',
      disabled: true,
      headerColumnRender: (list: any) => {
        return (
          <Controller
            control={control}
            name="isSelectedAll"
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <Checkbox
                  onChange={(val) => {
                    onChange(val)
                    onSelectedAllEvent(val, list.length)
                  }}
                  onBlur={onBlur}
                  value={value}
                  // disabled={props.isConfirmed}
                  checkBoxStyle={listStyles.checkboxContainer}
                />
              )
            }}
          />
        )
      },
      rowRender: (renderProps) => {
        return (
          <Controller
            control={control}
            name={`signatureList.${renderProps.index}.isSelectedItem`}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <Checkbox
                  key={`SignatureList_isSelectedItem_${renderProps.index}`}
                  onChange={(val) => {
                    onChange(val)
                    setValue(`signatureList.${renderProps.index}.historyID`, OPLSignatureList[renderProps.index].id)
                    onSelectedItemEvent()
                  }}
                  onBlur={onBlur}
                  value={value}
                  // disabled={props.isConfirmed}
                  checkBoxStyle={listStyles.checkboxContainer}
                />
              )
            }}
          />
        )
      }
    },
    {
      title: t('OPL_CS:OPL:FileName'),
      fieldName: 'fileName',
      rowRender: (rowProps) => {
        const fileName = rowProps.selectItem['fileName']
        return (
          <TouchableOpacity onPress={() => onClickFileNameEvent(rowProps.index)}>
            <View>
              <Text style={OPLSharedStyles.underlineText}>{fileName}</Text>
            </View>
          </TouchableOpacity>
        )
      }
    },
    {
      title: t('OPL_CS:OPL:Title'),
      fieldName: 'title'
    },
    {
      title: t('OPL_CS:OPL:ADCode'),
      fieldName: 'adCode'
    },
    {
      title: t('OPL_CS:OPL:ModifiedDate'),
      fieldName: 'modifiedDate'
    },
    {
      title: t('OPL_CS:OPL:ModifiedBy'),
      fieldName: 'modifiedBy'
    },
    {
      title: t('CustomerEngagement:ExpirationDate'),
      fieldName: 'expirationDate'
    },
    {
      title: t('Reports:Status'),
      fieldName: 'signatureStatus'
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      getOPLSignatureList(pageNum, pageSize)
      pipe(
        AuthService.userInfo,
        ZIO.map((userInfo) => {
          setUserEmail(userInfo.isGaLogin ? userInfo.name : userInfo.email)
          return userInfo
        }),
        ErrorHandling.run()
      )
    }

    return () => {
      console.log('component will unmount')
      signatureFieldList.remove()
      setValue(`signatureList`, [])
      // setOPLSignatureList([])
      // setHasEnableEdit(false)
      // setHasEnableDelete(false)
    }
  }, [isFocused])

  const getOPLSignatureList = (pageNum: number, pageS: number) => {
    const searchFormData = getValues()
    const expirationDate = searchFormData.expirationDate
      ? PulseOpsFormat.serviceInquiry_endOfDate(searchFormData.expirationDate, true).toISOString()
      : ''
    const fromDate = searchFormData.from
      ? PulseOpsFormat.serviceInquiry_StartOfDate(searchFormData.from, true).toISOString()
      : ''
    const toDate = searchFormData.to
      ? PulseOpsFormat.serviceInquiry_endOfDate(searchFormData.to, true).toISOString()
      : ''
    const queryData: OPLService.OPLSignatureListRequest = {
      fileName: searchFormData.fileName,
      title: searchFormData.title,
      content: '',
      adCode: searchFormData.ADCode,
      gaCode: '',
      expirationDate: expirationDate,
      createdDate: '',
      createdBy: '',
      modifiedDate: '',
      modifiedBy: '',
      signatureStatus: '',
      transactionType: '',
      start: pageNum,
      size: pageS,
      fromDate: fromDate,
      toDate: toDate
    }
    pipe(
      OPLService.getOPLSignatureList(queryData),
      ZIO.map((response) => {
        if (response && response.body.length > 0) {
          const customedData = response.body.map((item) => ({
            ...item,
            expirationDate: item.expirationDate
              ? PulseOpsFormat.dateStringtoFormat(item.expirationDate, 'DD/MM/YYYY')
              : '',
            modifiedDate: item.modifiedDate
              ? PulseOpsFormat.dateStringtoFormat(item.modifiedDate, 'DD/MM/YYYY - HH:mm:ss')
              : ''
          }))
          setOPLSignatureList(customedData)
          initSignatureListForm(customedData)
          setTotal(response.total)
        } else {
          setOPLSignatureList([])
          setTotal(0)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const initSignatureListForm = (signatureArr: OPLService.OPLSignatureInfo[]) => {
    let i: number = 0
    signatureFieldList.remove()
    setValue(`signatureList`, [])
    setHasEnableEdit(false)
    setHasEnableViewDetail(false)
    setHasEnableDelete(false)
    setHasEnableExtract(false)
    setValue('isSelectedAll', false)
    while (i < signatureArr.length) {
      signatureFieldList.append({
        historyID: signatureArr[i].id,
        isSelectedItem: false
      })
      setValue(`signatureList.${i}.historyID`, signatureArr[i].id)
      setValue(`signatureList.${i}.isSelectedItem`, false)
      i++
    }
  }

  const onSelectedAllEvent = (selectedAllValue: boolean, arrlength: number) => {
    let i: number = 0
    while (i < arrlength) {
      if (watch(`signatureList.${i}.isSelectedItem`) !== selectedAllValue) {
        // console.log('`SignatureList_isSelectedItem1')
        setValue(`signatureList.${i}.isSelectedItem`, selectedAllValue)
      }
      setValue(`signatureList.${i}.historyID`, OPLSignatureList[i].id)
      i++
    }
    if (arrlength <= 10 && selectedAllValue) {
      setHasEnableExtract(true)
    } else {
      setHasEnableExtract(false)
    }
    selectedAllValue ? setHasEnableDelete(true) : setHasEnableDelete(false)
    setHasEnableViewDetail(false)
    setHasEnableEdit(false)
  }

  const onSelectedItemEvent = () => {
    const signatureListListArr = watch(`signatureList`)
    if (signatureListListArr && signatureListListArr.length > 0) {
      const hasUnSelectedAll = signatureListListArr.some((p) => !p.isSelectedItem)
      const hasSelectecItem = signatureListListArr.some((p) => p.isSelectedItem)
      const selectedListLength = signatureListListArr.filter((p) => p.isSelectedItem).length
      const formSignatureItem = signatureListListArr.find((p) => p.isSelectedItem)
      const signatureItem = OPLSignatureList.find((x) => x.id === formSignatureItem?.historyID)
      if (watch(`isSelectedAll`) !== !hasUnSelectedAll) {
        // console.log('hasSelectedAll:' + !hasUnSelectedAll)
        setValue('isSelectedAll', !hasUnSelectedAll)
      }
      hasSelectecItem ? setHasEnableDelete(true) : setHasEnableDelete(false)
      selectedListLength === 1 ? setHasEnableViewDetail(true) : setHasEnableViewDetail(false)
      selectedListLength <= 10 && selectedListLength >= 1 ? setHasEnableExtract(true) : setHasEnableExtract(false)
      if (
        selectedListLength === 1 &&
        signatureItem?.signatureStatus === OPLDocumentConst.OPLDocumentStatusConst.Active
      ) {
        setHasEnableEdit(true)
      } else {
        setHasEnableEdit(false)
      }
    }
  }

  const onSearchEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      setPageNum(0)
      setPageSize(10)
      getOPLSignatureList(0, 10)
    }
  }

  const onClickFileNameEvent = (index: number) => {
    const urlFilenet = !!OPLSignatureList && OPLSignatureList.length > 0 ? OPLSignatureList[index].urlFilenet : ''
    if (!!urlFilenet) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(urlFilenet, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          const type = res.headers.get('content-type') || ''
          console.log('type:' + type)
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onPageChange = (pageN: number, pageSi: number) => {
    setPageNum(pageN)
    getOPLSignatureList(pageN, pageSi)
  }

  const onRowsPerPageChange = (pageN: number, pageSi: number) => {
    setPageSize(pageSi)
    getOPLSignatureList(pageN, pageSi)
  }

  const onClickBtnDeleteEvent = () => {
    setIsOpenDeleteModal(true)
  }

  const getUpdatedData = () => {
    const signatureListArr = watch(`signatureList`)
    const updateItems = signatureListArr
      .filter(
        (x) =>
          x.isSelectedItem &&
          OPLSignatureList.some(
            (p) => p.id === x.historyID && p.signatureStatus !== OPLDocumentConst.OPLDocumentStatusConst.Inactive
          )
      )
      .map((item) => {
        return {
          id: item.historyID,
          modifiedDate: new Date().toISOString(),
          modifiedBy: userEmail,
          signatureStatus: OPLDocumentConst.OPLDocumentStatusConst.Inactive
        }
      })
    const updatedData: OPLService.DeleteOPLSignatureRequest = {
      body: updateItems
    }
    return updatedData
  }

  const onConfirmDeleteEvent = () => {
    const deletedData = getUpdatedData()
    pipe(
      deletedData.body && deletedData.body.length <= 0
        ? ZIO.succeed({
            status: {
              message: 'MS020075'
            }
          })
        : OPLService.deleteOPLSignature(deletedData),
      ZIO.map((response) => {
        if (['MS020075'].includes(response.status.message)) {
          showToast(t('message:MS020091'), 'success')
          getOPLSignatureList(pageNum, pageSize)
        } else {
          showToast(t('message:MS020071'), 'error')
        }
        setIsOpenDeleteModal(false)
      }),
      ZIO.mapError((error) => {
        showToast(t('message:MS020071'), 'error')
        setIsOpenDeleteModal(false)
        return error
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onEditEvent = () => {
    const signatureListArr = watch(`signatureList`)
    const selectedItems = signatureListArr.filter((x) => x.isSelectedItem)
    const historyID = selectedItems && selectedItems.length > 0 ? selectedItems[0].historyID : ''
    navigationItem.navigate('OPLEditingSignature', {
      actionType: 'update',
      historyID
    })
  }

  const onViewDetailEvent = () => {
    const signatureListArr = watch(`signatureList`)
    const selectedItems = signatureListArr.filter((x) => x.isSelectedItem)
    const historyID = selectedItems && selectedItems.length > 0 ? selectedItems[0].historyID : ''
    navigationItem.navigate('OPLViewDetailSignature', {
      actionType: 'view',
      historyID
    })
  }

  const onExtractFileListEvent = () => {
    const signatureArr = watch(`signatureList`)
    const extractList = OPLSignatureList.filter((p) =>
      signatureArr.some((x) => x.historyID === p.id && x.isSelectedItem)
    )
    pipe(
      extractList,
      A.map((item) => {
        const fileUrl = item.urlFilenet
        return pipe(
          ZIO.zipPar(AuthService.token, AuthService.getLoginType),
          ZIO.flatMap(([token, loginType]) => {
            return ZIO.fromPromise(() =>
              fetch(fileUrl, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([res, blob]) => {
            // console.log('type:' + type)
            const type = res.headers.get('content-type') || ''
            OPLCommon.downloadFileByURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      })
    )
  }

  return (
    <ScrollView style={listStyles.container} horizontal={false}>
      <SectionContent>
        <SectionRow>
          <SectionCol>
            <Controller
              control={control}
              name="from"
              rules={{
                validate: (val) => {
                  if (!val && !watch(`to`)) {
                    return true
                  } else if (!!val && !moment(val).isValid()) {
                    return `${t('message:MS020081')}`
                  } else if (!!val && moment(val).valueOf() > moment(new Date()).valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (!val && !!watch(`to`)) {
                    return `${t('message:MS020009', { field: t('OPL_CS:OPL:From') })}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <DatePicker
                    alwaysShow
                    label={t('OPL_CS:OPL:From')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    minDate={undefined}
                    minDateMessage={''}
                    errorMessage={error?.message}
                    labelStyle={OPLSharedStyles.datePickerText}
                  />
                )
              }}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name="to"
              rules={{
                validate: (val) => {
                  if (!val && !watch(`from`)) {
                    return true
                  } else if (!!val && !moment(val).isValid()) {
                    return `${t('message:MS020081')}`
                  } else if (!!val && moment(val).valueOf() > moment(new Date()).valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (!val && !!watch(`from`)) {
                    return `${t('message:MS020009', { field: t('OPL_CS:OPL:To') })}`
                  } else if (moment(val).valueOf() < moment(watch(`from`)).valueOf()) {
                    return `${t('message:MS030044')}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <DatePicker
                    alwaysShow
                    label={t('OPL_CS:OPL:To')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    minDate={undefined}
                    errorMessage={error?.message}
                    labelStyle={OPLSharedStyles.datePickerText}
                  />
                )
              }}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name="fileName"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('OPL_CS:OPL:FileName')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={300}
                />
              )}
            />
          </SectionCol>
        </SectionRow>
        <SectionRow sectionStyles={listStyles.secondLine}>
          <SectionCol>
            <Controller
              control={control}
              name="title"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('OPL_CS:OPL:Title')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={300}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name="ADCode"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('OPL_CS:OPL:ADCode')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={12}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name="expirationDate"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <DatePicker
                    // required={true}
                    alwaysShow
                    label={t('CustomerEngagement:ExpirationDate')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    // maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    minDate={undefined}
                    errorMessage={error?.message}
                    labelStyle={OPLSharedStyles.datePickerText}
                  />
                )
              }}
            />
          </SectionCol>
        </SectionRow>
        {userPermissions.includes(Permission.SearchOPLSDSignatureList) && (
          <View style={[listStyles.buttonContainer, listStyles.secondLine]}>
            <TouchableOpacity
              style={[sharedStyle.button, sharedStyle.btnRed, listStyles.buttonContent1]}
              onPress={() => onSearchEvent()}
            >
              <Text style={sharedStyle.textButtonRed}>{t('common:Search')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </SectionContent>
      <CheckboxTable
        displayedColumns={displayedColumns}
        dataSource={OPLSignatureList}
        stickyHeader
        isPagination={true}
        totalRecord={total}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setPageSize={setPageSize}
        setTotalRecord={setTotal}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <View style={[listStyles.buttonContainer]}>
        {userPermissions.includes(Permission.DeleteOPLSDSignatureList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableDelete ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              listStyles.buttonContent
            ]}
            onPress={() => onClickBtnDeleteEvent()}
            disabled={!hasEnableDelete}
          >
            <Text style={[hasEnableDelete ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('claim:Delete')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.EditOPLSDSignatureList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableEdit ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              listStyles.buttonContent
            ]}
            onPress={() => onEditEvent()}
            disabled={!hasEnableEdit}
          >
            <Text style={[hasEnableEdit ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:OPL:Edit')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.ViewdetailOPLSDSignatureList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableViewDetail ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              listStyles.buttonContent
            ]}
            onPress={() => onViewDetailEvent()}
            disabled={!hasEnableViewDetail}
          >
            <Text style={[hasEnableViewDetail ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:OPL:ViewDetail')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.ExtractOPLSDSignatureList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableExtract ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              listStyles.buttonContent
            ]}
            disabled={!hasEnableExtract}
            onPress={() => onExtractFileListEvent()}
          >
            <Text style={[hasEnableExtract ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:OPL:Extract')}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <OPLDeleteModal
        headerTitle={t('OPL_CS:OPL:Notice')}
        message={t('message:MS020066')}
        isVisiable={isOpenDeleteModal}
        onCloseModal={() => setIsOpenDeleteModal(false)}
        onCancelEvent={() => setIsOpenDeleteModal(false)}
        onYesEvent={onConfirmDeleteEvent}
      ></OPLDeleteModal>
    </ScrollView>
  )
}
const listStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24
  },
  secondLine: {
    marginTop: 20
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonContent: {
    marginTop: 0
  },
  buttonContent1: {
    marginVertical: 0
  },
  checkboxContainer: {
    padding: 0,
    paddingLeft: 10,
    margin: 0
  }
})
