
export namespace CSGADDocumentConst {
  export const CSAreaConstList = [
    {
      label: 'Central 1',
      value: 'AR001'
    },
    {
      label: 'Central 2',
      value: 'AR002'
    },
    {
      label: 'Central 3',
      value: 'AR003'
    },
    {
      label: 'North 1',
      value: 'AR004'
    },
    {
      label: 'North 2',
      value: 'AR005'
    },
    {
      label: 'North 3',
      value: 'AR006'
    },
    {
      label: 'North 4',
      value: 'AR007'
    },
    {
      label: 'HCM',
      value: 'AR008'
    },
    {
      label: 'South 1',
      value: 'AR009'
    },
    {
      label: 'South 2',
      value: 'AR010'
    }
  ]

  export const DocIDConstList = [
    {
      docName: 'Giấy ủy quyền của GAD cho nhân viên GA/TO',
      docID: '40101311',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'GAD THE POWER OF ATTORNEY FOR GA/TO STAFF BY GAD',
      docNo: 'D1230'
    },
    {
      docName: 'PYC cấp/hủy quyền',
      docID: '40101361',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'REQUEST OF SYSTEM AUTHORITY / DELETE SYSTEM AUTHORITY',
      docNo: 'D1229'
    },
    {
      docName: 'Chữ ký mẫu của GAD',
      docID: '40101371',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'GAD SIGNATURE',
      docNo: 'D1235'
    }
    ,
    {
      docName: 'PYC đăng ký huấn luyện',
      docID: '40101381',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'TRAINING REQUEST',
      docNo: 'D1236'
    }
  ]
}