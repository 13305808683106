import React from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CheckboxTable, CheckboxTableColum, OPLCommon, OPLDocumentConst, OPLSharedStyles } from '../../opl'
import { SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import {
  AppContext,
  assets,
  AuthService,
  Checkbox,
  CSService,
  DatePicker,
  ErrorHandling,
  GeneralService,
  OfficeCode,
  Permission,
  PulseOpsFormat,
  SelectSearch,
  sharedStyle
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { CSStackContext } from '../CSStackContext'
import { CSGADDocumentForm } from './CSGADDocumentForm'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'
import moment from 'moment'
import { CSGADDocumentConst } from './CSGADDocumentConst'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import OPLDeleteModal from '../../opl/opl-common/OPLDeleteModal'

export const CSGADDocumentList = () => {
  const { t } = useTranslation()
  const { userPermissions } = React.useContext(CSStackContext)
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [hasEnableDelete, setHasEnableDelete] = React.useState(false)
  const [hasEnableViewDetail, setHasEnableViewDetail] = React.useState(false)
  const [hasEnableExtract, setHasEnableExtract] = React.useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [total, setTotal] = React.useState<number>(0)
  const [GADDocumentList, setGADDocumentList] = React.useState<Array<CSService.GADDocumentInfo>>([])
  const [userEmail, setUserEmail] = React.useState<string>('')
  const isFocused = useIsFocused()
  const { control, getValues, setValue, trigger, watch } = useForm<CSGADDocumentForm.CSGADDocumentListData>({
    defaultValues: {
      from: null,
      to: null,
      GAOfficeCode: undefined,
      docID: undefined,
      CSArea: undefined,
      isSelectedAll: false,
      GADDocumentList: []
    },
    mode: 'onChange'
  })

  const GADDocumentFieldList = useFieldArray<CSGADDocumentForm.CSGADDocumentListData>({
    control: control,
    name: 'GADDocumentList'
  })

  const displayedColumns: CheckboxTableColum[] = [
    {
      title: '',
      fieldName: '',
      disabled: true,
      headerColumnRender: (list: any) => {
        return (
          <Controller
            control={control}
            name="isSelectedAll"
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <Checkbox
                  onChange={(val) => {
                    onChange(val)
                    onSelectedAllEvent(val, list.length)
                  }}
                  onBlur={onBlur}
                  value={value}
                  checkBoxStyle={OPLSharedStyles.checkboxContainer}
                />
              )
            }}
          />
        )
      },
      rowRender: (renderProps) => {
        return (
          <Controller
            control={control}
            name={`GADDocumentList.${renderProps.index}.isSelectedItem`}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <Checkbox
                  key={`GADDocumentList_isSelectedItem_${renderProps.index}`}
                  onChange={(val) => {
                    onChange(val)
                    setValue(`GADDocumentList.${renderProps.index}.historyID`, GADDocumentList[renderProps.index].id)
                    onSelectedItemEvent()
                  }}
                  onBlur={onBlur}
                  value={value}
                  checkBoxStyle={OPLSharedStyles.checkboxContainer}
                />
              )
            }}
          />
        )
      }
    },
    {
      title: t('OPL_CS:CS:Document'),
      fieldName: 'fileName'
    },
    {
      title: t('OPL_CS:CS:GAOfficeCode'),
      fieldName: 'gaCode'
    },
    {
      title: t('OPL_CS:CS:DocIDDocumentType'),
      fieldName: 'docId'
    },
    {
      title: t('OPL_CS:CS:ModifiedDate'),
      fieldName: 'modifiedDate'
    },
    {
      title: t('OPL_CS:OPL:ModifiedBy'),
      fieldName: 'modifiedBy'
    },
    {
      title: t('Reports:Status'),
      fieldName: 'status'
    }
  ]
  const GAOfficeCodeList: OfficeCode[] = pipe(
    GeneralService.getALLOfficeList(),
    ZIO.map((officeList) => {
      return officeList && officeList.length > 0 ? officeList : []
    }),
    ErrorHandling.runDidMount([])
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      getGADDocumentList(pageNum, pageSize)
      pipe(
        AuthService.userInfo,
        ZIO.map((userInfo) => {
          setUserEmail(userInfo.isGaLogin ? userInfo.name : userInfo.email)
          return userInfo
        }),
        ErrorHandling.run()
      )
    }

    return () => {
      GADDocumentFieldList.remove()
      setValue(`GADDocumentList`, [])
    }
  }, [isFocused])

  const getGADDocumentList = (pageN: number, pageS: number) => {
    const searchFormData = getValues()
    const fromDate = searchFormData.from
      ? PulseOpsFormat.serviceInquiry_StartOfDate(searchFormData.from, true).toISOString()
      : ''
    const toDate = searchFormData.to
      ? PulseOpsFormat.serviceInquiry_endOfDate(searchFormData.to, true).toISOString()
      : ''
    const queryData: CSService.GADDocumentListRequest = {
      code: '',
      documentType: 'CS_GAD',
      fileName: '',
      gaCode: searchFormData.GAOfficeCode?.value || '',
      csArea: searchFormData.CSArea?.value || '',
      docId: searchFormData.docID?.value || '',
      createdDate: '',
      createdBy: '',
      modifiedDate: '',
      modifiedBy: '',
      status: '',
      transactionType: '',
      docNo: '',
      start: pageN,
      size: pageS,
      fromDate: fromDate,
      toDate: toDate
    }
    pipe(
      CSService.getGADDocumentList(queryData),
      ZIO.map((response) => {
        if (response && response.body.length > 0) {
          const customedData = response.body.map((item) => ({
            ...item,
            activityDate: item.activityDate
              ? PulseOpsFormat.dateStringtoFormat(item.activityDate, 'DD/MM/YYYY - HH:mm:ss')
              : '',
            modifiedDate: item.modifiedDate
              ? PulseOpsFormat.dateStringtoFormat(item.modifiedDate, 'DD/MM/YYYY - HH:mm:ss')
              : '',
            docId: pipe(
              O.fromNullable(CSGADDocumentConst.DocIDConstList.find((x) => x.docID === item.docId)),
              O.fold(
                () => '',
                (successData) => successData.docName
              )
            )
          }))
          setGADDocumentList(customedData)
          initGADDocumentListForm(customedData)
          setTotal(response.total)
        } else {
          setGADDocumentList([])
          setTotal(0)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const initGADDocumentListForm = (GADDocumentArr: CSService.GADDocumentInfo[]) => {
    let i: number = 0
    GADDocumentFieldList.remove()
    setValue(`GADDocumentList`, [])
    setHasEnableViewDetail(false)
    setHasEnableDelete(false)
    setHasEnableExtract(false)
    setValue('isSelectedAll', false)
    while (i < GADDocumentArr.length) {
      GADDocumentFieldList.append({
        historyID: GADDocumentArr[i].id,
        isSelectedItem: false
      })
      setValue(`GADDocumentList.${i}.historyID`, GADDocumentArr[i].id)
      setValue(`GADDocumentList.${i}.isSelectedItem`, false)
      i++
    }
  }

  const onSearchEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      setPageNum(0)
      setPageSize(10)
      getGADDocumentList(0, 10)
    }
  }

  const onPageChange = (pageN: number, pageSi: number) => {
    setPageNum(pageN)
    getGADDocumentList(pageN, pageSi)
  }

  const onRowsPerPageChange = (pageN: number, pageSi: number) => {
    setPageSize(pageSi)
    getGADDocumentList(pageN, pageSi)
  }

  const onSelectedAllEvent = (selectedAllValue: boolean, arrlength: number) => {
    let i: number = 0
    while (i < arrlength) {
      if (watch(`GADDocumentList.${i}.isSelectedItem`) !== selectedAllValue) {
        setValue(`GADDocumentList.${i}.isSelectedItem`, selectedAllValue)
      }
      setValue(`GADDocumentList.${i}.historyID`, GADDocumentList[i].id)
      i++
    }
    if (arrlength <= 10 && selectedAllValue) {
      setHasEnableExtract(true)
    } else {
      setHasEnableExtract(false)
    }
    selectedAllValue ? setHasEnableDelete(true) : setHasEnableDelete(false)
    setHasEnableViewDetail(false)
  }

  const onSelectedItemEvent = () => {
    const GADDocumentArr = watch(`GADDocumentList`)
    if (GADDocumentArr && GADDocumentArr.length > 0) {
      const hasUnSelectedAll = GADDocumentArr.some((p) => !p.isSelectedItem)
      const hasSelectecItem = GADDocumentArr.some((p) => p.isSelectedItem)
      const selectedListLength = GADDocumentArr.filter((p) => p.isSelectedItem).length
      if (watch(`isSelectedAll`) !== !hasUnSelectedAll) {
        setValue('isSelectedAll', !hasUnSelectedAll)
      }
      hasSelectecItem ? setHasEnableDelete(true) : setHasEnableDelete(false)
      selectedListLength === 1 ? setHasEnableViewDetail(true) : setHasEnableViewDetail(false)
      selectedListLength <= 10 && selectedListLength >= 1 ? setHasEnableExtract(true) : setHasEnableExtract(false)
    }
  }

  const onClickBtnDeleteEvent = () => {
    setIsOpenDeleteModal(true)
  }
  const getUpdatedData = () => {
    const signatureListArr = watch(`GADDocumentList`)
    const updateItems = signatureListArr
      .filter(
        (x) =>
          x.isSelectedItem &&
          GADDocumentList.some(
            (p) => p.id === x.historyID && p.status !== OPLDocumentConst.OPLDocumentStatusConst.Inactive
          )
      )
      .map((item) => {
        return {
          id: item.historyID,
          modifiedDate: new Date().toISOString(),
          modifiedBy: userEmail,
          status: OPLDocumentConst.OPLDocumentStatusConst.Inactive
        }
      })
    const updatedData: CSService.DeleteADDocumentListRequest = {
      body: updateItems
    }
    return updatedData
  }

  const onConfirmDeleteEvent = () => {
    const deletedData = getUpdatedData()
    pipe(
      deletedData.body && deletedData.body.length <= 0
        ? ZIO.succeed({
            status: {
              message: 'MS020085'
            }
          })
        : CSService.deleteGADDocumentList(deletedData),
      ZIO.map((response) => {
        // if (response.status.message) {
        //   showToast(t('message:MS020085'), 'success')
        //   getGADDocumentList(pageNum, pageSize)
        // } else {
        //   showToast(t('message:(MS020080'), 'error')
        // }
        showToast(t('message:MS020090'), 'success')
        getGADDocumentList(pageNum, pageSize)
        setIsOpenDeleteModal(false)
      }),
      ZIO.mapError((error) => {
        showToast(t('message:(MS020080'), 'error')
        setIsOpenDeleteModal(false)
        return error
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getActiveData = () => {
    const signatureListArr = watch(`GADDocumentList`)
    const currentDate = new Date().toISOString()
    const updateItems = signatureListArr
      .filter(
        (x) =>
          x.isSelectedItem &&
          GADDocumentList.some(
            (p) => p.id === x.historyID && p.status !== OPLDocumentConst.OPLDocumentStatusConst.Active
          )
      )
      .map((item) => {
        return {
          id: item.historyID,
          modifiedDate: currentDate,
          modifiedBy: userEmail,
          activityDate: currentDate,
          status: OPLDocumentConst.OPLDocumentStatusConst.Active
        }
      })
    const updatedData: CSService.UpdateADDocumentListRequest = {
      body: updateItems
    }
    return updatedData
  }

  const onActiveEvent = () => {
    const activeData = getActiveData()
    pipe(
      activeData.body && activeData.body.length <= 0
        ? ZIO.succeed({
            status: {
              message: 'MS020085'
            }
          })
        : CSService.updateGADDocumentList(activeData),
      ZIO.map((response) => {
        // if (response.status.message) {
        //   showToast(t('message:MS020085'), 'success')
        //   getGADDocumentList(pageNum, pageSize)
        // } else {
        //   showToast(t('message:(MS020080'), 'error')
        // }
        // showToast(t('message:MS020085'), 'success')
        getGADDocumentList(pageNum, pageSize)
        setIsOpenDeleteModal(false)
      }),
      ZIO.mapError((error) => {
        showToast(t('message:(MS020080'), 'error')
        setIsOpenDeleteModal(false)
        return error
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }
  const onViewDetailEvent = () => {
    const GADDocumentArr = watch(`GADDocumentList`)
    const fileUrl = pipe(
      GADDocumentArr.find((p) => p.isSelectedItem),
      O.fromNullable,
      O.map((documentItem) => {
        return pipe(
          GADDocumentList.find((x) => x.id === documentItem.historyID),
          O.fromNullable,
          O.fold(
            () => '',
            (item) => item.urlFilenet
          )
        )
      }),
      O.getOrElse(() => '')
    )
    if (!!fileUrl) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(fileUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          // const type = res.headers.get('content-type') || ''
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onExtractFileListEvent = () => {
    const signatureArr = watch(`GADDocumentList`)
    const extractList = GADDocumentList.filter((p) =>
      signatureArr.some((x) => x.historyID === p.id && x.isSelectedItem)
    )
    pipe(
      extractList,
      A.map((item) => {
        const fileUrl = item.urlFilenet
        return pipe(
          ZIO.zipPar(AuthService.token, AuthService.getLoginType),
          ZIO.flatMap(([token, loginType]) => {
            return ZIO.fromPromise(() =>
              fetch(fileUrl, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
            OPLCommon.downloadFileByURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      })
    )
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <SectionRow>
          <SectionCol>
            <Controller
              control={control}
              name={`GAOfficeCode`}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('OPL_CS:CS:GA')}
                  options={GAOfficeCodeList.map((item) => ({ label: item.code, value: item.code }))}
                  value={value}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={t('common:Select')}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name={`CSArea`}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('OPL_CS:CS:CSArea')}
                  options={CSGADDocumentConst.CSAreaConstList}
                  value={value}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  //  errorMessage={!value || !value.value ? error?.message : ''}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={t('common:Select')}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name={`docID`}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('OPL_CS:CS:DocIDDocumentType')}
                  options={CSGADDocumentConst.DocIDConstList.map((item) => ({
                    label: item.docName,
                    value: item.docID
                  }))}
                  value={value}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={t('common:Select')}
                />
              )}
            />
          </SectionCol>
        </SectionRow>
        <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
          <SectionCol>
            <Controller
              control={control}
              name="from"
              rules={{
                validate: (val) => {
                  if (!val && !watch(`to`)) {
                    return true
                  } else if (!!val && !moment(val).isValid()) {
                    return `${t('message:MS020081')}`
                  } else if (!!val && moment(val).valueOf() > moment(new Date()).valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (!val && !!watch(`to`)) {
                    return `${t('message:MS020009', { field: t('OPL_CS:OPL:From') })}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  alwaysShow
                  label={t('OPL_CS:OPL:From')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  minDateMessage={''}
                  errorMessage={error?.message}
                  labelStyle={OPLSharedStyles.datePickerText}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <Controller
              control={control}
              name="to"
              rules={{
                validate: (val) => {
                  if (!val && !watch(`from`)) {
                    return true
                  } else if (!!val && !moment(val).isValid()) {
                    return `${t('message:MS020081')}`
                  } else if (!!val && moment(val).valueOf() > moment(new Date()).valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (!val && !!watch(`from`)) {
                    return `${t('message:MS020009', { field: t('OPL_CS:OPL:To') })}`
                  } else if (moment(val).valueOf() < moment(watch(`from`)).valueOf()) {
                    return `${t('message:MS030044')}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  // required={true}
                  alwaysShow
                  label={t('OPL_CS:OPL:To')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  errorMessage={error?.message}
                  labelStyle={OPLSharedStyles.datePickerText}
                />
              )}
            />
          </SectionCol>
        </SectionRow>
        {userPermissions.includes(Permission.SearchGADDocsList) && (
          <View style={[OPLSharedStyles.buttonContainer, OPLSharedStyles.secondLine]}>
            <TouchableOpacity
              style={[sharedStyle.button, sharedStyle.btnRed, listStyles.buttonContent1]}
              onPress={() => onSearchEvent()}
            >
              <Text style={sharedStyle.textButtonRed}>{t('common:Search')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </SectionContent>
      <CheckboxTable
        displayedColumns={displayedColumns}
        dataSource={GADDocumentList}
        stickyHeader
        isPagination={true}
        totalRecord={total}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setPageSize={setPageSize}
        setTotalRecord={setTotal}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <View style={[OPLSharedStyles.buttonContainer, OPLSharedStyles.footerContainer]}>
        {userPermissions.includes(Permission.DeleteGADDocsList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableDelete ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              OPLSharedStyles.buttonContent
            ]}
            onPress={() => onClickBtnDeleteEvent()}
            disabled={!hasEnableDelete}
          >
            <Text style={[hasEnableDelete ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('claim:Delete')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.ActiveGADDocsList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableDelete ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              OPLSharedStyles.buttonContent
            ]}
            onPress={() => onActiveEvent()}
            disabled={!hasEnableDelete}
          >
            <Text style={[hasEnableDelete ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:CS:Active')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.ViewdetailGADDocsList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableViewDetail ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              OPLSharedStyles.buttonContent
            ]}
            onPress={() => onViewDetailEvent()}
            disabled={!hasEnableViewDetail}
          >
            <Text style={[hasEnableViewDetail ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:OPL:ViewDetail')}
            </Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.ExtractGADDocsList) && (
          <TouchableOpacity
            style={[
              sharedStyle.button,
              hasEnableExtract ? sharedStyle.btnRed : sharedStyle.btnDisabled,
              OPLSharedStyles.buttonContent
            ]}
            disabled={!hasEnableExtract}
            onPress={() => onExtractFileListEvent()}
          >
            <Text style={[hasEnableExtract ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled]}>
              {t('OPL_CS:OPL:Extract')}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <OPLDeleteModal
        headerTitle={t('OPL_CS:OPL:Notice')}
        message={t('message:MS020066')}
        isVisiable={isOpenDeleteModal}
        onCloseModal={() => setIsOpenDeleteModal(false)}
        onCancelEvent={() => setIsOpenDeleteModal(false)}
        onYesEvent={onConfirmDeleteEvent}
      ></OPLDeleteModal>
    </ScrollView>
  )
}
const listStyles = StyleSheet.create({
  buttonContent1: {
    marginVertical: 0
  }
})
