import React from 'react'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { Error, FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import {
  AppContext,
  AuthService,
  DatePicker,
  ErrorHandling,
  Input,
  OPLService,
  Permission,
  sharedStyle
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { OPLDocumentForm } from './OPLDocumentForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { OPLStackParamList } from '../OPLStackParamList'
import { useLoading } from '@mxt/zio-react'
import { OPLDocumentConst } from './OPLDocumentConst'
import { OPLStackContext } from '../OPLStackContext'

export const OPLEditingDocument = (props: StackScreenProps<OPLStackParamList, 'OPLEditingDocument'>) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const actionType = props.route.params.actionType
  const historyID = props.route.params.historyID
  const documentType = props.route.params.documentType
  const isOnlyView = actionType === 'view'
  const [isLoading, bindLoader] = useLoading(false)
  const { userPermissions } = React.useContext(OPLStackContext)
  const { showToast, changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const rootNavigation = useNavigation()
  // const [] = React.useState()
  const { setValue, control, trigger, getValues, reset } = useForm<OPLDocumentForm.OPLEditingDocumentFormData>({
    defaultValues: {
      historyID: '',
      fileLink: '',
      fileName: '',
      title: '',
      content: '',
      code: '',
      documentType: '',
      expirationDate: null,
      createDate: null,
      createBy: '',
      modifiedDate: null,
      modifiedBy: '',
      status: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('OPL_CS:OPL:Document'),
          navigate: () =>
            props.navigation.navigate(
              documentType === OPLDocumentConst.OPLDocumentTypeConst.OPL_FORMS
                ? 'OPLFormDocument'
                : 'OPLBusinessDocument'
            )
        },
        {
          title: t('OPL_CS:OPL:Edit'),
          navigate: null
        }
      ])
    }
    return () => {
      reset({
        historyID: '',
        fileLink: '',
        fileName: '',
        title: '',
        content: '',
        code: '',
        documentType: '',
        expirationDate: null,
        createDate: null,
        createBy: '',
        modifiedDate: null,
        modifiedBy: '',
        status: ''
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const detailData = pipe(
    ZIO.zipPar(OPLService.getOPLDocumentDetailByID(historyID), AuthService.userInfo),
    ZIO.map(([detailResponse, userInfo]) => {
      setValue('historyID', detailResponse.id)
      setValue('fileName', detailResponse.fileName)
      setValue('title', detailResponse.title)
      setValue('code', detailResponse.code)
      setValue('content', detailResponse.content)
      setValue('documentType', detailResponse.documentType)
      setValue('expirationDate', detailResponse.expirationDate ? new Date(detailResponse.expirationDate) : null)
      setValue('createDate', detailResponse.createdDate ? new Date(detailResponse.createdDate) : null)
      setValue('createBy', detailResponse.createdBy)
      setValue('modifiedDate', new Date())
      setValue(`modifiedBy`, userInfo.isGaLogin ? userInfo.name : userInfo.email)
      setValue(`status`, detailResponse.status)
      return detailResponse
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([historyID])
  )

  const onNavigatePreviousScreen = () => {
    props.navigation.navigate(
      documentType === OPLDocumentConst.OPLDocumentTypeConst.OPL_FORMS ? 'OPLFormDocument' : 'OPLBusinessDocument'
    )
  }

  const getUpdatedData = (formData: OPLDocumentForm.OPLEditingDocumentFormData) => {
    const expirationDate = formData.expirationDate ? moment(formData.expirationDate).format("YYYY-MM-DD") : ''
    // const expirationDate = formData.expirationDate ? formData.expirationDate.toISOString() : ''
    const createdDate = detailData?.createdDate || ''
    const modifiedDate = formData.modifiedDate ? formData.modifiedDate.toISOString() : ''
    const updatedData: OPLService.UpdateOPLDocumentRequest = {
      body: [
        {
          id: formData.historyID,
          documentType: formData.documentType,
          fileName: formData.fileName,
          title: formData.title,
          content: formData.content,
          code: formData.code,
          expirationDate: expirationDate,
          createdDate: createdDate,
          createdBy: formData.createBy,
          modifiedDate: modifiedDate,
          modifiedBy: formData.modifiedBy,
          status: formData.status
        }
      ]
    }
    return updatedData
  }

  const onSaveEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formData = getValues()
      const updatedData = getUpdatedData(formData)
      pipe(
        OPLService.updateOPLDocument(updatedData),
        ZIO.map((response) => {
          if (['MS020085'].includes(response.status.message)) {
            showToast(t('message:MS020085'), 'success')
            onNavigatePreviousScreen()
          } else {
            showToast(t('message:MS020071'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:MS020071'), 'error')
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onClickFileNameEvent = (linkUrl: string) => {
    if (!!linkUrl) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(linkUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onResetEvent = () => {
    if (detailData) {
      setValue('fileName', detailData.fileName)
      setValue('title', detailData.title)
      setValue('code', detailData.code)
      setValue('content', detailData.content)
      setValue('expirationDate', detailData.expirationDate ? new Date(detailData.expirationDate) : null)
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <TouchableOpacity onPress={() => onClickFileNameEvent(detailData?.urlFilenet || '')}>
          <View>
            <Text style={OPLSharedStyles.underlineText}>{detailData?.fileName}</Text>
          </View>
        </TouchableOpacity>
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:FileName') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
                {/* <Input title={t('FileName')} value={''} required /> */}
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:Title') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      disabled={isOnlyView}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('CustomerEngagement:Content') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      disabled={isOnlyView}
                      numberOfLines={4}
                      maxLength={300}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="code"
                  rules={{
                    validate: (val) => {
                      if (!val) {
                        return `${t('message:MS020001', { field: t('CustomerEngagement:Code') })}`
                      } else if (val.length !== 8) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Code')}
                      value={value}
                      maxLength={8}
                      inputType="number"
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  rules={{
                    validate: (val) => {
                      if (!!val && !moment(val).isValid()) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifiedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
      {!isOnlyView && (
        <View style={[OPLSharedStyles.buttonContainer]}>
          {userPermissions.includes(Permission.CancelOPLSDEditDocument) && (
            <TouchableOpacity style={[sharedStyle.button]} onPress={() => onResetEvent()}>
              <Text style={sharedStyle.textButton}>{t('OPL_CS:OPL:Cancel')}</Text>
            </TouchableOpacity>
          )}
          {userPermissions.includes(Permission.SaveOPLSDEditDocument) && (
            <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
              <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </ScrollView>
  )
}
const createDocStyles = StyleSheet.create({
  datePickerText: {
    marginBottom: 2
  },
  underlineText: {
    // textDecorationLine: 'underline',
    color: 'rgb(30, 165, 252)'
  }
})
