import * as React from 'react'
import {
  Title,
  Panel,
  Divider,
  RadioButtonGroup,
  HeaderTable,
  PayoutPopup,
  PayoutService,
  ErrorHandling,
  AppContext,
  GeneralService,
  formatNumberWithComma,
  ControlProps,
  TransactionType,
  SubmissionService
} from '@pulseops/common'
import { Row, Column, Label, Checkbox, Error, PayoutInfoPopup } from '@pulseops/submission/common'
import { PayPremiumPopup } from './pay-premium-popup'
import { RepayLoanPopup } from './repay-loan-popup'
import { OtherPayPremiumPopup } from './other-pay-premium-popup'
import { BankTransferPopup } from './bank-transfer'
import { CashAtCounterPopup } from './cash-at-counter'
import { PaidAtBankPopup } from './paid-at-bank'
import { EwalletMomoPopup } from './ewallet-momo'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { OwnerInfo } from './bank-transfer/constants'
import _ from 'lodash'
import moment from 'moment'
import { Text, View } from 'react-native'
import { Button } from 'react-native-elements'
// import { PayoutInfoPopup } from './payout-info-popup'
export interface PayoutMethodRef {
  clearData: () => void
}

const OTHER_METHODS = [
  PayoutPopup.PayoutMethods.BANKTRANSFER,
  PayoutPopup.PayoutMethods.PAID_AT_BANK,
  // PayoutPopup.PayoutMethods.MOMO,
  PayoutPopup.PayoutMethods.CASH_AT_COUNTER
]

interface Props extends ControlProps<PayoutPopup.Summary[]> {
  methods?: PayoutPopup.PayoutMethods[]
  policyNum: string
  maxAmount?: number
  editable?: boolean
  enable?: boolean
  transactionType: TransactionType
  inheritanceFlag?: boolean
  otherPremiumTitle?: string
  isFractionFormat?: boolean
  officeCode?: string
  isSeaBankPolicy?: boolean
  isRefundCardFlag?: boolean
}

const _PayoutMethod = (
  {
    policyNum,
    methods = [
      PayoutPopup.PayoutMethods.PAYPREMIUM,
      PayoutPopup.PayoutMethods.PAYLOAN,
      PayoutPopup.PayoutMethods.OTHER,
      PayoutPopup.PayoutMethods.CASHLESS,
      PayoutPopup.PayoutMethods.BANKTRANSFER,
      PayoutPopup.PayoutMethods.PAID_AT_BANK,
      PayoutPopup.PayoutMethods.REPAY_APL,
      PayoutPopup.PayoutMethods.REPAY_OPL,
      PayoutPopup.PayoutMethods.REPAY_OPL_APL,
      // PayoutPopup.PayoutMethods.MOMO,
      PayoutPopup.PayoutMethods.CASH_AT_COUNTER
    ],
    maxAmount,
    editable = true,
    enable = true,
    value,
    errorMessage,
    transactionType,
    inheritanceFlag = false,
    otherPremiumTitle,
    onChange,
    isFractionFormat,
    officeCode,
    isSeaBankPolicy,
    isRefundCardFlag
  }: Props,
  ref: React.Ref<PayoutMethodRef>
) => {
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()

  const [isPayPremiumChecked, setIsPayPremiumChecked] = React.useState<boolean>(false)
  const [isRepayLoanChecked, setIsRepayLoanChecked] = React.useState<boolean>(false)
  const [isOtherPayPremiumChecked, setIsOtherPayPremiumChecked] = React.useState<boolean>(false)
  const [isOtherMethodChecked, setIsOtherMethodChecked] = React.useState<boolean>(false)

  const [isPayPremiumToggle, setIsPayPremiumToggle] = React.useState<boolean>(false)
  const [isRepayLoanToggle, setIsRepayLoanToggle] = React.useState<boolean>(false)
  const [isOtherPayPremiumToggle, setIsOtherPayPremiumToggle] = React.useState<boolean>(false)
  const [isOtherMethodToggle, setIsOtherMethodToggle] = React.useState<boolean>(false)

  const [otherMethodSelected, setOtherMethodSelected] = React.useState<PayoutPopup.PayoutMethods>()

  const [payPremiumDetails, setPayPremiumDetails] = React.useState<PayoutPopup.PayPremiumData[]>([])
  const [repayLoanDetails, setRepayLoanDetails] = React.useState<PayoutPopup.ReloanPayData[]>([])
  const [otherPayPremiumDetails, setOtherPayPremiumDetails] = React.useState<PayoutPopup.OtherPayPremiumData[]>([])
  const [ownerInfo, setOwnerInfo] = React.useState<OwnerInfo>({ ownerName: '', nationalId: '', mobilePhone: '' })

  const [payPremiumPayoutData, setPayPremiumPayoutData] = React.useState<PayoutPopup.PayoutData[]>([])
  const [repayLoanPayoutData, setRepayLoanPayoutData] = React.useState<PayoutPopup.PayoutData[]>([])
  const [otherPayPremiumPayoutData, setOtherPayPremiumPayoutData] = React.useState<PayoutPopup.PayoutData[]>([])
  const [bankTransferPayoutData, setBankTransferPayoutData] = React.useState<PayoutPopup.PayoutData>()
  const [cashAtCounterPayoutData, setCashAtCounterPayoutData] = React.useState<PayoutPopup.PayoutData>()
  const [paidAtBankPayoutData, setPaidAtBankPayoutData] = React.useState<PayoutPopup.PayoutData>()
  const [ewalletMomoData, setEwalletMomoData] = React.useState<PayoutPopup.PayoutData>()
  const [payoutDataSource, setPayoutDataSource] = React.useState<PayoutPopup.Summary[]>([])

  const [translator, setTranslator] = React.useState<PayoutPopup.PayoutMethodTranslator[]>([])
  const [isLoading, bindLoading] = useLoading(false)

  const [totalPayoutAmount, setTotalPayoutAmount] = React.useState<number>(0)
  const [viewInfoSpecs, setViewInfoSpecs] = React.useState<{
    index: number
    visible: boolean
  }>({
    index: -1,
    visible: false
  })

  React.useImperativeHandle(
    ref,
    () => ({
      clearData: () => {
        setOtherMethodSelected(undefined)

        setIsPayPremiumChecked(false)
        setIsRepayLoanChecked(false)
        setIsOtherPayPremiumChecked(false)
        setIsOtherMethodChecked(false)

        setPayPremiumPayoutData([])
        setRepayLoanPayoutData([])
        setOtherPayPremiumPayoutData([])
        setBankTransferPayoutData(undefined)
        setCashAtCounterPayoutData(undefined)
        setPaidAtBankPayoutData(undefined)
        setEwalletMomoData(undefined)
      }
    }),
    []
  )

  const getPayoutMethodTranslator = () => {
    return pipe(
      GeneralService.getPayoutMethods(),
      ZIO.tap((x) => {
        const translator = x.body.map((x) => ({
          typeEnum: x.typeEnum,
          nameEn: x.nameEn,
          nameVi: x.nameVi
        }))
        setTranslator(translator)
        return ZIO.unit
      })
    )
  }

  const isExcludePrimaryPolicy = () => {
    return [TransactionType.CANCEL_FROM_INCEPTION, TransactionType.MATURITY, TransactionType.FULL_SURRENDER].includes(
      transactionType
    )
  }

  const getPayPremiumDetails = (showModal: (isVisible: boolean) => void) => () => {
    pipe(
      PayoutService.getPayPremiumDetail(policyNum, isSeaBankPolicy),
      // ZIO.succeed([]),
      ZIO.tap((details) => {
        if (isExcludePrimaryPolicy()) {
          details = details.filter((x) => x.policyNum !== policyNum)
        }
        setPayPremiumDetails(details)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getRepayLoanDetails = (showModal: (isVisible: boolean) => void) => () => {
    pipe(
      isSeaBankPolicy ? ZIO.succeed([]) :
        PayoutService.getRepayLoadData(policyNum),
      ZIO.tap((details) => {
        if (isExcludePrimaryPolicy()) {
          details = details.filter((x) => x.policyNum !== policyNum)
        }
        details = details.filter((x) => x.apl > 0 || x.opl > 0)
        setRepayLoanDetails(details)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getOtherPremiumDetails = (showModal: (isVisible: boolean) => void) => () => {
    pipe(
      PayoutService.getOtherPremiumDetail(policyNum),
      ZIO.tap((details) => {
        if (isExcludePrimaryPolicy()) {
          details = details.filter((x) => x.policyNum !== policyNum)
        }
        setOtherPayPremiumDetails(details)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getOwnerInfo = (showModal: (isVisible: boolean) => void) => (payoutMethodType: PayoutPopup.PayoutMethods) => {
    pipe(
      PayoutService.getOwnerInfo(policyNum),
      ZIO.flatMap((ownerInfo) => {
        return payoutMethodType === PayoutPopup.PayoutMethods.MOMO
          ? pipe(
            SubmissionService.getNewOwnerInfo(policyNum),
            ZIO.map((responseData) => ({
              ownerName: ownerInfo.ownerName,
              nationalId: ownerInfo.nationalId,
              mobilePhone: responseData.mobilePhone
            }))
          )
          : ZIO.succeed(ownerInfo)
      }),
      ZIO.tap((info) => {
        setOwnerInfo(info)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getPayoutMethodData = () => {
    let data: PayoutPopup.Summary[] = []
    if (isPayPremiumChecked) {
      data = data.concat(
        payPremiumPayoutData.map(
          (payPremium): PayoutPopup.Summary => ({
            method: payPremium.method,
            methodView: PayoutPopup.translateMethod(payPremium.method, translator),
            detail: `${payPremium.policyNum} - ${payPremium.poName}`,
            amount: payPremium.amount,
            policyNum: payPremium.policyNum,
            poName: payPremium.poName,
            totalPremium: payPremium.totalPremium,

            bankAccountName: null,
            bankAccountNum: null,
            bankBranchCode: null,
            bankBranchName: null,
            bankCode: null,
            bankName: null,
            nationalId: null,
            payee: null,
            officeCode: null,
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      )
    }

    if (isRepayLoanChecked) {
      data = data.concat(
        repayLoanPayoutData.map(
          (payloanPremium): PayoutPopup.Summary => ({
            method: payloanPremium.method,
            methodView: PayoutPopup.translateMethod(payloanPremium.method, translator),
            detail: `${payloanPremium.policyNum} - ${payloanPremium.poName}`,
            amount: payloanPremium.amount,

            policyNum: payloanPremium.policyNum,
            poName: payloanPremium.poName,
            totalPremium: payloanPremium.totalPremium,

            bankAccountName: null,
            bankAccountNum: null,
            bankBranchCode: null,
            bankBranchName: null,
            bankCode: null,
            bankName: null,
            nationalId: null,
            payee: null,
            officeCode: null,
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      )
    }

    if (isOtherPayPremiumChecked) {
      data = data.concat(
        otherPayPremiumPayoutData.map(
          (otherPremium): PayoutPopup.Summary => ({
            method: otherPremium.method,
            methodView: PayoutPopup.translateMethod(otherPremium.method, translator),
            detail: `${otherPremium.policyNum} - ${otherPremium.poName}`,
            amount: otherPremium.amount,
            policyNum: otherPremium.policyNum,
            poName: otherPremium.poName,
            totalPremium: otherPremium.totalPremium,

            bankAccountName: null,
            bankAccountNum: null,
            bankBranchCode: null,
            bankBranchName: null,
            bankCode: null,
            bankName: null,
            nationalId: null,
            payee: null,
            officeCode: null,
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      )
    }

    if (isOtherMethodChecked && otherMethodSelected && bankTransferPayoutData) {
      data = data.concat([
        {
          method: bankTransferPayoutData.method,
          methodView: PayoutPopup.translateMethod(bankTransferPayoutData.method, translator),
          detail: `${bankTransferPayoutData.bankName} - ${bankTransferPayoutData.bankBranchName} - ${bankTransferPayoutData.poName} - ${bankTransferPayoutData.bankAccountNum}`,
          amount: bankTransferPayoutData.amount,
          policyNum: bankTransferPayoutData.policyNum,
          poName: bankTransferPayoutData.poName,
          bankCode: bankTransferPayoutData.bankCode,
          bankName: bankTransferPayoutData.bankName,
          bankAccountNum: bankTransferPayoutData.bankAccountNum,
          bankAccountName: bankTransferPayoutData.bankAccountName,
          bankBranchCode: bankTransferPayoutData.bankBranchCode,
          bankBranchName: bankTransferPayoutData.bankBranchName,
          nationalId: bankTransferPayoutData.nationalId,
          totalPremium: null,
          payee: bankTransferPayoutData.payee,
          officeCode: null,
          officeBankCode: undefined,
          officeType: undefined
        }
      ])
    }

    if (isOtherMethodChecked && otherMethodSelected && cashAtCounterPayoutData) {
      data = data.concat([
        {
          method: cashAtCounterPayoutData.method,
          methodView: PayoutPopup.translateMethod(cashAtCounterPayoutData.method, translator),
          detail: `${cashAtCounterPayoutData.officeCode} - ${cashAtCounterPayoutData.poName} - ${cashAtCounterPayoutData.nationalId}`,
          amount: cashAtCounterPayoutData.amount,
          policyNum: cashAtCounterPayoutData.policyNum,
          poName: cashAtCounterPayoutData.poName,
          bankCode: cashAtCounterPayoutData.bankCode,
          bankName: cashAtCounterPayoutData.bankName,
          bankAccountNum: cashAtCounterPayoutData.bankAccountNum,
          bankAccountName: cashAtCounterPayoutData.bankAccountName,
          bankBranchCode: cashAtCounterPayoutData.bankBranchCode,
          bankBranchName: cashAtCounterPayoutData.bankBranchName,
          nationalId: cashAtCounterPayoutData.nationalId,
          payee: cashAtCounterPayoutData.payee,
          officeCode: cashAtCounterPayoutData.officeCode,
          totalPremium: null,
          officeBankCode: cashAtCounterPayoutData.officeBankCode,
          officeType: cashAtCounterPayoutData.officeType
        }
      ])
    }
    if (isOtherMethodChecked && otherMethodSelected && paidAtBankPayoutData) {
      data = data.concat([
        {
          method: paidAtBankPayoutData.method,
          methodView: PayoutPopup.translateMethod(paidAtBankPayoutData.method, translator),
          detail: `${paidAtBankPayoutData.bankName} - ${paidAtBankPayoutData.bankBranchName} - ${paidAtBankPayoutData.poName
            } - ${paidAtBankPayoutData.nationalId} - ${moment(paidAtBankPayoutData.payee?.issuedDate).format(
              'DD/MM/YYYY'
            )} - ${paidAtBankPayoutData.payee?.issuedBy ?? ''}`,
          amount: paidAtBankPayoutData.amount,
          policyNum: paidAtBankPayoutData.policyNum,
          poName: paidAtBankPayoutData.poName,
          bankCode: paidAtBankPayoutData.bankCode,
          bankName: paidAtBankPayoutData.bankName,
          bankAccountNum: paidAtBankPayoutData.bankAccountNum,
          bankAccountName: paidAtBankPayoutData.bankAccountName,
          bankBranchCode: paidAtBankPayoutData.bankBranchCode,
          bankBranchName: paidAtBankPayoutData.bankBranchName,
          nationalId: paidAtBankPayoutData.nationalId,
          payee: paidAtBankPayoutData.payee,
          officeCode: paidAtBankPayoutData.officeCode,
          // issueDate: paidAtBankPayoutData.issueDate,
          // issueBy: paidAtBankPayoutData.issueBy,
          totalPremium: null,
          officeBankCode: undefined,
          officeType: undefined
        }
      ])
    }
    if (isOtherMethodChecked && otherMethodSelected && ewalletMomoData) {
      data = data.concat([
        {
          method: ewalletMomoData.method,
          methodView: PayoutPopup.translateMethod(ewalletMomoData.method, translator),
          detail: `${ewalletMomoData.poName} - ${ewalletMomoData.payee?.idNumber} - ${ewalletMomoData.payee?.phone}`,
          amount: ewalletMomoData.amount,
          policyNum: ewalletMomoData.policyNum,
          poName: ewalletMomoData.poName,
          bankCode: ewalletMomoData.bankCode,
          bankName: ewalletMomoData.bankName,
          bankAccountNum: ewalletMomoData.bankAccountNum,
          bankAccountName: ewalletMomoData.bankAccountName,
          bankBranchCode: ewalletMomoData.bankBranchCode,
          bankBranchName: ewalletMomoData.bankBranchName,
          nationalId: ewalletMomoData.nationalId,
          payee: ewalletMomoData.payee,
          officeCode: ewalletMomoData.officeCode,
          totalPremium: null,
          officeBankCode: ewalletMomoData.officeBankCode,
          officeType: ewalletMomoData.officeType
        }
      ])
    }

    return data
  }

  React.useEffect(() => {
    const data = getPayoutMethodData()
    if (data.length === 0 && value && value.length === 0) {
      setPayoutDataSource(value)
    } else {
      setPayoutDataSource(data)
      onChange && onChange(data)
    }
  }, [
    payPremiumPayoutData,
    repayLoanPayoutData,
    otherPayPremiumPayoutData,
    bankTransferPayoutData,
    cashAtCounterPayoutData,
    paidAtBankPayoutData,
    ewalletMomoData
  ])

  React.useEffect(() => {
    if (value) {
      setPayoutDataSource(value)
    }
  }, [value])

  React.useEffect(() => {
    if (payoutDataSource) {
      const totalPayoutAmount = payoutDataSource.reduce((total, item) => Number((total + item.amount).toFixed(2)), 0)
      setTotalPayoutAmount(totalPayoutAmount)
    }
  }, [payoutDataSource])

  React.useEffect(() => {
    pipe(getPayoutMethodTranslator(), bindLoading, ErrorHandling.run())
  }, [])

  React.useEffect(() => showGlobalLoading(isLoading), [isLoading])

  const getOtherMethods = () => {
    const data: { id: string; label: string }[] = []

    return methods.reduce((data, item) => {
      if (item === PayoutPopup.PayoutMethods.BANKTRANSFER) {
        data.push({
          label: t('submission:BankTransfer'),
          id: PayoutPopup.PayoutMethods.BANKTRANSFER
        })
      }

      if (item === PayoutPopup.PayoutMethods.PAID_AT_BANK) {
        data.push({
          label: t('submission:PaidAtBankMethod'),
          id: PayoutPopup.PayoutMethods.PAID_AT_BANK
        })
      }

      if (item === PayoutPopup.PayoutMethods.CASH_AT_COUNTER) {
        data.push({
          label: t('submission:CashAtCounter'),
          id: PayoutPopup.PayoutMethods.CASH_AT_COUNTER
        })
      }

      // if (item === PayoutPopup.PayoutMethods.MOMO) {
      //   data.push({
      //     label: t('submission:MomoeWallet'),
      //     id: PayoutPopup.PayoutMethods.MOMO
      //   })
      // }

      return data
    }, data)
  }

  const hasCashMethod = () => {
    const hasCashMethod =
      methods.find((item) =>
        [
          PayoutPopup.PayoutMethods.PAYPREMIUM,
          PayoutPopup.PayoutMethods.PAYLOAN,
          PayoutPopup.PayoutMethods.OTHER
        ].includes(item)
      ) !== undefined
    return hasCashMethod
  }

  return (
    <>
      <Title title={t('submission:PAYOUT_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        {editable && (
          <>
            <Label title={t('submission:PayoutMethod')} required />
            <Divider height={17} />
            <Column alignItems="baseline">
              <Row flex={1} flexWrap="wrap">
                {methods.includes(PayoutPopup.PayoutMethods.PAYPREMIUM) && (
                  <Checkbox
                    title={t('submission:Paypremium')}
                    value={isPayPremiumChecked}
                    enable={enable}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        getPayPremiumDetails(setIsPayPremiumToggle)()
                      } else {
                        setPayPremiumPayoutData([])
                      }
                      setIsPayPremiumChecked(isChecked)
                    }}
                    style={{ marginEnd: 50 }}
                  />
                )}
                {methods.includes(PayoutPopup.PayoutMethods.PAYLOAN) && (
                  <Checkbox
                    title={t('submission:RepayLoan')}
                    value={isRepayLoanChecked}
                    enable={enable}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        getRepayLoanDetails(setIsRepayLoanToggle)()
                      } else {
                        setRepayLoanPayoutData([])
                      }
                      setIsRepayLoanChecked(isChecked)
                    }}
                    style={{ marginEnd: 50 }}
                  />
                )}
                {methods.includes(PayoutPopup.PayoutMethods.OTHER) && (
                  <Checkbox
                    title={otherPremiumTitle || t('submission:OtherFee')}
                    value={isOtherPayPremiumChecked}
                    enable={enable}
                    onChange={(isChecked) => {
                      setIsOtherPayPremiumChecked(isChecked)
                      if (isChecked) {
                        getOtherPremiumDetails(setIsOtherPayPremiumToggle)()
                      } else {
                        setOtherPayPremiumPayoutData([])
                      }
                    }}
                    style={{ marginEnd: 50 }}
                  />
                )}
                {
                  isRefundCardFlag && (
                    <Checkbox
                      title={t('submission:RefundCard')}
                      value={isRefundCardFlag}
                      enable={!isRefundCardFlag}
                      style={{ marginEnd: 50 }}
                    />
                  )
                }
              </Row>
              {/* <Divider height={30} /> */}
              {methods.includes(PayoutPopup.PayoutMethods.CASHLESS) && (
                <Checkbox
                  style={{ marginTop: hasCashMethod() ? 30 : 0 }}
                  title={t('submission:OtherMethod')}
                  value={isOtherMethodChecked}
                  enable={enable}
                  onChange={(isChecked) => {
                    setIsOtherMethodChecked(isChecked)
                    setOtherMethodSelected(undefined)
                    if (!isChecked) {
                      setBankTransferPayoutData(undefined)
                      setCashAtCounterPayoutData(undefined)
                      setPaidAtBankPayoutData(undefined)
                      setEwalletMomoData(undefined)
                    }
                  }}
                />
              )}
              {isOtherMethodChecked && (
                <View style={{ width: '100%' }}>
                  <RadioButtonGroup
                    horizontal={false}
                    style={{ marginStart: 36 }}
                    radioButtonProps={{
                      style: {
                        marginEnd: 0,
                        marginTop: 15
                      }
                    }}
                    value={otherMethodSelected || ''}
                    onChange={(value) => {
                      const option = value as PayoutPopup.PayoutMethods
                      setOtherMethodSelected(option)
                      setBankTransferPayoutData(undefined)
                      setCashAtCounterPayoutData(undefined)
                      setPaidAtBankPayoutData(undefined)
                      setEwalletMomoData(undefined)
                      if (
                        [
                          PayoutPopup.PayoutMethods.BANKTRANSFER,
                          PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
                          PayoutPopup.PayoutMethods.PAID_AT_BANK,
                          // PayoutPopup.PayoutMethods.MOMO
                        ].includes(option)
                      ) {
                        getOwnerInfo(setIsOtherMethodToggle)(option)
                      } else {
                        setIsOtherMethodToggle(true)
                      }
                    }}
                    options={getOtherMethods()}
                  />
                </View>
              )}
            </Column>
            <Divider height={30} />
          </>
        )}
        <Row>
          <Label title={`${t('requestInfo:TotalPayoutAmount')}: `} color="#000000" />
          <Label title={`${formatNumberWithComma(totalPayoutAmount)} VND`} color={'#ED1B2E'} fontWeight="bold" />
        </Row>
        <Row justifyContent="space-between" marginTop={16} marginBottom={10}>
          <Label title={t('submission:PaymentSummary')} />
          <Label title={`${t('requestInfo:Currency')}: VND`} fontStyle="italic" />
        </Row>
        <HeaderTable
          columns={[
            {
              key: '0',
              title: t('Payout:PayMethodSummary'),
              name: 'methodView'
            },
            {
              key: '1',
              title: t('requestInfo:Detail'),
              name: 'detail'
            },
            {
              key: '2',
              title: t('requestInfo:Amount'),
              name: 'amount',
              styles: {
                textAlign: 'right'
              }
            },
            {
              key: '3',
              title: '',
              name: 'action',
              styles: {
                textAlign: 'right'
              },
              render: ({ index }) => {
                return OTHER_METHODS.includes(payoutDataSource[index]?.method) ? (
                  <View style={{ alignItems: 'center' }}>
                    <Button
                      title={<Text style={{ fontSize: 15, color: '#fff' }}>{t('submission:ViewInfo')}</Text>}
                      buttonStyle={{
                        width: 118,
                        height: 32,
                        borderRadius: 100
                      }}
                      onPress={() => setViewInfoSpecs({ index, visible: true })}
                    />
                  </View>
                ) : (
                  <></>
                )
              }
            }
          ]}
          dataSource={
            (payoutDataSource || []).map((item) => ({ ...item, amount: formatNumberWithComma(item.amount) })) as any[]
          }
        />
        {errorMessage && <Error style={{ marginTop: 10 }} message={errorMessage} />}
      </Panel>
      <PayPremiumPopup
        visible={isPayPremiumToggle}
        primaryPolicy={policyNum}
        details={payPremiumDetails}
        isSeaBankPolicy={isSeaBankPolicy}
        onClose={() => {
          setIsPayPremiumToggle(false)
          setIsPayPremiumChecked(false)
        }}
        onConfirm={(payoutData) => {
          setPayPremiumPayoutData(payoutData)
          setIsPayPremiumToggle(false)
        }}
      />

      <RepayLoanPopup
        visible={isRepayLoanToggle}
        primaryPolicy={policyNum}
        details={repayLoanDetails}
        maxNetAmount={maxAmount || 0}
        onClose={() => {
          setIsRepayLoanToggle(false)
          setIsRepayLoanChecked(false)
        }}
        onConfirm={(payoutData) => {
          setRepayLoanPayoutData(payoutData)
          setIsRepayLoanToggle(false)
        }}
      />

      <OtherPayPremiumPopup
        visible={isOtherPayPremiumToggle}
        primaryPolicy={policyNum}
        details={otherPayPremiumDetails}
        onClose={() => {
          setIsOtherPayPremiumToggle(false)
          setIsOtherPayPremiumChecked(false)
        }}
        onConfirm={(payoutData) => {
          setOtherPayPremiumPayoutData(payoutData)
          setIsOtherPayPremiumToggle(false)
        }}
      />

      {otherMethodSelected === PayoutPopup.PayoutMethods.BANKTRANSFER && (
        <BankTransferPopup
          isShowPayeeNotPoOption={
            inheritanceFlag &&
            [TransactionType.PRUCASH_PAYMENT, TransactionType.MATURITY, TransactionType.FULL_SURRENDER].includes(
              transactionType
            )
          }
          visible={isOtherMethodToggle}
          primaryPolicy={policyNum}
          // maxAmount={Math.max((maxAmount || 0) - totalPayoutAmount, 0)}
          maxAmount={Math.max(Number(((maxAmount || 0) - totalPayoutAmount).toFixed(2)), 0)}
          ownerInfo={ownerInfo}
          isFractionFormat={isFractionFormat}
          onClose={() => {
            setIsOtherMethodToggle(false)
            setOtherMethodSelected(undefined)
          }}
          onConfirm={(payoutData) => {
            setBankTransferPayoutData(payoutData)
            setIsOtherMethodToggle(false)
          }}
        />
      )}
      {otherMethodSelected === PayoutPopup.PayoutMethods.CASH_AT_COUNTER && (
        <CashAtCounterPopup
          isShowPayeeNotPoOption={
            inheritanceFlag &&
            [TransactionType.PRUCASH_PAYMENT, TransactionType.MATURITY, TransactionType.FULL_SURRENDER].includes(
              transactionType
            )
          }
          visible={isOtherMethodToggle}
          primaryPolicy={policyNum}
          // maxAmount={Math.max((maxAmount || 0) - totalPayoutAmount, 0)}
          maxAmount={Math.max(Number(((maxAmount || 0) - totalPayoutAmount).toFixed(2)), 0)}
          ownerInfo={ownerInfo}
          isFractionFormat={isFractionFormat}
          onClose={() => {
            setIsOtherMethodToggle(false)
            setOtherMethodSelected(undefined)
          }}
          onConfirm={(payoutData) => {
            setCashAtCounterPayoutData(payoutData)
            setIsOtherMethodToggle(false)
          }}
          submissionOfficeCode={officeCode}
        />
      )}
      {otherMethodSelected === PayoutPopup.PayoutMethods.PAID_AT_BANK && (
        <PaidAtBankPopup
          isShowPayeeNotPoOption={
            inheritanceFlag &&
            [TransactionType.PRUCASH_PAYMENT, TransactionType.MATURITY, TransactionType.FULL_SURRENDER].includes(
              transactionType
            )
          }
          visible={isOtherMethodToggle}
          primaryPolicy={policyNum}
          // maxAmount={Math.max((maxAmount || 0) - totalPayoutAmount, 0)}
          maxAmount={Math.max(Number(((maxAmount || 0) - totalPayoutAmount).toFixed(2)), 0)}
          ownerInfo={ownerInfo}
          isFractionFormat={isFractionFormat}
          onClose={() => {
            setIsOtherMethodToggle(false)
            setOtherMethodSelected(undefined)
          }}
          onConfirm={(payoutData) => {
            setPaidAtBankPayoutData(payoutData)
            setIsOtherMethodToggle(false)
          }}
        />
      )}

      {otherMethodSelected === PayoutPopup.PayoutMethods.MOMO && (
        <EwalletMomoPopup
          visible={isOtherMethodToggle}
          primaryPolicy={policyNum}
          maxAmount={Math.max(Number(((maxAmount || 0) - totalPayoutAmount).toFixed(2)), 0)}
          ownerInfo={ownerInfo}
          isFractionFormat={isFractionFormat}
          onClose={() => {
            setIsOtherMethodToggle(false)
            setOtherMethodSelected(undefined)
          }}
          onConfirm={(payoutData) => {
            setEwalletMomoData(payoutData)
            setIsOtherMethodToggle(false)
          }}
        ></EwalletMomoPopup>
      )}

      <PayoutInfoPopup
        visible={viewInfoSpecs.visible}
        isFractionFormat={isFractionFormat}
        data={payoutDataSource[viewInfoSpecs.index]}
        onClose={() => setViewInfoSpecs({ index: -1, visible: false })}
      />
    </>
  )
}

export const PayoutMethod = React.forwardRef(_PayoutMethod)
