import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { OPLStackParamList } from './OPLStackParamList'
import { OPLScreen } from './OPLScreen'
import {
  OPLBusinessDocument,
  OPLEditingDocument,
  OPLFormDocument,
  OPLSubDocument,
  OPLViewDetailDocument
} from './opl-document'
import { OPLEditingSignature, OPLSignature, OPLViewDetailSignature } from './opl-signature'
import { OPLStackContext } from './OPLStackContext'
import { pipe } from 'fp-ts/lib/function'
import { ErrorHandling, Permission, RBAC } from '@pulseops/common'

const stack = createStackNavigator<OPLStackParamList>()
export const OPLStack = () => {
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidMount([]))
  return (
    <OPLStackContext.Provider value={{ userPermissions: permissions }}>
      <stack.Navigator initialRouteName="OPLScreen" screenOptions={{ headerShown: false }}>
        <stack.Screen name="OPLScreen" component={OPLScreen} />
        <stack.Screen name="OPLBusinessDocument" component={OPLBusinessDocument} />
        <stack.Screen name="OPLSignature" component={OPLSignature} />
        {permissions.includes(Permission.ViewOPLSDEditDocument) && (
          <stack.Screen name="OPLEditingDocument" component={OPLEditingDocument} />
        )}
        {permissions.includes(Permission.ViewOPLSDViewDetailDoc) && (
          <stack.Screen name="OPLViewDetailDocument" component={OPLViewDetailDocument} />
        )}
        {permissions.includes(Permission.ViewOPLSDEditSignature) && (
          <stack.Screen name="OPLEditingSignature" component={OPLEditingSignature} />
        )}
        {permissions.includes(Permission.ViewOPLSDViewDetailSign) && (
          <stack.Screen name="OPLViewDetailSignature" component={OPLViewDetailSignature} />
        )}
        <stack.Screen name="OPLSubDocument" component={OPLSubDocument} />
        <stack.Screen name="OPLFormDocument" component={OPLFormDocument} />
      </stack.Navigator>
    </OPLStackContext.Provider>
  )
}
