import { AppContext, Permission, sharedStyle } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CSStackParamList } from './CSStackParamList'
import { useTranslation } from 'react-i18next'
import { CSStackContext } from './CSStackContext'
import { useIsFocused, useNavigation } from '@react-navigation/native'
export const CSScreen = (props: StackScreenProps<CSStackParamList>) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const { userPermissions } = React.useContext(CSStackContext)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:DocumentManagement'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const onHandleNavigate = (type: string) => {
    switch (type) {
      case '1': {
        props.navigation.navigate('CSGADDocument')
        break
      }
      case '2': {
        props.navigation.navigate('CSProcessGuidelineDoc')
        break
      }
      default:
        break
    }
  }
  return (
    <View style={CSStyle.container}>
      {userPermissions.includes(Permission.ViewGADDocsCSDocsManage) && (
        <TouchableOpacity style={[sharedStyle.button]} onPress={() => onHandleNavigate('1')}>
          <Text style={sharedStyle.textButton}>{t('OPL_CS:CS:GADDocuments')}</Text>
        </TouchableOpacity>
      )}
      {userPermissions.includes(Permission.ViewProcessGuidelinesCSDocsManage) && (
        <TouchableOpacity style={[sharedStyle.button]} onPress={() => onHandleNavigate('2')}>
          <Text style={sharedStyle.textButton}>{t('OPL_CS:CS:Process_Guidelines')}</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
const CSStyle = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 100
  }
})
