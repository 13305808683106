export namespace OPLDocumentConst {
  export enum OPLDocumentTypeConst {
    OPL_BUSINESS_PROCESS = 'OPL_BUSINESS_PROCESS',
    OPL_FORMS = 'OPL_FORMS'
  }

  export enum OPLDocumentStatusConst {
    Active = 'Active',
    Inactive = 'Inactive'
  }
}