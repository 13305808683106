import { StyleSheet } from 'react-native'
export const OPLSharedStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24
  },
  wrapper: {
    flex: 1,
    marginTop: 16
  },
  secondLine: {
    marginTop: 20
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonContent: {
    marginVertical: 0
  },
  datePickerText: {
    marginBottom: 2
  },
  underlineText: {
    color: 'rgb(30, 165, 252)'
  },
  checkboxContainer: {
    padding: 0,
    paddingLeft: 10,
    margin: 0
  },
  btnDeleteContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  btnDeleteContent: {
    marginLeft: 0,
    marginBottom: 0
  },
  btnAddContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  btnAddContent: {
    marginLeft: 30,
    marginBottom: 0
  },
  panelContent: {
    marginTop: 15
  },
  messageContent: {
    marginTop: 10
  },
  messageText: {
    fontSize: 13,
    color: '#ED1B2C'
  },
  footerContainer: {
    marginBottom: 20
  }
})