import { pipe } from "fp-ts/lib/function"
import { POApi } from "../POApi"
import * as t from 'io-ts'
import { ZIO } from "@mxt/zio"
import { Maybe } from "@mxt/zio/codec"

export namespace CSService {
  export const NewGADDocumentRequest = t.type({
    id: Maybe(t.string),
    code: t.string,
    documentType: t.string,
    fileName: t.string,
    gaCode: t.string,
    csArea: t.string,
    docId: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    activityDate: t.string,
    status: t.string,
    transactionType: t.string,
    docNo: t.string,
    document: t.type({
      name: t.string,
      url: t.string,
    }),
    keyID: Maybe(t.string)
  })

  export const NewGADDocumentResponse = t.type({
    body: t.array(t.type({
      keyID: t.string,
      message: t.string
    })),
    status: t.type({
      message: Maybe(t.string)
    })
  })

  export const GADDocumentListRequest = t.type({
    code: t.string,
    documentType: t.string,
    fileName: t.string,
    gaCode: t.string,
    csArea: t.string,
    docId: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    status: t.string,
    transactionType: t.string,
    docNo: t.string,
    start: t.number,
    size: t.number,
    fromDate: t.string,
    toDate: t.string,
  })

  export const GADDocumentInfo = t.type({
    id: t.string,
    fileName: t.string,
    gaCode: t.string,
    csArea: t.string,
    docId: t.string,
    createdDate: t.string,
    createdBy: t.string,
    modifiedDate: t.string,
    modifiedBy: t.string,
    activityDate: Maybe(t.string),
    status: t.string,
    urlFilenet: t.string
  })

  export const GADDocumentListResponse = t.type({
    body: t.array(GADDocumentInfo),
    start: t.number,
    size: t.number,
    total: t.number
  })
  export const DeleteADDocumentListRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      status: t.string
    }))
  })

  export const UpdateADDocumentListRequest = t.type({
    body: t.array(t.type({
      id: t.string,
      modifiedDate: t.string,
      modifiedBy: t.string,
      activityDate: t.string,
      status: t.string
    }))
  })

  export const UpdateGADDocumentResponse = t.type({
    status: t.type({
      message: Maybe(t.string)
    })
  })

  export type NewGADDocumentRequest = t.TypeOf<typeof NewGADDocumentRequest>
  export type GADDocumentListRequest = t.TypeOf<typeof GADDocumentListRequest>
  export type GADDocumentInfo = t.TypeOf<typeof GADDocumentInfo>
  export type DeleteADDocumentListRequest = t.TypeOf<typeof DeleteADDocumentListRequest>
  export type UpdateADDocumentListRequest = t.TypeOf<typeof UpdateADDocumentListRequest>

  export const addNewGADDocument = (bodyData: NewGADDocumentRequest[]) => pipe(
    POApi.post(`prunet/add-cs-document`)(NewGADDocumentResponse)(bodyData),
    ZIO.map((newResponse) => newResponse)
  )

  export const getGADDocumentList = (bodyData: GADDocumentListRequest) => pipe(
    POApi.post(`prunet/search-cs-document`)(GADDocumentListResponse)(bodyData),
    ZIO.map((response) => response)
  )

  export const deleteGADDocumentList = (data: DeleteADDocumentListRequest) => pipe(
    POApi.post(`prunet/update-cs-document`)(UpdateGADDocumentResponse)(data),
    ZIO.map((response) => response)
  )

  export const updateGADDocumentList = (data: UpdateADDocumentListRequest) => pipe(
    POApi.post(`prunet/update-cs-document`)(UpdateGADDocumentResponse)(data),
    ZIO.map((response) => response)
  )

  export const addNewProcessGuidelineDoc = (bodyData: NewGADDocumentRequest[]) => pipe(
    POApi.post(`prunet/add-cs-document`)(NewGADDocumentResponse)(bodyData),
    ZIO.map((newResponse) => newResponse)
  )

  export const getProcessGuidelineDocList = (bodyData: GADDocumentListRequest) => pipe(
    POApi.post(`prunet/search-cs-document`)(GADDocumentListResponse)(bodyData),
    ZIO.map((response) => response)
  )
}