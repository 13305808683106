import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export const CheckingPayoutData = t.type({
  businessKey: Maybe(t.string),
  policyNumber: Maybe(t.string),
  transactionType: Maybe(t.string),
  authorizeDate: Maybe(t.string),
  createdDate: Maybe(t.string),
  bankTransfer: Maybe(t.number),
  paidAtBank: Maybe(t.number),
  payPremium: Maybe(t.number),
  cashAtCounter: Maybe(t.number),
  momo: Maybe(t.number),
  repayLoan: Maybe(t.number),
  other: Maybe(t.number),
  totalMethod: Maybe(t.number),
  refundCardFlag: Maybe(t.string),
})

export const ReportCheckingPayoutResponse = t.type({
  body: t.type({
    data: t.array(CheckingPayoutData),
    size: t.number,
    start: t.number,
    total: t.number
  })
})

export type CheckingPayoutDataType = t.TypeOf<typeof CheckingPayoutData>
export namespace CheckingPayoutReportService {

  export interface PayloadReport {
    businessKey?: string
    fromDate?: string
    toDate?: string
    transactionType?: string
    policyNumber?: string
    totalMethod?: number
  }

  export const viewReportCheckingPayout= ({
      businessKey,
      fromDate,
      toDate,
      transactionType,
      policyNumber,
      totalMethod
    }: PayloadReport, size: number, start: number ) => {
      return pipe(
        POApi.post('wf-api/policy/payout-method-checking/get-data')(ReportCheckingPayoutResponse)({
          order: "ASC",
          size,
          sort: "createdDate",
          start,
          transactionType,
          policyNumber,
          businessKey,
          fromDate,
          toDate,
          totalMethod
        }),
        ZIO.map((res) => {
          const { data = [], size = 10, start = 0, total = 0 } = res.body ?? {}

          return {
            data: data,
            start,
            size,
            total
          }
        })
      )
    }
}
