import React from 'react'
import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { Error, FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import {
  AppContext,
  AuthService,
  DatePicker,
  ErrorHandling,
  FileUploadData,
  ImgUploadMutiple,
  ImgUploadMutiplePC,
  Input,
  OPLService,
  Permission,
  sharedStyle,
  StorageBlob
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
// import { OPLDocumentForm } from './OPLDocumentForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OPLSignatureForm } from './OPLSignatureForm'
import moment from 'moment'
import { OPLDocumentConst } from '../opl-document'
import { useLoading } from '@mxt/zio-react'
import { OPLStackContext } from '../OPLStackContext'
import { useIsFocused } from '@react-navigation/native'

type OPLCreatingSignatureProps = {
  isActiveTab: boolean
  setIsActiveTabIndex: (index: number) => void
}

export const OPLCreatingSignature = (props: OPLCreatingSignatureProps) => {
  const { t } = useTranslation()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const { userPermissions } = React.useContext(OPLStackContext)
  const [userInfo, setUserInfo] = React.useState('')
  const { setValue, control, trigger, getValues, reset, watch } = useForm<OPLSignatureForm.OPLCreatingSignatureFormData>({
    defaultValues: {
      uploadFiles: [],
      fileName: '',
      title: '',
      content: '',
      ADCode: '',
      GACode: '',
      expirationDate: null,
      createDate: new Date(),
      createBy: '',
      modifiedDate: new Date(),
      modifedBy: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      pipe(
        ZIO.zipPar(AuthService.userInfo, OPLService.getADCodeFromSystem()),
        ZIO.map(([userData, systemCode]) => {
          setValue(`createBy`, userData.isGaLogin ? userData.name : userData.email)
          setValue(`modifedBy`, userData.isGaLogin ? userData.name : userData.email)
          setValue('ADCode', systemCode.toString())
          setUserInfo(userData.isGaLogin ? userData.name : userData.email)
          return userData
        }),
        ErrorHandling.run()
      )
    }
    return () => {
      reset({
        uploadFiles: [],
        fileName: '',
        title: '',
        content: '',
        ADCode: '',
        GACode: '',
        expirationDate: null,
        createDate: new Date(),
        createBy: userInfo,
        modifiedDate: new Date(),
        modifedBy: userInfo
      })
    }
  }, [isFocused])

  const onUploadedFileEvent = (value: FileUploadData[]) => {
    if (value && value.length > 0) {
      setValue('fileName', value[0].fileName)
      trigger('fileName')
    } else {
      setValue('fileName', '')
    }
  }

  const onResetEvent = () => {
    reset({
      uploadFiles: [],
      fileName: '',
      title: '',
      content: '',
      ADCode: watch('ADCode'),
      GACode: '',
      expirationDate: null,
      createDate: new Date(),
      createBy: userInfo,
      modifiedDate: new Date(),
      modifedBy: userInfo
    })
  }

  const getUploadFileWithMetaData = (formData: OPLSignatureForm.OPLCreatingSignatureFormData) => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    const currentDate = moment(new Date()).format('DD/MM/YYYY')
    const batchno = `${currentDate}-KL-${formData.ADCode}`
    const metaDataRaw: StorageBlob.MetaDataUpload = {
      type: '',
      doctype: '',
      class: 'POLICYINFO',
      docid: '10406071',
      maindoc: 'SIGNATURE DOCUMENTS',
      subdoc: 'BDM-SIGNATURE',
      polnum: formData.ADCode,
      batchno: batchno
    }
    // console.log('metaDataRaw:' + JSON.stringify(metaDataRaw))
    metaDataFiles = formData.uploadFiles.map((x) => {
      return {
        file: x.file,
        metaData: metaDataRaw
      }
    })
    return metaDataFiles
  }

  const getSubmitedData = (
    formData: OPLSignatureForm.OPLCreatingSignatureFormData,
    documents: {
      name: string
      url: string
    }[]
  ) => {
    const expirationDate = formData.expirationDate ? moment(formData.expirationDate).format("YYYY-MM-DD") : ''
    const createdDate = formData.createDate ? formData.createDate.toISOString() : ''
    const modifiedDate = formData.modifiedDate ? formData.modifiedDate.toISOString() : ''
    const savedData: OPLService.NewOPLSignatureInfoRequest = {
      body: {
        id: null,
        fileName: formData.fileName,
        title: formData.title,
        content: formData.content,
        adCode: formData.ADCode,
        gaCode: formData.GACode,
        expirationDate: expirationDate,
        createdDate: createdDate,
        createdBy: formData.createBy,
        modifiedDate: modifiedDate,
        modifiedBy: formData.modifedBy,
        signatureStatus: OPLDocumentConst.OPLDocumentStatusConst.Active,
        transactionType: 'PRUNET_UPLOAD',
        docNo: 'D1227'
      },
      documents: documents
    }
    return savedData
  }

  const onSaveEvent = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      // console.log('data of creating document is valid')
      const formData = getValues()
      pipe(
        ZIO.effect(() => getUploadFileWithMetaData(formData)),
        ZIO.flatMap((uploadFile) => StorageBlob.uploadToSubmit('', 'PRUNET_UPLOAD')(uploadFile)),
        ZIO.flatMap((azuredFiles) => {
          const submitedData = getSubmitedData(formData, azuredFiles)
          return OPLService.addNewOPLSignature(submitedData)
        }),
        ZIO.map((response) => {
          if (response && response.status.message === 'MS020075') {
            showToast(t('message:MS020075'), 'success')
            props.setIsActiveTabIndex(0)
          } else {
            showToast(t('message:MS020071'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:MS020071'), 'error')
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <Controller
          control={control}
          name={'uploadFiles'}
          rules={{
            required: {
              value: true,
              message: t('message:MS150004')
            },
            validate: (val) => {
              if (val && val.length > 0 && val[0].fileName.length > 300) {
                return `${t('message:MS020068', { maxLength: 300 })}`
              } else {
                return true
              }
            }
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiplePC
                value={value as any[]}
                onChange={(val) => {
                  onChange(val)
                  onUploadedFileEvent(val)
                }}
                onBlur={onBlur}
                timeFormat={'DD/MM/YYYY HH:mm'}
                maxSizeFile={10}
                maxTotalFile={10}
                messageFormat={'OPL_CS:OPL:UploadFileFormatInfo'}
                validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']}
                messageMaxSize={'OPL_CS:OPL:MaxSizeFile10MB'}
                messageMaxTotalSize={'OPL_CS:OPL:MaxSizeFile10MB'}
                formatFileNameVi={true}
                maxNumFile={1}
                takeAPicture={false}
                showFullFileName={false}
                isFileDownload={true}
                errorMessage={''}
              />
              {error?.message && <Error message={error.message} />}
            </>
          )}
        />
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:FileName') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
                {/* <Input title={t('FileName')} value={''} required /> */}
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('OPL_CS:OPL:Title') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('CustomerEngagement:Content') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      numberOfLines={4}
                      maxLength={300}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="ADCode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ADCode')}
                      value={value}
                      maxLength={8}
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      alwayShowUnderline
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="GACode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:GACode')}
                      value={value}
                      maxLength={300}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  rules={{
                    validate: (val) => {
                      if (!!val && !moment(val).isValid()) {
                        return `${t('message:MS020072')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={createDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
      <View style={[OPLSharedStyles.buttonContainer]}>
        {userPermissions.includes(Permission.CancelOPLSDUploadSignature) && (
          <TouchableOpacity style={[sharedStyle.button]} onPress={() => onResetEvent()}>
            <Text style={sharedStyle.textButton}>{t('OPL_CS:OPL:Cancel')}</Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.SaveOPLSDUploadSignature) && (
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
            <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  )
}
const createDocStyles = StyleSheet.create({
  datePickerText: {
    marginBottom: 2
  }
})
