import { Permission, TabView, TabViewInfo } from '@pulseops/common'
import React from 'react'
import { View } from 'react-native'
import { OPLSharedStyles } from '../../opl'
import { CSStackContext } from '../CSStackContext'
import { useTranslation } from 'react-i18next'
import { CSProcessGuidelineCreatingDoc } from './CSProcessGuidelineCreatingDoc'
import { CSProcessGuidelineDocList } from './CSProcessGuidelineDocList'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { AppContext } from '../../../../../common/src'
export const CSProcessGuidelineDoc = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState(0)
  const { userPermissions } = React.useContext(CSStackContext)
  const {changeBreadcrumb} = React.useContext(AppContext.AppContextInstance)
  const getTabList = () => {
    const tabList: TabViewInfo[] = []
    if (userPermissions.includes(Permission.ViewProcessGuidelineslist)) {
      tabList.push({
        tabName: t('OPL_CS:CS:List'),
        tabKey: 0
      })
    }
    if (userPermissions.includes(Permission.SaveUploadProcessGuidelines)) {
      tabList.push({
        tabName: t('OPL_CS:CS:UploadDocument'),
        tabKey: 1
      })
    }
    return tabList
  }

  React.useEffect(() => {
      if (isFocused) {
        changeBreadcrumb([
          {
            title: '',
            navigate: () => {
              rootNavigation.navigate('HomeScreen')
            }
          },
          {
            title: t('menu:DocumentManagement'),
            navigate: () => rootNavigation.navigate('CSScreen')
          },
          {
            title: t('OPL_CS:CS:Process_Guidelines'),
            navigate: null
          }
        ])
      }
    }, [isFocused])

  const getComponentByTabIndex = () => {
    switch (isActiveTabIndex) {
      case 0:
        return userPermissions.includes(Permission.ViewProcessGuidelineslist) ? (
          <CSProcessGuidelineDocList></CSProcessGuidelineDocList>
        ) : (
          <></>
        )
      case 1:
        return userPermissions.includes(Permission.SaveUploadProcessGuidelines) ? (
          <CSProcessGuidelineCreatingDoc setIsActiveTabIndex={setIsActiveTabIndex}></CSProcessGuidelineCreatingDoc>
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }
  return (
    <View style={OPLSharedStyles.wrapper}>
      <TabView
        isActiveTabIndex={isActiveTabIndex}
        setIsActiveTabIndex={setIsActiveTabIndex}
        data={getTabList()}
      ></TabView>
      {getComponentByTabIndex()}
    </View>
  )
}
