import HomepageLogo from './homepage-logo.svg'
import HomepageLogoWide from './homepage-logo_wide.svg'
import NewHomepageLogo from './NewHomepageLogo.svg'
import SmallHomepageLogo from './SmallHomepageLogo.svg'
import StaffIcon from './staff.svg'
import StaffWideIcon from './staff_wide.svg'
import GaIcon from './ga.svg'
import GaWideIcon from './ga_wide.svg'
import GaDisabledIcon from './ga_disabled.svg'
import GaWideDisabledIcon from './ga_wide_disabled.svg'
import DocumentIcon from './document.svg'
import TaskIcon from './task.svg'
import TaskWideIcon from './task_wide.svg'
import SubmissionIcon from './submission.svg'
import SubmissionWideIcon from './submission_wide.svg'
import PolicyDetailIcon from './policy_detail.svg'
import PolicyDetailWideIcon from './policy_detail_wide.svg'
import HeaderLogo from './header_logo.svg'
import HeaderLogoWide from './header_logo_wide.svg'
import SearchIcon from './icon_search.svg'
import NotificationIcon from './icon_notification.svg'
import HambergerIcon from './icon_hamberger.svg'
import FilterIcon from './icon_filter.svg'
import FilterAppliedIcon from './icon_filter_applied.svg'
import ShowMoreTaskIcon from './icon_showmore_task.svg'
import PriorityStarFilledIcon from './icon_priority_star_filled.svg'
import PriorityStarNoFilledIcon from './icon_priority_star_no_filled.svg'
import TimeIcon from './icon_time.svg'
import ShowMoreTaskWideIcon_Active from './icon_showmore_task_wide_active.svg'
import ShowMoreTaskWideIcon_Inactive from './icon_showmore_task_wide_inactive.svg'
import ArrowRightIcon from './icon_arrow_right.svg'
import ShowMoreMobileIcon_Active from './icon_showmore_task_mobile_active.svg'
import ShowMoreMobileIcon_Inactive from './icon_showmore_task_mobile_inactive.svg'
import TaskIcon_Checked from './icon_task_checked.svg'
import TaskIcon_UnChecked from './icon_task_unchecked.svg'
import OverdueIcon from './icon_overdue.svg'
import StatusExpandIcon from './status_expand.svg'
import StatusCompleteIcon from './status_complete.svg'
import StatusTransferIcon from './status_transfer.svg'
import StatusSuspendIcon from './status_suspend.svg'
import StatusRejectIcon from './status_reject.svg'
import StatusEndIcon from './status_end.svg'
import StatusCloseIcon from './status_close.svg'
import OwnerIcon from './owner.svg'
import PlusCircleIcon from './plus-circle.svg'
import ChooseFileIcon from './choose_file.svg'
import CashOutIcon from './cashout.svg'
import CameraIcon from './camera.svg'
import File1Icon from './file1.svg'
import CheckIcon from './check.svg'
import CheckBoxIcon from './check.svg'
import ArrowDownDropdownIcon from './icon_arrow_down_dropdown.svg'
import CloseTaskModalIcon from './icon_close_task_modal.svg'
import ProfileIcon from './profile.svg'
import RemoveIcon from './remove.svg'
import SearchRedIcon from './search.svg'
import UploadIcon from './upload.svg'
import PrintIcon from './print.svg'
import CalendarIcon from './calendar_icon.svg'
import DeleteIcon from './delete_recycler.svg'
import TimeCircleIcon from './icon_time_circle.svg'
import CalenderIcon from './icon_calendar.svg'
import MenuItemArrowRightIcon from './icon_menu_item_arrow_right.svg'
import MenuItemCaseInquiryIcon from './icon_menu_item_case_inquiry.svg'
import MenuItemErrorHandlerReportIcon from './icon_menu_item_error_handler_report.svg'
import MenuItemReportIcon from './icon_menu_item_report.svg'
import MenuItemSettingIcon from './icon_menu_item_setting.svg'
import MenuItemSubmissionFormIcon from './icon_menu_item_submission_form.svg'
import MenuItemTaskManagementIcon from './icon_menu_item_tasks_management.svg'
import MenuItemUtilitiesIcon from './icon_menu_item_utilities.svg'
import MenuItemVoucherInquiryIcon from './icon_menu_item_voucher_inquiry.svg'
import MenuItemPolicyInquiryIcon from './icon_menu_item_policy_inquiry.svg'
import SearchTaskIcon from './icon_search_task.svg'
import TaskIcon_UnChecked_Disable from './icon_task_unchecked_disable.svg'
import Search20Icon from './Search20.svg'
import ArrowRightTaskFilterModalIcon from './icon_arrow_right_task_filter_modal.svg'
import ArrowSelectTable from './arrowSelectTable.svg'
import SendEmail from './sendEmail.svg'
import SendEmail1 from './Send_mail1.svg'
import HomepageBO from './homepageIcon_BO.svg'
import InputCalendar from './calendarInput.svg'
import ArrowLeft from './ArrowLeft.svg'
import ChatIcon from './icon_chat.svg'
import GrinIcon from './icon_grin.svg'
import SendIcon from './icon_send.svg'
import HomeIcon from './home.svg'
import UploadCloud from './upload-cloud-img.svg'
import UploadCamera from './camera_upload.svg'
import LanguageIcon from './icon_language.svg'
import ContactUsIcon from './icon_contact_us.svg'
import VietnameseFlagIcon from './icon_vietnamese_flag.svg'
import EnglandFlagIcon from './icon_england_flag.svg'
import CheckedIcon from './icon_check.svg'
import LocationPinIcon from './location_pin.svg'
import MicIcon from './mic.svg'
import PSOpened from './ps-opened.svg'
import ReportEform from './report-eform.svg'
import ReportBusinessPulse from './report-bp.svg'
import DivExpand from './div-expand.svg'
import DivClosed from './div-collapse.svg'
import PSImageIcon from './policy-service/ps-img.svg'
import CheckBox_Checked_Active from './icon_checkbox_checked_active.svg'
import CheckBox_Uncheck_Active from './icon_checkbox_uncheck_active.svg'
import CheckBox_Checked_Disable from './icon_checkbox_checked_disable.svg'
import CheckBox_Uncheck_Disable from './icon_checkbox_uncheck_disable.svg'
import OpenMenu from './open_menu.svg'
import CloseMenu from './close_menu.svg'
import ExpandArrow from './expand_arrow.svg'
import UploadDocument from './upload_document.svg'
import CashAdvance from './policy-service/cash-advance.svg'
import AddLackOfDocuments from './policy-service/add-lack-of-documents.svg'
import Reinstatement from './policy-service/reinstatement.svg'
import Payout from './policy-service/payout.svg'
import ChangePolicyInfo from './policy-service/change-policy-info.svg'
import ChangeGeneralInfo from './policy-service/change-general-info.svg'
import LetterRequest from './policy-service/letter-request.svg'
import MicroPhone from './mic.svg'
import PsImage from './policy-service/ps-img.svg'
import DeleteBin from './delete_bin.svg'
import AngleRightXXL from './icon_menu_item_arrow_right_XXL.svg'
import GridViewIcon from './grid_icon.svg'
import TableViewIcon from './table_icon.svg'
import SeeLessTaskIcon from './icon_seeless_task.svg'
import EyesIcon from './policy-service/eyes.svg'
import ClaimService from './claim-service/claim-service.svg'
import ClaimSupplementary from './claim-service/claim-supplementary.svg'
import Building from './building.svg'
import MainBoyAvatar from './main_boy_avatar.svg'
import PolicyNumber from './policy_number.svg'
import ExpandIcon from './div-expand.svg'
import CollapseIcon from './div-collapse.svg'
import IconImport from './icon_import.svg'
import SearchDisableIcon from './icon_search_disable.svg'
import Download from './download.svg'
import RedEye from './icon_red_eye.svg'
import DownloadWhite from './download-white.svg'
import IconSuccessCheck from './icon_success_check.svg'
import NoticeIcon from './icon_notice.svg'
import LoginLogo from './login_logo.svg'
import AppLogo from './AppLogo.svg'
import UsernameInput from './username_input.svg'
import Lock from './lock.svg'
import EyeOn from './eye_on.svg'
import EyeOff from './eye_off.svg'
import IconXRed from './icon-x-red.svg'
import IconAddReceipt from './icon-add-receipt.svg'
import IconPCProfile from './icon-pc-profile.svg'
import RightScrollIcon from './Arrow_Right_Circle.svg'
import LeftScrollIcon from './Arrow_Left_Circle.svg'
import AssignmentIcon from './assignment.svg'
import InternalFeedbackBanner from './InternalFeedbackBanner.svg'
import UnAuthorFeedbackIcon from './UnAuthorFeedbackIcon.svg'
import IsSubmitedIcon from './IsSubmitedIcon.svg'
import IconArrowDownRed from './icon-arrow-down-red.svg'
import iconOpen from './openicon.svg'
import iconclose from './iconclose.svg'
import iconCalendar from './Calendar.svg'
import checkWhite from './checkWhite.svg'
import checkBlue from './checkBlue.svg'
import IconArrowDownGray from './icon-arrow-down-gray.svg'
import XlsIcon from './xls_icon.svg'
import IconNotify from './Iconly.svg'
import CEPImage from './CEPimage.svg'
import IconArrowDownCircel from './arrow_down_circle.svg'
import HomepageInquiryIcon from './InquiryHomePage_Icon.svg'
import IconAppliedFilter from './filter_icon_applied_tran_history.svg'
import IconArrowBgRightCircle from './arrow_right_circle_bg.svg'
import IconCepSearch from './CepSearch.svg'
import IconCepArrowDownRed from './IconArrowDownRed.svg'
import IconStar from './Star.svg'
import UploadStatus from './Vector.svg'
import Clock from './clock.svg'
import Undo from './undo.svg'
import WarningCircle from './icon-warning-circle.svg'
import IconEdit from './icon-edit.svg'
import IconSave from './icon-save.svg'
import IconMenuNotification from './icon_menu_notification.svg'
import XLSXIcon from './xlsxIcon.svg'
import TrackingSubmission from './Tracking_submission.svg'
import TrackingNote from './TrackingNotes.svg'
import TrackingProcess from './TrackingProcess.svg'
import TrackingComplete from './TrackingComplete.svg'
import TrackingMoney from './TrackingMoney.svg'
import ReportErrorHandler from './report_error_handler.svg'
import pdf from './pdf.svg'
import PencilIcon from './pencil.svg'
import imageIcon from './imageIcon.svg'
import EDIT from './config-table/t8_edit.svg'
import DELETE from './config-table/t8_trash.svg'
import ModalFrontSide from './change-nation-id/frontside.svg'
import ModalBackSide from './change-nation-id/backside.svg'
import DocuemntActive from './Document_active.svg'
import DocuemntInactive from './Document_inactive.svg'
import UserIcon from './userCep.svg'
import IconPdf from './icon-pdf.svg'
import IconComment from './icon_comment.svg'
import IconJpeg from './icon-jpeg.svg'
import IconJpg from './icon-jpg.svg'
import IconMsg from './icon-msg.svg'
import IconPng from './icon-png.svg'
import IconTif from './icon-tif.svg'
import IconTiff from './icon-tiff.svg'
import OutboundSearch from './OutboundSearch.svg'
import OutboundIcon from './outbound/OutboundIcon.svg'
import NewOutboundIcon from './outbound/NewOutboundIcon.svg'
import InboundIcon from './inbound/InboundIcon.svg'
import NewInboundIcon from './inbound/NewInboundIcon.svg'
import IBSearchIcon from './inbound/IBSearchIcon.svg'
import IBChevronLeft from './inbound/ib-chevron-left.svg'
import IBVip1 from './inbound/ib-vip1.svg'
import IBCollapseRight from './inbound/ib-collapse-right.svg'
import IBCakeDOB from './inbound/ib-cake-dob.svg'
import IBCallIcon from './inbound/ib-call-icon.svg'
import IBCall2Icon from './inbound/ib-call2-icon.svg'
import IBCollapseDown from './inbound/ib-collapse-down.svg'
import IBEmailIcon from './inbound/ib-email-icon.svg'
import IBZaloIcon from './inbound/ib-zalo-icon.svg'
import IBLocalIcon from './inbound/ib-local-icon.svg'
import IBMarriedStatusIcon from './inbound/ib-married-status-icon.svg'
import IBVectorIcon from './inbound/ib-vector-icon.svg'
import IBVectorGrayIcon from './inbound/ib-vector-gray-icon.svg'
import IBBriefCaseIcon from './inbound/ib-briefcase.svg'
import IBClockIcon from './inbound/ib-clock.svg'
import IBMoneyIcon from './inbound/ib-money.svg'
import IBAngryIcon from './inbound/ib-angry.svg'
import IBPencilIcon from './inbound/ib-pencil.svg'
import IBPushToTopIcon from './inbound/ib-push-to-top.svg'
import IBCollapseIcon from './inbound/ib-collapse-icon.svg'
import IBExpandIcon from './inbound/ib-expand-icon.svg'
import IBArrowUpRightFromSquare from './inbound/ib-arrow-up-right-from-square.svg'
import IBMenuSearchAPerson from './inbound/ib-menu-searching-a-person.svg'
import OutboundCallButton from './outbound/OutboundCallButton.svg'
import OBTickBox from './outbound/OBTickBox1.svg'
import OBPanelExtend from './outbound/OBPanelExtend.svg'
import OBPanelCollapse from './outbound/OBPanelCollapse.svg'
import OBNoTick from './outbound/OBNoTick.svg'
import IBChevronRightCircle from './inbound/ib-chevron-right-circle.svg'
import OBDropUp from './outbound/OBDropUp.svg'
import OBDropDown from './outbound/OBDropDown.svg'
import UploadCameraRed from './camera_upload_red.svg'
import ExportCurve from './export_curve.svg'
import IBChevronRight from './inbound/ib-chevron-right.svg'
import IBArrowMaximize from './inbound/ib-arrow-maximize.svg'
import IBArrowMinimize from './inbound/ib-arrow-minimize.svg'
import IBCheckRole from './inbound/ib-check-role.svg'
import IBTickTrue from './inbound/ib-tick-true.svg'
import IBVipDiamond from './inbound/ib-vip-diamond.svg'
import IBVipGold from './inbound/ib-vip-gold.svg'
import IconCalendarRed from './calendar_red.svg'
import IconMSG from './icon_msg.svg'
import OBPlus from './outbound/OBPlus.svg'
import OBPhoneCall from './outbound/OBPhoneCall.svg'
import OBPopupCallOther from './outbound/OBPopupCallOther.svg'
import OBCallOtherClose from './outbound/OBCallOtherClose.svg'
import OBEye from './outbound/OBEye.svg'
import OBEndCall from './outbound/OBEndCall.svg'
import OBRecheckEndCall from './outbound/OBRecheckEndCall.svg'
import OBRelistenIcon from './outbound/OBRelistenIcon.svg'
import OBExportFile from './outbound/OBExportFile.svg'
import OBCallIndicator from './outbound/OBCallIndicator.svg'
import OBCustomerBehavior from './outbound/OBCustomerBehavior.svg'
import OBSpecialNote from './outbound/OBSpecialNote.svg'
import IBTickFalse from './inbound/ib-tick-false.svg'
import NewTaskManagerIcon from './NewTaskManagerIcon.svg'
import NewSubmissionIcon from './NewSubmissionIcon.svg'
import NewPolicyDetailIcon from './NewPolicyDetailIcon.svg'
import NewBackOfficeIcon from './NewBackOfficeIcon.svg'
import NewReleasePolicyIcon from './NewReleasePolicyIcon.svg'
import New360InquiryIcon from './New360InquiryIcon.svg'
import NewAgentSubmissionIcon from './NewAgentSubmissionIcon.svg'

import IBShowMore from './inbound/ib-show-more.svg'
import IBOpenPopupIcon from './inbound/ib-open-popup-icon.svg'
import IBCircleCollapseIcon from './inbound/ib-circle-collapse-icon.svg'
import IBCircleExpandIcon from './inbound/ib-circle-expand-icon.svg'

import IBFemaleIcon from './inbound/ib-female-icon.svg'
import IBMaleIcon from './inbound/ib-male-icon.svg'
import IBArrowRight from './inbound/ib-arrow-right.svg'
import IBDraftMenu from './inbound/ib-draft-menu.svg'
import IBContactIcon from './inbound/ib-contact-icon.svg'
import IBMultiTasksXIcon from './inbound/ib-multi-task-x-icon.svg'
import IBMultiTasksPlusIcon from './inbound/ib-multi-task-plus-icon.svg'
import IBXMark from './inbound/ib-Xmark.svg'
import IBCustomerBehaviorIcon from './inbound/ib-customer-behavior.svg'
import IBCallAvayaIcon from './inbound/ib-call-avaya-icon.svg'
import IBInfoBipIcon from './inbound/ib-inforbip-icon.svg'
import IBCallbackAppointIcon from './inbound/ib-callback-appoint-icon.svg'
import IBClickToSendEmailIcon from "./inbound/ib-click-to-send-email-icon.svg"
import OBArrowRight from "./outbound/OBArrowRight.svg"
import OBPencilGray from "./outbound/OBPencilGray.svg"

import BtnPlayIcon from './play_btn_audio.svg'
import BtnPauseIcon from './pause_btn_audio.svg'
import BtnPrevIcon from './prev_btn_audio.svg'
import BtnNextIcon from './next_btn_audio.svg'
import TTVPersonIcon from './counselors.svg'
import PersonIcon from './person.svg'
import LoudSpeakerIcon from './loud_speaker.svg'
import LoudSpeakerRedIcon from './loud_speaker_red.svg'
import LoudSMIcon from './loud_small.svg'
import NoticeRedIcon from './icon_notice_red.svg'
import OBPromotionIcon from './outbound/OBPromotionIcon.svg'
import OBReorderTable from './outbound/OBReorderTable.svg'
import OBReorderButton from './outbound/OBReorderButton.svg'
import OBReorderButtonDisabled from './outbound/OBReorderButtonDisabled.svg'
export const assets = {
  HomeBackground: require('./home_bg.png'),
  HomeBackgroundWide: require('./home_bg_wide.png'),
  TaskDetailHeaderBackground: require('./task_detail_header_bg.png'),
  DrawerBackground: require('./drawer_background.png'),
  CFIAttachmentTemplate: require('./file/PYC DE NGHI HUY GIAO DICH THE- HOAN TRA PHI -JULY2021.pdf'),
  CFIIsRefundCarAttachmentTemplate: require('./file/PYC ĐỀ NGHỊ HỦY GIAO DỊCH THẺ- HOÀN TRẢ PHÍ.pdf'),
  AgentOfficeTemplate: require('./file/MovingOfficeRequestForm.pdf'),
  AgentLeaderTemplate: require('./file/TransferUnitRequestForm.pdf'),
  AgentTerminationTemplate: require('./file/TerminationRequestForm.pdf'),
  RedatingHealthyTemplate: require('../../../../apps/PulseOps/web/static/to_khai_suc_khoe.pdf'),
  UpdatedRedatingHealthyTemplate: require('../../../../apps/PulseOps/web/static/To_khai_Suc_khoe_UW03_20052024_latest_23052024.pdf'),
  HomepageBO,
  HomepageLogo,
  HomepageLogoWide,
  NewHomepageLogo,
  SmallHomepageLogo,
  StaffIcon,
  StaffWideIcon,
  GaIcon,
  GaWideIcon,
  GaDisabledIcon,
  GaWideDisabledIcon,
  DocumentIcon,
  ProfileIcon,
  TaskIcon,
  TaskWideIcon,
  SubmissionIcon,
  SubmissionWideIcon,
  PolicyDetailIcon,
  PolicyDetailWideIcon,
  PlusCircleIcon,
  ChooseFileIcon,
  CashOutIcon,
  CameraIcon,
  File1Icon,
  HeaderLogo,
  HeaderLogoWide,
  SearchIcon,
  NotificationIcon,
  HambergerIcon,
  FilterIcon,
  FilterAppliedIcon,
  PriorityStarNoFilledIcon,
  PriorityStarFilledIcon,
  TimeIcon,
  ShowMoreTaskIcon,
  ShowMoreTaskWideIcon_Active,
  ShowMoreTaskWideIcon_Inactive,
  ShowMoreMobileIcon_Active,
  ShowMoreMobileIcon_Inactive,
  ArrowRightIcon,
  TaskIcon_Checked,
  TaskIcon_UnChecked,
  TaskIcon_UnChecked_Disable,
  OverdueIcon,
  StatusExpandIcon,
  StatusCompleteIcon,
  StatusTransferIcon,
  StatusSuspendIcon,
  StatusRejectIcon,
  StatusEndIcon,
  StatusCloseIcon,
  OwnerIcon,
  CheckIcon,
  CheckBoxIcon,
  ArrowDownDropdownIcon,
  CloseTaskModalIcon,
  RemoveIcon,
  SearchRedIcon,
  UploadIcon,
  PrintIcon,
  CalendarIcon,
  DeleteIcon,
  Search20Icon,
  ArrowRightTaskFilterModalIcon,
  TimeCircleIcon,
  CalenderIcon,
  MenuItemArrowRightIcon,
  MenuItemCaseInquiryIcon,
  MenuItemErrorHandlerReportIcon,
  MenuItemReportIcon,
  MenuItemSettingIcon,
  MenuItemSubmissionFormIcon,
  MenuItemUtilitiesIcon,
  MenuItemTaskManagementIcon,
  MenuItemPolicyInquiryIcon,
  MenuItemVoucherInquiryIcon,
  ArrowSelectTable,
  SendEmail,
  SendEmail1,
  InputCalendar,
  ArrowLeft,
  SearchTaskIcon,
  ChatIcon,
  GrinIcon,
  SendIcon,
  HomeIcon,
  UploadCloud,
  UploadCamera,
  LanguageIcon,
  ContactUsIcon,
  VietnameseFlagIcon,
  EnglandFlagIcon,
  CheckedIcon,
  LocationPinIcon,
  MicIcon,
  PSOpened,
  ReportEform,
  ReportBusinessPulse,
  DivExpand,
  DivClosed,
  PSImageIcon,
  CheckBox_Checked_Active,
  CheckBox_Uncheck_Active,
  CheckBox_Checked_Disable,
  CheckBox_Uncheck_Disable,
  OpenMenu,
  CloseMenu,
  ExpandArrow,
  UploadDocument,
  CashAdvance,
  AddLackOfDocuments,
  Reinstatement,
  Payout,
  ChangePolicyInfo,
  ChangeGeneralInfo,
  LetterRequest,
  MicroPhone,
  PsImage,
  DeleteBin,
  AngleRightXXL,
  GridViewIcon,
  TableViewIcon,
  SeeLessTaskIcon,
  EyesIcon,
  ClaimService,
  ClaimSupplementary,
  Building,
  MainBoyAvatar,
  PolicyNumber,
  ExpandIcon,
  CollapseIcon,
  IconImport,
  SearchDisableIcon,
  RedEye,
  Download,
  DownloadWhite,
  IconSuccessCheck,
  NoticeIcon,
  LoginLogo,
  AppLogo,
  LoginBackground: require('./login_bg.png'),
  LoginBackgroundMobile: require('./login_bg_mb.png'),
  UsernameInput,
  Lock,
  EyeOn,
  EyeOff,
  IconXRed,
  IconAddReceipt,
  IconPCProfile,
  RightScrollIcon,
  LeftScrollIcon,
  AssignmentIcon,
  InternalFeedbackBanner,
  UnAuthorFeedbackIcon,
  IsSubmitedIcon,
  iconOpen,
  iconclose,
  iconCalendar,
  checkWhite,
  checkBlue,
  IconArrowDownGray,
  IconNotify,
  CEPImage,
  IconArrowDownCircel,
  XlsIcon,
  IconArrowDownRed,
  HomepageInquiryIcon,
  IconAppliedFilter,
  IconArrowBgRightCircle,
  IconCepSearch,
  IconCepArrowDownRed,
  IconStar,
  UploadStatus,
  Clock,
  Undo,
  IconEdit,
  IconSave,
  IconMenuNotification,
  XLSXIcon,
  WarningCircle,
  TrackingSubmission,
  TrackingNote,
  TrackingProcess,
  TrackingComplete,
  TrackingMoney,
  ReportErrorHandler,
  pdf,
  PencilIcon,
  imageIcon,
  ConfigTable: {
    EDIT,
    DELETE
  },
  ChangeNationalIDCard: {
    ModalFrontSide,
    ModalBackSide
  },
  DocuemntActive,
  DocuemntInactive,
  UserIcon,
  IconComment,
  IconPdf,
  IconJpeg,
  IconJpg,
  IconMsg,
  IconPng,
  IconTif,
  IconTiff,
  OutboundSearch,
  OutboundIcon,
  NewOutboundIcon,
  InboundIcon,
  NewInboundIcon,
  IBSearchIcon,
  IBChevronLeft,
  IBVip1,
  IBCollapseRight,
  IBCakeDOB,
  IBCallIcon,
  IBCall2Icon,
  IBCollapseDown,
  IBEmailIcon,
  IBZaloIcon,
  IBLocalIcon,
  IBMarriedStatusIcon,
  IBVectorIcon,
  IBVectorGrayIcon,
  IBBriefCaseIcon,
  IBClockIcon,
  IBMoneyIcon,
  IBAngryIcon,
  IBPencilIcon,
  IBPushToTopIcon,
  IBCollapseIcon,
  IBExpandIcon,
  IBArrowUpRightFromSquare,
  IBMenuSearchAPerson,
  OutboundCallButton,
  OBTickBox,
  OBPanelExtend,
  OBPanelCollapse,
  OBNoTick,
  IBChevronRightCircle,
  OBDropUp,
  OBDropDown,
  IBChevronRight,
  IBArrowMaximize,
  IBArrowMinimize,
  IBCheckRole,
  UploadCameraRed,
  ExportCurve,
  IBTickTrue,
  IBVipDiamond,
  IBVipGold,
  IconCalendarRed,
  IconMSG,
  OBPlus,
  OBPhoneCall,
  OBPopupCallOther,
  OBCallOtherClose,
  OBEye,
  OBEndCall,
  OBRecheckEndCall,
  OBRelistenIcon,
  OBExportFile,
  OBCallIndicator,
  OBCustomerBehavior,
  OBSpecialNote,
  IBTickFalse,
  NewTaskManagerIcon,
  NewSubmissionIcon,
  NewPolicyDetailIcon,
  NewBackOfficeIcon,
  NewReleasePolicyIcon,
  New360InquiryIcon,
  NewAgentSubmissionIcon,
  IBShowMore,
  IBOpenPopupIcon,
  IBCircleCollapseIcon,
  IBCircleExpandIcon,
  IBFemaleIcon,
  IBMaleIcon,
  IBArrowRight,
  IBDraftMenu,
  IBContactIcon,
  IBMultiTasksXIcon,
  IBMultiTasksPlusIcon,
  IBXMark,
  IBCustomerBehaviorIcon,
  IBCallAvayaIcon,
  IBInfoBipIcon,
  IBCallbackAppointIcon,
  IBClickToSendEmailIcon,
  OBArrowRight,
  OBPencilGray,
  BtnPlayIcon,
  BtnPauseIcon,
  BtnPrevIcon,
  BtnNextIcon,
  TTVPersonIcon,
  PersonIcon,
  LoudSpeakerIcon,
  LoudSMIcon,
  LoudSpeakerRedIcon,
  NoticeRedIcon,
  OBPromotionIcon,
  OBReorderTable,
  OBReorderButton,
  OBReorderButtonDisabled
}
