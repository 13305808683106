import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import { ZIO } from '@mxt/zio'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
export namespace AdminPageService {
  export type NewBankBodyInput = {
    code: string
    codePulse: string
    name: string
    nameEn: string
    status: string
  }

  export type NewT33OfficeBodyInput = {
    bankCode: string
    code: string
    gaCode: string
    isEFM: boolean
    nameEn: string
    nameVi: string
    status: string
    type: string
  }

  export type NewGAInformationBodyInput = {
    id?: string
    adRegion: string
    adZone: string
    address: string
    agentCode: string
    bankCode: string
    classification: string
    companyName: string
    couchbaseId: string
    csRegion: string
    dob: string
    extraPay: string
    gaCSAssociate: string
    gaCSAssociateEmail: string
    gaCSEmail: string
    gaCSSupportManager: string
    gaCode: string
    gaFlag: string
    gaMother: string
    gaOMEmail: string
    gaServiceAssociate: string
    gaTaxCode: string
    gadEmail: string
    gadName: string
    gamRHS: string
    mobile: string
    officeCode: string
    officeName: string
    openDate: string
    province: string
    sex: string
    telPhone: string
    transactionLiveZoom: string
    type: string
  }

  export const BankInfo = t.type({
    id: t.string,
    code: t.string,
    codePulse: Maybe(t.string),
    name: Maybe(t.string),
    nameEn: Maybe(t.string),
    status: t.string,
  })

  export const BankBranchInfo = t.type({
    id: t.string,
    code: t.string,
    codePulse: Maybe(t.string),
    bankCode: t.string,
    bankCodePulse: Maybe(t.string),
    bankName: t.string,
    name: t.string,
    branchNameEn: Maybe(t.string)
  })

  export const T33OfficeInfo = t.type({
    id: t.string,
    bankCode: Maybe(t.string),
    code: t.string,
    gaCode: Maybe(t.string),
    isEFM: t.boolean,
    nameEn: t.string,
    nameVi: t.string,
    status: t.string,
    type: t.string
  })

  export const GAInformationInfo = t.type({
    id: Maybe(t.string),
    adRegion: Maybe(t.string),
    adZone: Maybe(t.string),
    address: Maybe(t.string),
    agentCode: Maybe(t.string),
    bankCode: Maybe(t.string),
    classification: Maybe(t.string),
    companyName: Maybe(t.string),
    couchbaseId: Maybe(t.string),
    csRegion: Maybe(t.string),
    dob: Maybe(t.string),
    extraPay: Maybe(t.string),
    gaCSAssociate: Maybe(t.string),
    gaCSAssociateEmail: Maybe(t.string),
    gaCSEmail: Maybe(t.string),
    gaCSSupportManager: Maybe(t.string),
    gaCode: Maybe(t.string),
    gaFlag: Maybe(t.string),
    gaMother: Maybe(t.string),
    gaOMEmail: Maybe(t.string),
    gaServiceAssociate: Maybe(t.string),
    gaTaxCode: Maybe(t.string),
    gadEmail: Maybe(t.string),
    gadName: Maybe(t.string),
    gamRHS: Maybe(t.string),
    mobile: Maybe(t.string),
    officeCode: Maybe(t.string),
    officeName: Maybe(t.string),
    openDate: Maybe(t.string),
    province: Maybe(t.string),
    sex: Maybe(t.string),
    telPhone: Maybe(t.string),
    transactionLiveZoom: Maybe(t.string),
    type: Maybe(t.string),
  })



  export const BankResponse = t.type({
    total: t.number,
    data: t.array(BankInfo),
    errors: Maybe(t.array(t.type({
      code: Maybe(t.string),
      message: Maybe(t.string)
    })))
  })
  export const BankBranchResponse = t.type({
    total: t.number,
    data: t.array(BankBranchInfo),
    errors: Maybe(t.array(t.type({
      code: Maybe(t.string),
      message: Maybe(t.string)
    })))
  })

  export const T33OfficeResponse = t.type({
    total: t.number,
    data: t.array(T33OfficeInfo)
  })

  export const GAInformationResponse = t.type({
    total: t.number,
    data: t.array(GAInformationInfo)
  })


  export type BankInfo = t.TypeOf<typeof BankInfo>
  export type BankBranchInfo = t.TypeOf<typeof BankBranchInfo>
  export type T33OfficeInfo = t.TypeOf<typeof T33OfficeInfo>
  export type GAInformationInfo = t.TypeOf<typeof GAInformationInfo>

  export const getBankList = () => pipe(
    POApi.get(`administration-rest/bank/masterdata/all`)(BankResponse),
    ZIO.map((response) => {
      return response
    })
  )

  export const addNewBank = (inputData: NewBankBodyInput) => pipe(
    POApi.post(`administration-rest/bank/masterdata`)(BankResponse)(inputData),
    ZIO.map((response) => {
      return response
    })
  )

  export const getBankBranchList = () => pipe(
    POApi.get(`administration-rest/bank-branch/masterdata/all`)(BankBranchResponse),
    ZIO.map((response) => {
      return response.data
    })
  )

  export const addNewBankBranch = (inputData: BankBranchInfo) => pipe(
    POApi.post(`administration-rest/bank-branch/masterdata`)(BankBranchResponse)({
      code: inputData.code,
      codePulse: inputData.codePulse,
      bankCode: inputData.bankCode,
      bankCodePulse: inputData.bankCodePulse,
      bankName: inputData.bankName,
      name: inputData.name,
      branchNameEn: inputData.branchNameEn
    }),
    ZIO.map((response) => {
      return response
    })
  )

  export const getT33OfficeList = () => pipe(
    POApi.get(`administration-rest/t33office/masterdata/all`)(T33OfficeResponse),
    ZIO.map((responseData) => {
      return responseData.data
    })
  )

  export const addNewOffice = (inputData: NewT33OfficeBodyInput) => pipe(
    POApi.postError()(`administration-rest/t33office/masterdata`)(t.type({
      data: Maybe(t.array(T33OfficeInfo)),
      errors: t.array(t.type({
        code: t.string,
        message: t.string
      }))
    }), POApi.adminPageError)(inputData),
    ZIO.foldM(
      (err) => ZIO.fail(err),
      (success) => ZIO.succeed(success)
    )
  )

  export const updateOffice = (inputData: T33OfficeInfo) => pipe(
    POApi.putError()(`administration-rest/t33office/masterdata`)(t.type({
      data: Maybe(t.array(T33OfficeInfo)),
      errors: t.array(t.type({
        code: t.string,
        message: t.string
      }))
    }), POApi.adminPageError)(inputData),
    ZIO.map((response) => {
      return response
    })
  )

  export const deleteOffice = (officeID: string) => pipe(
    POApi.delError()(`administration-rest/t33office/masterdata/${officeID}`)(t.type({
      data: t.array(T33OfficeInfo),
      // errors: t.array(t.type({
      //   code: t.string,
      //   message: t.string
      // }))
    }), POApi.adminPageError),
    ZIO.foldM(
      (err) => ZIO.fail(err),
      (success) => ZIO.succeed(success)
    )
  )

  export const getGAInformationList = () => pipe(
    POApi.get(`administration-rest/gainformation/masterdata/all`)(GAInformationResponse),
    ZIO.map((response) => {
      return response
    })
  )

  export const addNewGAInformation = (inputData: NewGAInformationBodyInput) => pipe(
    POApi.postError()(`administration-rest/gainformation/masterdata`)(t.type({
      data: Maybe(t.array(GAInformationInfo)),
      errors: t.array(t.type({
        code: t.string,
        message: t.string
      }))
    }), POApi.adminPageError)(inputData),
    ZIO.foldM(
      (err) => ZIO.fail(err),
      (success) => ZIO.succeed(success)
    )
  )

  export const updateGAInformation = (inputData: NewGAInformationBodyInput) => pipe(
    POApi.putError()(`administration-rest/gainformation/masterdata`)(t.type({
      data: Maybe(t.array(GAInformationInfo)),
      errors: t.array(t.type({
        code: t.string,
        message: t.string
      }))
    }), POApi.adminPageError)(inputData),
    ZIO.foldM(
      (err) => ZIO.fail(err),
      (success) => ZIO.succeed(success)
    )
  )

  export const deleteGAInformation = (GAInformationID: string) => pipe(
    POApi.delError()(`administration-rest/gainformation/masterdata/${GAInformationID}`)(t.type({
      data: Maybe(t.array(GAInformationInfo)),
    }), POApi.adminPageError),
    ZIO.foldM(
      (err) => ZIO.fail(err),
      (success) => ZIO.succeed(success)
    )
  )

}