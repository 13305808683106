import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy } from '../Client'
import { TaskDetailBase } from '../task-detail-api'
import { getCoreDetail, getPayOutDetail } from './common/payout'
import { PaymentType } from '../PaymentType'
import { CancelFromInceptionConst } from '../OpEformDetail/CancelFromInception'
import { MoreData } from '../MoreData'
import { PulseOpsFormat } from '../../../Formatter'
import { CashoutOptionType } from '../task-detail-api/CashoutOptions'
import { SourceType } from '../SourceType'

export interface CancelFromInception {
  tag: TransactionType.CANCEL_FROM_INCEPTION

  clientName: string
  effectiveDate: Date | null
  customerReceivedDate: Date | null
  instalmentPremium: string
  suspendAmount: string
  advancePremium: string
  medicalFee: string
  pwAmount: string
  netAmountAvailable: string
  topUpAmount: string
  reason: string

  totalAmount: number
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
  source?: string
  refundCardFlag?: string | undefined
}

export type CFIDATA = {
  installmentPremium: number
  advancePremium: number
  suspenseAmount: number
  medicalFee: number
  pwAmount: number
  topUpAmount: number
}

export const CancelFromInception = (
  detail: api.CancelFromInception & TaskDetailBase,
  clientName: string,
  cfi: MoreData.CFI,
  clientPolicy: Array<ClientPolicy>,
  cfiData: CFIDATA,
  branchName?: string
): CancelFromInception => {
  const payloadData = detail.payload.body
  const coreStatus = getCoreDetail(
    detail.coreResponseDetail,
    (!payloadData?.policiesReceived ? payloadData?.cashOutOptions : []) as CashoutOptionType
  )
  const cfiBody = cfi.body
  const reason = !!payloadData?.policiesReceived ? payloadData.reason : payloadData?.attributesExt?.REASON
  const totalAmount = !!payloadData?.policiesReceived
    ? payloadData.policiesReceived.reduce((sum, val) => sum + Number(val.amount), 0)
    : payloadData?.cashOutOptions?.reduce((total, item) => total + Number(item.paymentOption.amount), 0)

  const cashOutList = !!payloadData?.policiesReceived
    ? undefined
    : payloadData && payloadData.cashOutOptions
    ? payloadData.cashOutOptions?.filter(
        (x) =>
          PaymentType.BANKTRANSFER === x.type ||
          PaymentType.PAID_AT_BANK === x.type ||
          PaymentType.CASH_AT_COUNTER === x.type
      )
    : []

  switch (detail.source) {
    case SourceType.PRUONLINE:
    case SourceType.PULSE4OPS:
      return {
        tag: TransactionType.CANCEL_FROM_INCEPTION,
        clientName: clientName,
        source: detail.source,
        effectiveDate: cfiBody ? (cfiBody.effectiveDate === 99999999 ? null : cfiBody.effectiveDate) : null, //  missing, from sql
        customerReceivedDate: cfiBody
          ? cfiBody.customerReceivedDate === 99999999
            ? null
            : cfiBody.customerReceivedDate
          : null,
        instalmentPremium: PulseOpsFormat.thousandSepartor(cfiData.installmentPremium),
        suspendAmount: PulseOpsFormat.thousandSepartor(cfiData.suspenseAmount), // sql die
        advancePremium: PulseOpsFormat.thousandSepartor(cfiData.advancePremium), // missing, from sql
        medicalFee: PulseOpsFormat.thousandSepartor(cfiData.medicalFee), // no data
        pwAmount: PulseOpsFormat.thousandSepartor(cfiData.pwAmount),
        netAmountAvailable: PulseOpsFormat.thousandSepartor(
          cfiData.installmentPremium +
            cfiData.suspenseAmount +
            cfiData.advancePremium +
            cfiData.topUpAmount -
            cfiData.medicalFee -
            cfiData.pwAmount
        ),
        topUpAmount: PulseOpsFormat.thousandSepartor(cfiData.topUpAmount),
        // reason: CancelFromInceptionConst.mapCFIReason.get(p.reason) || '-',
        reason: CancelFromInceptionConst.mapCFIReason.get(reason ?? '') || '-',

        // totalAmount: payloadData.policiesReceived.reduce((sum, val) => sum + Number(val.amount), 0),
        totalAmount: totalAmount ?? 0,
        cashOutList: cashOutList,
        refundCardFlag: payloadData?.refundCardFlag,
        branchName: branchName,
        methods: !!payloadData?.policiesReceived
          ? payloadData.policiesReceived.map((x) => ({
              method: x.payoutOptionType || '-',
              detail: getPayOutDetail(
                {
                  type: x.payoutOptionType as PaymentType,
                  bankAccount: {
                    accountName: x.bankAccountName || '-',
                    accountNo: x.bankAccountNumber || '-',
                    branchName,
                    bankName: x.bankName || '-',
                    bankCode: '-',
                    branchCode: x.bankBranch || '-'
                  },
                  policy: {
                    policyNo: x.policyReceivedNum || '-'
                  }
                },
                clientPolicy
              ),
              amount: Number(x.amount),
              status: null,
              note: '-',
              action: '-'
            }))
          : payloadData?.cashOutOptions?.map((v, i) => ({
              method: v.type ?? '',
              detail: getPayOutDetail(
                {
                  type: v.type as PaymentType,
                  officeCode:
                    (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) ||
                    '',

                  bankAccount: {
                    accountName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                      (v.type === PaymentType.PAID_AT_BANK &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? clientName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      (v.type === PaymentType.CASH_AT_COUNTER &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? clientName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? clientName) ||
                      '-',
                    accountNo:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                      ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                      '-',
                    bankName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                      '-',
                    branchName:
                      (v.type === PaymentType.BANKTRANSFER && branchName) ||
                      (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                      undefined,
                    bankCode: '-',
                    branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
                  },
                  phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
                  issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
                  issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
                  policy: {
                    policyNo: v.policy?.policyNo || '-'
                  }
                },
                clientPolicy
              ),
              amount: v.paymentOption.amount ?? 0,
              status: coreStatus.length > 0 ? coreStatus[i].status : null,
              note: coreStatus.length > 0 ? coreStatus[i].errorMes : '-',
              action: '-'
            })) || [],

      }
  }
}
