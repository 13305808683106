
export namespace CSProcessGuidelineDocConst {

  export const DocIDConstList = [
    {
      docName: 'Mẫu Phiếu yêu cầu',
      docID: '40101321',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'REQUEST FORM',
      docNo: 'D1231'
    },
    {
      docName: 'Mẫu Giấy Ủy quyền',
      docID: '40101331',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'TEMPLATE OF THE POWER OF ATTORNEY',
      docNo: 'D1232'
    },
    {
      docName: 'Mẫu Biểu khác',
      docID: '40101341',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'OTHER REQUEST FORMS',
      docNo: 'D1233'
    },
    {
      docName: 'Hướng dẫn Nghiệp vụ Giao dịch Bảo hiểm',
      docID: '40101351',
      mainDoc: 'CS-GA DOCUMENT',
      subDoc: 'GUIDANCE FOR OPERATION TRANSACTION',
      docNo: 'D1234'
    }
  ]
}