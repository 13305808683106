import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  AuthService,
  DatePicker,
  ErrorHandling,
  Input,
  OPLService,
  sharedStyle
} from '@pulseops/common'
import { Controller, useForm } from 'react-hook-form'
import { OPLDocumentForm } from './OPLDocumentForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import React from 'react'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { OPLDocumentConst } from './OPLDocumentConst'
import { StackScreenProps } from '@react-navigation/stack'
import { OPLStackParamList } from '../OPLStackParamList'

export const OPLViewDetailDocument = (props: StackScreenProps<OPLStackParamList, 'OPLViewDetailDocument'>) => {
  const { t } = useTranslation()
  const actionType = props.route.params.actionType
  const historyID = props.route.params.historyID
  const documentType = props.route.params.documentType
  const isOnlyView = actionType === 'view'
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const [isLoading, bindLoader] = useLoading(false)
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { setValue, control } = useForm<OPLDocumentForm.OPLEditingDocumentFormData>({
    defaultValues: {
      historyID: '',
      fileLink: '',
      fileName: '',
      title: '',
      content: '',
      code: '',
      documentType: '',
      expirationDate: null,
      createDate: null,
      createBy: '',
      modifiedDate: null,
      modifiedBy: '',
      status: ''
    },
    mode: 'onChange'
  })

  const detailData = pipe(
    OPLService.getOPLDocumentDetailByID(historyID),
    ZIO.map((detailResponse) => {
      setValue('historyID', detailResponse.id)
      setValue('fileName', detailResponse.fileName)
      setValue('title', detailResponse.title)
      setValue('code', detailResponse.code)
      setValue('content', detailResponse.content)
      setValue('documentType', detailResponse.documentType)
      setValue('expirationDate', detailResponse.expirationDate ? new Date(detailResponse.expirationDate) : null)
      setValue('createDate', detailResponse.createdDate ? new Date(detailResponse.createdDate) : null)
      setValue('createBy', detailResponse.createdBy)
      setValue('modifiedDate', detailResponse.modifiedDate ? new Date(detailResponse.modifiedDate) : null)
      setValue(`modifiedBy`, detailResponse.modifiedBy)
      setValue(`status`, detailResponse.status)
      return detailResponse
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([historyID])
  )

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('OPL_CS:OPL:Document'),
          navigate: () =>
            props.navigation.navigate(
              documentType === OPLDocumentConst.OPLDocumentTypeConst.OPL_FORMS
                ? 'OPLFormDocument'
                : 'OPLBusinessDocument'
            )
        },
        {
          title: t('OPL_CS:OPL:ViewDetail'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const onClickFileNameEvent = (linkUrl: string) => {
    if (!!linkUrl) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(linkUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <TouchableOpacity onPress={() => onClickFileNameEvent(detailData?.urlFilenet || '')}>
          <View>
            <Text style={OPLSharedStyles.underlineText}>{detailData?.fileName}</Text>
          </View>
        </TouchableOpacity>
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t('message:MS020001', { field: t('OPL_CS:OPL:FileName') })
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t('message:MS020001', { field: t('OPL_CS:OPL:Title') })
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      disabled={isOnlyView}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t('message:MS020001', { field: t('CustomerEngagement:Content') })
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      disabled={isOnlyView}
                      numberOfLines={4}
                      maxLength={300}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="code"
                  // rules={{
                  //   validate: (val) => {
                  //     if (!val) {
                  //       return `${t('message:MS020001', { field: t('CustomerEngagement:Code') })}`
                  //     } else if (val.length !== 8) {
                  //       return `${t('message:MS020072')}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Code')}
                      value={value}
                      maxLength={8}
                      inputType="number"
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  // rules={{
                  //   validate: (val) => {
                  //     if (!!val && !moment(val).isValid()) {
                  //       return `${t('message:MS020072')}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      errorMessage={error?.message}
                      labelStyle={viewDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={viewDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={viewDocStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifiedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
    </ScrollView>
  )
}
const viewDocStyles = StyleSheet.create({
  datePickerText: {
    marginBottom: 2
  },
  underlineText: {
    color: 'rgb(30, 165, 252)'
  }
})
