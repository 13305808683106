import React from 'react'
import { View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { AppContext, assets, PanelProp, Permission, SearchPanel } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { OPLStackParamList } from '../OPLStackParamList'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { OPLStackContext } from '../OPLStackContext'
export const OPLSubDocument = (props: StackScreenProps<OPLStackParamList>) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
   const {userPermissions} = React.useContext(OPLStackContext)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:OPL'),
          navigate: () => rootNavigation.navigate('OPLScreen')
        },
        {
          title: t('OPL_CS:OPL:Document'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const data: PanelProp = {
    itemsSearch: [
      // {
      //   id: 'OPLBusiness',
      //   name: t('Agent support operations'),
      //   navigator: () => {
      //     props.navigation.navigate('OPLBusinessDocument')
      //   }
      // },
      // {
      //   id: 'OPLForm',
      //   name: t('Agent support operations'),
      //   navigator: () => {
      //     props.navigation.navigate('OPLFormDocument')
      //   }
      // }
    ],
    itemsGroup: [
      {
        id: 'OPLBusinessDocument',
        name: t('OPL_CS:OPL:BusinessProcessOfGDBHAtGA'),
        icon: () => <assets.ReportEform />,
        children: [
          {
            id: Permission.ViewSubMenuDocitemOPLSDSubMenuDoc,
            name: t('OPL_CS:OPL:AgentSupportOperations'),
            navigator: () => {
              props.navigation.navigate('OPLBusinessDocument')
            }
          }
        ].filter((item) => userPermissions.includes(item.id))
      },
      {
        id: 'OPLFormDocument',
        name: t('OPL_CS:OPL:Forms'),
        icon: () => <assets.ReportEform />,
        children: [
          {
            id: Permission.ViewSubMenuDocitemOPLSDSubMenuDoc,
            name: t('OPL_CS:OPL:AgentSupportOperations'),
            navigator: () => {
              props.navigation.navigate('OPLFormDocument')
            }
          }
        ].filter((item) => userPermissions.includes(item.id))
      }
    ]
  }
  return <View style={OPLSharedStyles.wrapper}>{data && <SearchPanel data={data} />}</View>
}
