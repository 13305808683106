import { AppContext, AuthService, DatePicker, Input, OPLService, sharedStyle } from '@pulseops/common'
import { FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { Controller, useForm } from 'react-hook-form'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { OPLSharedStyles } from '../opl-common'
import { OPLSignatureForm } from './OPLSignatureForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import React from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { OPLStackParamList } from '../OPLStackParamList'
import { useLoading } from '@mxt/zio-react'

export const OPLViewDetailSignature = (props: StackScreenProps<OPLStackParamList, 'OPLViewDetailSignature'>) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const rootNavigation = useNavigation()
  const actionType = props.route.params.actionType
  const historyID = props.route.params.historyID
  const isOnlyView = actionType === 'view'
  const [isLoading, bindLoader] = useLoading()
  const [detailData, setDetailData] = React.useState<OPLService.OPLSignatureInfo>()
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { setValue, control } = useForm<OPLSignatureForm.OPLEditingSignatureFormData>({
    defaultValues: {
      fileLink: '',
      fileName: '',
      title: '',
      content: '',
      ADCode: '',
      GACode: '',
      expirationDate: null,
      createDate: null,
      createBy: '',
      modifiedDate: null,
      modifiedBy: '',
      signatureStatus: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            rootNavigation.navigate('HomeScreen')
          }
        },
        {
          title: t('OPL_CS:OPL:Signature'),
          navigate: () => props.navigation.navigate('OPLSignature')
        },
        {
          title: t('OPL_CS:OPL:ViewDetail'),
          navigate: null
        }
      ])
      getDetailDataByID()
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getDetailDataByID = () => {
    pipe(
      OPLService.getOPLSignatureDetailByID(historyID),
      ZIO.map((detailResponse) => {
        setValue('historyID', detailResponse.id)
        setValue('fileName', detailResponse.fileName)
        setValue('title', detailResponse.title)
        setValue('ADCode', detailResponse.adCode)
        setValue('GACode', detailResponse.gaCode || '')
        setValue('content', detailResponse.content)
        setValue('expirationDate', detailResponse.expirationDate ? new Date(detailResponse.expirationDate) : null)
        setValue('createDate', detailResponse.createdDate ? new Date(detailResponse.createdDate) : null)
        setValue('createBy', detailResponse.createdBy)
        setValue('modifiedDate', detailResponse.modifiedDate ? new Date(detailResponse.modifiedDate) : null)
        setValue(`modifiedBy`, detailResponse.modifiedBy)
        setValue('fileLink', detailResponse.urlFilenet || '')
        setValue('signatureStatus', detailResponse.signatureStatus)
        setDetailData(detailResponse)
        return detailResponse
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onClickFileNameEvent = (linkUrl: string) => {
    if (!!linkUrl) {
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(linkUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          window.open(window.URL.createObjectURL(blob), '_blank')
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      <SectionContent>
        <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
        <Controller
          control={control}
          name={'fileLink'}
          render={({ field: { value, onChange, onBlur } }) => (
            <>
              <TouchableOpacity onPress={() => onClickFileNameEvent(value)}>
                <View>
                  <Text style={OPLSharedStyles.underlineText}>{detailData?.fileName}</Text>
                </View>
              </TouchableOpacity>
            </>
          )}
        />
      </SectionContent>
      <SectionContent>
        <SectionRow>
          <SectionCol sectionStyles={sharedStyle.col_6}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="fileName"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:FileName')}
                      required
                      value={value}
                      disabled={isOnlyView}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="title"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:Title')}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      maxLength={300}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="content"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CustomerEngagement:Content')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      numberOfLines={4}
                      maxLength={300}
                      disabled={isOnlyView}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_12}>
                <Controller
                  control={control}
                  name="ADCode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ADCode')}
                      value={value}
                      maxLength={8}
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      alwayShowUnderline
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="GACode"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:GACode')}
                      value={value}
                      maxLength={300}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isOnlyView}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="expirationDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('CustomerEngagement:ExpirationDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={undefined}
                      // maxDateMessage={t('message:MS990032')}
                      minDate={undefined}
                      disabled={isOnlyView}
                      errorMessage={error?.message}
                      labelStyle={OPLSharedStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createDate"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:CreateDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={OPLSharedStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="createBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:CreateBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name={'modifiedDate'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OPL_CS:OPL:ModifiedDate')}
                      alwaysShow
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      // maxDate={new Date()}
                      // maxDateMessage={t('message:MS990032')}
                      disabled={true}
                      minDate={undefined}
                      // errorMessage={error?.message}
                      labelStyle={OPLSharedStyles.datePickerText}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={control}
                  name="modifiedBy"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('OPL_CS:OPL:ModifiedBy')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled
                      alwayShowUnderline
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionCol>
        </SectionRow>
      </SectionContent>
    </ScrollView>
  )
}
