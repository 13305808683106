import React from 'react'
import { Text, ScrollView, TouchableOpacity, View } from 'react-native'
import { OPLDocumentConst, OPLSharedStyles } from '../../opl'
import {
  AppContext,
  assets,
  AuthService,
  CSService,
  ImgUploadMutiplePC,
  OPLService,
  Panel,
  Permission,
  PulseOpsFormat,
  SelectSearch,
  sharedStyle,
  StorageBlob
} from '@pulseops/common'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { CSProcessGuidelineDocForm } from './CSProcessGuidelineDocForm'
import { Error, FieldText, SectionCol, SectionRow } from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { CSProcessGuidelineDocConst } from './CSProcessGuidelineDocConst'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as A from 'fp-ts/lib/Array'
import { useLoading } from '@mxt/zio-react'
import { CSStackContext } from '../CSStackContext'
import moment from 'moment'

type CSProcessGuidelineCreatingDocProps = {
  setIsActiveTabIndex: (val: number) => void
}
export const CSProcessGuidelineCreatingDoc = (props: CSProcessGuidelineCreatingDocProps) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [userInfo, setUserInfo] = React.useState('')
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { userPermissions } = React.useContext(CSStackContext)
  const { control, getValues, watch, setValue, trigger } =
    useForm<CSProcessGuidelineDocForm.CSProcessGuidelineCreatingDocData>({
      defaultValues: {
        newGuidelinelist: []
      },
      mode: 'onChange'
    })
  const guidelineFieldArray = useFieldArray<CSProcessGuidelineDocForm.CSProcessGuidelineCreatingDocData>({
    control: control,
    name: 'newGuidelinelist'
  })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      initNewGuidelineFields()
      pipe(
        AuthService.userInfo,
        ZIO.map((userData) => {
          setUserInfo(userData.isGaLogin ? userData.name : userData.email)
          return userData
        }),
        ZIO.unsafeRun({})
      )
    }
    return () => {
      guidelineFieldArray.remove()
      setValue('newGuidelinelist', [])
    }
  }, [isFocused])

  const initNewGuidelineFields = () => {
    guidelineFieldArray.append({
      docID: undefined,
      uploadFiles: [],
      errorMessage: '',
      keyID: PulseOpsFormat.generateUUID()
    })
  }

  const onRemoveGuidelineDocBlock = (index: number) => {
    guidelineFieldArray.remove(index)
    const filteredGuidelinelist = watch('newGuidelinelist')
    pipe(
      filteredGuidelinelist,
      A.map((item) => {
        const newIndex = filteredGuidelinelist.indexOf(item)
        guidelineFieldArray.update(newIndex, {
          docID: item.docID,
          uploadFiles: item.uploadFiles,
          errorMessage: item.errorMessage,
          keyID: item.keyID
        })
        setValue(`newGuidelinelist.${newIndex}.docID`, item.docID)
        setValue(`newGuidelinelist.${newIndex}.uploadFiles`, item.uploadFiles)
        setValue(`newGuidelinelist.${newIndex}.errorMessage`, item.errorMessage)
        setValue(`newGuidelinelist.${newIndex}.keyID`, item.keyID)
        return item
      })
    )
  }

  const onAddNewGuidelineDocBlock = () => {
    const newGADDoclist = watch('newGuidelinelist')
    if (newGADDoclist.length < 5) {
      initNewGuidelineFields()
    }
  }

  const onResetEvent = () => {
    const filteredGuidelinelist = watch('newGuidelinelist')
    pipe(
      filteredGuidelinelist,
      A.map((item) => {
        const newIndex = filteredGuidelinelist.indexOf(item)
        guidelineFieldArray.update(newIndex, {
          docID: undefined,
          uploadFiles: [],
          errorMessage: '',
          keyID: item.keyID
        })
        setValue(`newGuidelinelist.${newIndex}.docID`, undefined)
        setValue(`newGuidelinelist.${newIndex}.uploadFiles`, [])
        setValue(`newGuidelinelist.${newIndex}.errorMessage`, '')
        setValue(`newGuidelinelist.${newIndex}.keyID`, item.keyID)
        return item
      })
    )
  }

  const onUploadedFileEvent = (selectedIndex: number) => {
    setValue(`newGuidelinelist.${selectedIndex}.errorMessage`, '')
  }

  const deleteSuccessfulSavedBlocks = (
    resultList: Array<{
      keyID: string
      message: string
    }>
  ) => {
    for (let i = 0; i < resultList.length; i++) {
      if (resultList[i] && resultList[i].message === 'MS020085') {
        const originGuideList = watch('newGuidelinelist')
        const deletedIndex = originGuideList.indexOf(
          originGuideList.find(
            (x) => x.keyID === resultList[i].keyID
          ) as CSProcessGuidelineDocForm.CSProcessGuidelineCreatingDocInfo
        )
        onRemoveGuidelineDocBlock(deletedIndex)
      }
    }
    //set error message to show in UI for each unsaved block
    const updatedGuidelinelist = watch('newGuidelinelist')
    pipe(
      updatedGuidelinelist,
      A.map((item) => {
        const newIndex = updatedGuidelinelist.indexOf(item)
        setValue(`newGuidelinelist.${newIndex}.errorMessage`, 'MS020080')
        return item
      })
    )
  }

  const getUploadFileWithMetaData = (
    formData: CSProcessGuidelineDocForm.CSProcessGuidelineCreatingDocInfo,
    systemCode: number
  ) => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    const batchno = moment(new Date()).format('DD/MM/YYYY')
    const docIDInfo = CSProcessGuidelineDocConst.DocIDConstList.find((p) => p.docID === formData.docID?.value)
    const metaDataRaw: StorageBlob.MetaDataUpload = {
      type: '',
      doctype: '',
      class: 'POLICYINFO',
      docid: docIDInfo?.docID || '',
      maindoc: docIDInfo?.mainDoc || '',
      subdoc: docIDInfo?.subDoc || '',
      polnum: systemCode.toString(),
      batchno: batchno
    }
    // console.log('CSmetaData:' + JSON.stringify(metaDataRaw))
    metaDataFiles = formData.uploadFiles.map((x) => {
      return {
        file: x.file,
        metaData: metaDataRaw
      }
    })
    return metaDataFiles
  }

  const getSubmitedData = (
    formData: CSProcessGuidelineDocForm.CSProcessGuidelineCreatingDocInfo,
    documents: {
      name: string
      url: string
    }[],
    code: number
  ) => {
    const createdDate = new Date().toISOString()
    const modifiedDate = new Date().toISOString()
    const fileName = formData.uploadFiles && formData.uploadFiles.length > 0 ? formData.uploadFiles[0].fileName : ''
    const docIDInfo = CSProcessGuidelineDocConst.DocIDConstList.find((p) => p.docID === formData.docID?.value)
    const document =
      documents && documents.length > 0
        ? documents[0]
        : {
            name: '',
            url: ''
          }
    const savedData: CSService.NewGADDocumentRequest = {
      id: null,
      code: code.toString(),
      documentType: 'CS_PROCESS',
      fileName: fileName,
      gaCode: '',
      csArea: '',
      docId: formData.docID?.value || '',
      createdDate: createdDate,
      createdBy: userInfo,
      modifiedDate: modifiedDate,
      modifiedBy: userInfo,
      activityDate: createdDate,
      status: OPLDocumentConst.OPLDocumentStatusConst.Active,
      transactionType: 'PRUNET_UPLOAD',
      docNo: docIDInfo?.docNo || '',
      document: document,
      keyID: formData.keyID
    }
    return savedData
  }

  const onSaveEvent = async () => {
    const isValidData = await trigger()
    if (isValidData) {
      const formData = watch()
      pipe(
        OPLService.getADCodeFromSystem('CS_PROCESS'),
        ZIO.flatMap((codeNo) =>
          pipe(
            formData.newGuidelinelist,
            A.map((item) =>
              pipe(
                ZIO.effect(() => getUploadFileWithMetaData(item, codeNo)),
                ZIO.flatMap((uploadFiles) => StorageBlob.uploadToSubmit('', 'PRUNET_UPLOAD')(uploadFiles)),
                ZIO.map((azuredFiles) => {
                  const submitedItemData = getSubmitedData(item, azuredFiles, codeNo)
                  return submitedItemData
                })
              )
            ),
            ZIO.sequence
          )
        ),
        ZIO.flatMap((submitedData) => {
          return CSService.addNewProcessGuidelineDoc(submitedData)
        }),
        ZIO.map((response) => {
          if (response && response.status && response.status.message === 'MS020085') {
            showToast(t('message:MS020085'), 'success')
            props.setIsActiveTabIndex(0)
          } else {
            deleteSuccessfulSavedBlocks(response.body)
            // showToast(t('message:MS020080'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:MS020080'), 'error')
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }
  return (
    <ScrollView style={OPLSharedStyles.container} horizontal={false}>
      {guidelineFieldArray &&
        guidelineFieldArray.fields.length > 0 &&
        guidelineFieldArray.fields.map((fieldItem, index) => {
          return (
            <Panel containerStyle={OPLSharedStyles.panelContent} key={'guidelineFieldArray_' + index}>
              <SectionRow>
                <SectionCol>
                  <Controller
                    control={control}
                    name={`newGuidelinelist.${index}.docID`}
                    rules={{
                      required: {
                        value: true,
                        message: t('message:MS020009', { field: t('OPL_CS:CS:DocIDDocumentType') })
                      }
                    }}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                      <SelectSearch
                        label={t('OPL_CS:CS:DocIDDocumentType')}
                        options={CSProcessGuidelineDocConst.DocIDConstList.map((item) => ({
                          label: item.docName,
                          value: item.docID
                        }))}
                        value={value}
                        onChange={(val) => {
                          onChange(val)
                        }}
                        errorMessage={!value || !value.value ? error?.message : ''}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        required={true}
                        placeholder={t('common:Select')}
                      />
                    )}
                  />
                </SectionCol>
              </SectionRow>
              <SectionRow sectionStyles={OPLSharedStyles.secondLine}>
                <SectionCol sectionStyles={sharedStyle.col_12}>
                  <FieldText text={t('IFQ:FileAttachment')} isRequired={true}></FieldText>
                  <Controller
                    control={control}
                    name={`newGuidelinelist.${index}.uploadFiles`}
                    rules={{
                      required: {
                        value: true,
                        message: t('message:MS150004')
                      },
                      validate: (val) => {
                        if (val && val.length > 0 && val[0].fileName.length > 300) {
                          return `${t('message:MS020068', { maxLength: 300 })}`
                        } else {
                          return true
                        }
                      }
                    }}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                      <>
                        <ImgUploadMutiplePC
                          value={value as any[]}
                          onChange={(val) => {
                            onChange(val)
                            onUploadedFileEvent(index)
                          }}
                          onBlur={onBlur}
                          timeFormat={'DD/MM/YYYY HH:mm'}
                          maxSizeFile={10}
                          maxTotalFile={10}
                          // messageFormat={'OPL_CS:OPL:UploadFileFormatInfo'}
                          validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']}
                          messageMaxSize={'message:MS020083'}
                          messageMaxTotalSize={'message:MS020083'}
                          formatFileNameVi={true}
                          maxNumFile={1}
                          takeAPicture={false}
                          showFullFileName={false}
                          isFileDownload={true}
                          errorMessage={''}
                        />
                        {error?.message && <Error message={error.message} />}
                      </>
                    )}
                  />
                </SectionCol>
              </SectionRow>
              {!!watch(`newGuidelinelist.${index}.errorMessage`) && (
                <View style={OPLSharedStyles.messageContent}>
                  <Text style={OPLSharedStyles.messageText}>
                    {t(`message:${watch(`newGuidelinelist.${index}.errorMessage`)}`)}
                  </Text>
                </View>
              )}
              <View style={OPLSharedStyles.btnDeleteContainer}>
                {userPermissions.includes(Permission.DeleteUploadProcessGuidelines) && (
                  <TouchableOpacity
                    style={[
                      sharedStyle.button,
                      OPLSharedStyles.btnDeleteContent,
                      guidelineFieldArray.fields.length <= 1 && sharedStyle.btnDisabled
                    ]}
                    onPress={() => onRemoveGuidelineDocBlock(index)}
                    disabled={guidelineFieldArray.fields.length <= 1}
                  >
                    <Text
                      style={[
                        sharedStyle.textButton,
                        guidelineFieldArray.fields.length <= 1 && sharedStyle.btnTextDisabled
                      ]}
                    >
                      {t('claim:Delete')}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </Panel>
          )
        })}
      <View style={OPLSharedStyles.btnAddContainer}>
        {userPermissions.includes(Permission.AddUploadProcessGuidelines) && (
          <TouchableOpacity
            style={[sharedStyle.button, OPLSharedStyles.btnAddContent]}
            onPress={onAddNewGuidelineDocBlock}
          >
            <Text style={sharedStyle.textButton}>{t('common:Add')}</Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={[OPLSharedStyles.buttonContainer]}>
        {userPermissions.includes(Permission.ResetUploadProcessGuidelines) && (
          <TouchableOpacity style={[sharedStyle.button]} onPress={() => onResetEvent()}>
            <Text style={sharedStyle.textButton}>{t('OPL_CS:CS:Reset')}</Text>
          </TouchableOpacity>
        )}
        {userPermissions.includes(Permission.SaveUploadProcessGuidelines) && (
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => onSaveEvent()}>
            <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  )
}
